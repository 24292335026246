import EmptyRow from "../common/empty-row/empty-row"
export const NoyMaudyTexst = {
    dates: '7.10.2023-06.07.1994',
    main: <>{`נוי בן ה-29 היה מורה מוערך ואהוב בבית הספר היהודי "תלמוד תורה" במיניסוטה. מוכשר, חכם ובעל זיכרון פנומנלי.
    תמיד חשב על אחרים לפני שחשב על עצמו.
    \nבשבעה באוקטובר רקד נוי את החיים במסיבת הפסידאק יחד עם שניים מבני המשפחה:  `}
        <a href="https://rememberoct7.lnet.studio/#/Matan-Rozenberg" target="blank">מתן רוזנברג הי"ד</a>
        {` ו`}<a href="https://twitter.com/bokeralmog/status/1727024554332066172" target="blank">מוטי אלקבץ הי"ד</a>
    </>,
    slides: {
        earth: {
            mainShortText: `נוי היה בן הזקונים של תמר ושמעון, ואחיהם הצעיר של שני, סיון וספיר.
             הוא גדל והתחנך במושב יתד, ובמשך 6 שנים גר בארצות הברית.`,
            mainText: `בארה"ב חי נוי לצד סופי אריאולה, אשתו לשעבר. הזוגיות שלהם התבססה על חברות טובה במשך 10 שנים.
             כבר בגיל 6 למד נוי לנגן בפסנתר. אחותו ספיר מספרת שהמוזיקה היא שחיברה ביניהם. הם היו מנגנים יחד בפסנתר, יושבים במרפסת ומקשיבים למוזיקה.`,
            mainTextMobile: ``,
        }
        ,
        water: {
            mainShortText: `נוי היה קשור למסורת היהודית. אהב את ארוחות ערב שבת המשפחתיות ואת החגים.
            כשגר בארצות הברית הקפיד לחגוג יחד עם בוגרי בית הספר ולימד אותם על החגים.`,
            mainText: `נוי היה חסיד של תזונה התורמת לבריאות ושל התנסות במזונות חדשים. גם את אחיותיו ואת אחייניו ניסה לרתום לעניין: חייבים להיחשף לדברים חדשים, לטעום ולהתנסות, נהג לומר להם.`,
           
           
        },
        fire: {
            mainShortText: `נוי למד בתיכון במגמת קולנוע. 
            הוא אהב סרטי אנימה – ז'אנר סרטי האנימציה היפני – ובמיוחד את הסדרה One Piece.`,
            mainText: <>{`בשנים האחרונות יזם נוי צילומי סרטים, בכיכובם של האחיינים שלו. הוא היה כותב תסריטים, מביא את האביזרים, מצלם את האחיינים ועורך את הסרטונים. בשבילו זה היה זמן איכות עם האחיינים האהובים עליו והזדמנות טובה לחבר אותם אליו ואל העולם שלו.
            כאן תוכלו לראות אחד מהסרטים שהוא יצר יחד איתם.`}
                <iframe src="https://www.youtube.com/embed/1EN7bNd0syo?si=zUuK_SQUlci7Adns" style={{ border: 'none', width: "560px", height: "315px", margin: '65px 136px 5px 0px', borderRadius: '18px' }}
                    title='ליבנת קוץ הי"ד' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                </iframe>
            </>,
            mainTextMobile: <>{`בשנים האחרונות יזם נוי צילומי סרטים, בכיכובם של האחיינים שלו. הוא היה כותב תסריטים, מביא את האביזרים, מצלם את האחיינים ועורך את הסרטונים. בשבילו זה היה זמן איכות עם האחיינים האהובים עליו והזדמנות טובה לחבר אותם אליו ואל העולם שלו.
        כאן תוכלו לראות אחד מהסרטים שהוא יצר יחד איתם.`}
                <iframe src="https://www.youtube.com/embed/1EN7bNd0syo?si=zUuK_SQUlci7Adns"
                    style={{ border: 'none', width: '95%', height: 'auto', margin: '30px 17px 0 0' , borderRadius: '18px'}}
                    title='ליבנת קוץ הי"ד' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                </iframe>
            </>
        },


        air: {
            mainText: `נוי היה קשור מאוד גם לבעלי חיים. הוא גידל איגואנה, טיפל בחתולים ובכלבה.
            היה לו לב רחב, וגם בפני חסרי בית פתח את ליבו. 
            אימו תמי מספרת שכשהיו בארה"ב, נכנס פעם נוי למסעדה לקנות לעצמו אוכל. כשיצא ממנה ראה אדם חסר בית והחליט לתת לו את האוכל שלו. נוי נכנס בחזרה למסעדה כדי לקנות שוב אוכל, ובינתיים חיכו לו בני משפחתו ברכב ולא הבינו למה הוא מתעכב. כשגילו שהוא עמד בתור פעמיים כי נתן את המנה שלו למישהו אחר, הייתה שמחתם גדולה. הם הופתעו והוקסמו ממנו ומהלב הרחב שלו.`,
            mainShortText: `נוי היה אדם נוח לבריות. הסתדר עם כולם ודאג לחבר בין אנשים שונים.
            שם לב לאחרים, לפני שחשב על עצמו.`,
        },
    },
    songHeader: 'Road Trippin',
    songAuthor: 'Red Hot Chili Peppers',
    songLink: '11GYvfYjyV0?si=PNeCGMFS2uV_4so5',
    song: [],
    padletLink: 'm3ew9skg3agut5y4'
}