import './person-2.scss'
import React from 'react';
import ElementsCarousle from '../common/elements-slides/elements-slides';
import TabHeaderAndExpandableText from '../common/elements-slides/tab-header-and-expandable-text/tab-header-and-expandable-text';
import useDeviceDetection from '../../useDeviceDetection';
import { EliadOhayonTexst } from './person-2-texts';
import { EliadOhayonImages } from './person-2-images';
import Swipper from '../common/swipper/swipper';
import { Outlet, useOutletContext } from 'react-router-dom';
import { personsRouts } from '../../models';
import SpeiaEdgeImage from '../common/speia-edge-image/speia-edge-image';
import Padlet from '../common/padlet/padlet';
import EmptyRow from '../common/empty-row/empty-row';



const Person2 = () => {


  //#region elements slides
  const elementSlides = {
    earth: () => {
      return (<div className='tab-container' style={{ height: '515.12px', width: '1200px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textHeight='81px'
            text={EliadOhayonTexst.slides.earth.mainText}
            mainShortText={EliadOhayonTexst.slides.earth.mainShortText}

          />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.earth[0]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={EliadOhayonImages.slides.earth[1]} />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.earth[2]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={EliadOhayonImages.slides.earth[3]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: () => {
      return (<div className='tab-container' style={{ height: '515.12px', width: '1200px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"} textHeight='72px'
            text={EliadOhayonTexst.slides.water.mainText}
            mainShortText={EliadOhayonTexst.slides.water.mainShortText}
          />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.water[0]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={EliadOhayonImages.slides.water[1]} />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.water[2]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={EliadOhayonImages.slides.water[3]} />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.water[4]} isCorner={true} />
        </div>
      </div>
      )
    },
    fire: () => {
      return (<div className='tab-container' style={{ height: '515.12px', width: '1200px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"} textHeight='76px'
            text={EliadOhayonTexst.slides.fire.mainText}
            mainShortText={EliadOhayonTexst.slides.fire.mainShortText}
          />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.fire[0]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={EliadOhayonImages.slides.fire[1]} />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.fire[2]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={EliadOhayonImages.slides.fire[3]} isCorner={true} />
        </div>
      </div>
      )
    },
    air: () => {
      return (<div className='tab-container' style={{ height: '515.12px', width: '1200px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אוויר"} textHeight='142px'
            text={EliadOhayonTexst.slides.air.mainText}
            mainShortText={EliadOhayonTexst.slides.air.mainShortText}
          />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.air[0]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={EliadOhayonImages.slides.air[1]} />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.air[2]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={EliadOhayonImages.slides.air[3]} isCorner={true} />
        </div>
      </div>
      )
    }

  };

  const mobileElementSlides = {
    earth: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textMobileWidth='306px'
            text={<>
              {EliadOhayonTexst.slides.earth.mainShortText}
              <EmptyRow height={10} />
              {EliadOhayonTexst.slides.earth.mainTextMobile}
            </>
            }
            textHeight='109px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textFullHeight={'972px'} />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.earth[0]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"} textMobileWidth='325px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {EliadOhayonTexst.slides.water.mainShortText}
              <EmptyRow height={5} />
              {EliadOhayonTexst.slides.water.mainText}
            </>
            } textHeight='73px' textFullHeight={'470px'}
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }}
          />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.water[0]} isCorner={true} imageCrop={"top"} />
        </div>
      </div>
      )
    },
    fire: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container tab-rows-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {EliadOhayonTexst.slides.fire.mainShortText}
              <EmptyRow height={5} />
              {EliadOhayonTexst.slides.fire.mainText}
            </>
            } textHeight='130px' textFullHeight={'619px'} />
          <SpeiaEdgeImage image={EliadOhayonImages.slides.fire[0]} isCorner={true} />
        </div>
      </div>
      )
    },
    air: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"רוח"} textMobileWidth='288px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {EliadOhayonTexst.slides.air.mainShortText}
              <EmptyRow height={5} />
              {EliadOhayonTexst.slides.air.mainTextMobile}
            </>
            } textHeight='168px' textFullHeight={'876px'} />

          <SpeiaEdgeImage image={EliadOhayonImages.slides.air[2]} isCorner={true} />
        </div>
      </div>
      )
    }

  };
  //#endregion

  const deviceType = useDeviceDetection();
  const [selected, setSelected] = useOutletContext();



  return (
    <>
      <div className={`eliad-ohayon-component ${(deviceType.isMobile) ? 'eliad-ohayon-component-mobile' : ''}`}>
        <div className='section-start-01'>
          <div id='start' className='anchor-section-start'>
            <div className='handwriting-img'>
              {deviceType.isMobile ? EliadOhayonImages.handWirtingStatmentMobile : EliadOhayonImages.handWirtingStatment}
            </div>
            <div className='line'>
              <div> </div>
              <div className='line-style'> </div>
            </div>
            <div className='vertical-lines-container'>
              <div>
                <div className='vertical-line vertical-line-1' ></div>
                <div className='vertical-line vertical-line-2' ></div>
              </div>
              <div className='vertical-line vertical-line-3' ></div>
            </div>
          </div>
          <div className='person-section-1'>
            <img className='big-img' src={EliadOhayonImages.bigImage} alt={personsRouts[1].name} />
            <div className='text-group'>
              {deviceType.isMobile && <div className='top-right-corner'></div>}
              <div className='description-text-container'>
                <div>
                  <h1> {personsRouts[1].name}</h1>
                  <h3>{EliadOhayonTexst.dates}</h3>
                </div>
                <p>{EliadOhayonTexst.main}</p>
                {deviceType.isMobile && <div className='bottom-left-corner'></div>}
              </div>
            </div>
          </div>
        </div>
        <div className='two-anchors-sections flex-section'>
          <div id="02" className='anchor-section-02 relative-conatainer flex-section'>
            {deviceType.isMobile && <h1>סיפור אישי</h1>}
            <ElementsCarousle elementSlides={deviceType.isMobile ? mobileElementSlides : elementSlides}></ElementsCarousle>
          </div>
        </div>
        <div id="03" className='anchor-section-03 flex-section'>
          <div>
            <h2 className='section-header'>{EliadOhayonTexst.songHeader}</h2>
            <div className='author-name-conatainer'>
              <h4>{EliadOhayonTexst.songAuthor}</h4>
              <div className='decoration-line'></div>
              <div className='decoration-line decoration-line-2' style={{ marginRight: '16px' }}></div>
            </div>
          </div>
          <div className='song-container'>
            <iframe style={{
              borderRadius: '21px', border: 'none'
            }}
              title={EliadOhayonTexst.song}
              src={`https://www.youtube.com/embed/${EliadOhayonTexst.songLink}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}>
            </iframe>
          </div>
        </div>
        <div id="04" className='anchor-section-04 flex-section'>
          <div className='image-background-container'>
            <h2 className='section-header' style={{fontSize:'33px'}}>שיתופים על {personsRouts[1].name}</h2>
            <div className='decoration-line decoration-line-1'></div>
            <div className='decoration-line decoration-line-2' style={{ width: '909px', marginRight: '287px' }}></div>
            <Padlet padletLink={EliadOhayonTexst.padletLink} />
          </div>
        </div>
        <div id="05" className='anchor-section-05 flex-section'>
          <h2 className='section-header'>אלבום תמונות</h2>
          {deviceType.isMobile && <div className='vertical-lines-container'>
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="7" viewBox="0 0 54 7" fill="none">
              <rect width="17.2037" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 17.2041 0.807129)" fill="black" />
              <rect width="12.0426" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 33.5469 0.807129)" fill="black" />
              <rect width="42.5792" height="1.29028" transform="matrix(1 0 0 -1 10.752 6.39795)" fill="black" />
            </svg>
          </div>}
          <div style={{ position: 'relative', maxWidth: !deviceType.isMobile ? '1168px' : '80vw' }}>
            {selected === '05' && <Swipper images={deviceType.isMobile ? EliadOhayonImages.mobileSwipper : EliadOhayonImages.carousle}
              height={'515px'} margin={'62px'} spaceBetween={20} slidesPerView={deviceType.isXLScreen ? 3.3 : 2.5999} />
            } </div>
        </div>
      </div>
      <Outlet></Outlet>
    </>
  );
};
export default Person2;
