export const zoomBaseUrl = 'https://us02web.zoom.us/j/';
export const helpZoomLink = '82478053144?pwd=tySyw7naxDb4mgwIejSyAHFvgIYHcl.1';
export const mainZoomLink = 'gkB0pV3pkWk?si=vy2d-REFQDImoSBe%22';
        export const personsRouts = [
    { zoomLink: 'B2OCW-KF1Zw?si=_v5eOpSe3ZmcRA1U',
        width: (120.188 - 31.359), path: '/Moshe-Ochion', name: 'משה אוחיון' },
    { zoomLink: 'B2OCW-KF1Zw?si=_v5eOpSe3ZmcRA1U%22',
        width: 100.62, path: '/Eliad-Ochion', name: 'אליעד אוחיון' },
    { zoomLink: '8AqNPZp0ugg?si=Sx2aBeZTnitWoV9L%22',
        width: (127.453 - 31.359), path: '/Matan-Rozenberg', name: 'מתן רוזנברג' },
    { zoomLink: '96HhpFiPwOE?si=y7CwL14BuPdVi2UQ%22',
        width: (111.125 - 31.359), path: '/Livnat-Kuzt', name: 'ליבנת קוץ' },
    { zoomLink: 'RQ6bzXVYhAQ?si=9K4wvmqq_dn0PZE1%22',
        width: 67.344, path: '/Gili-Adar', name: 'גילי אדר' },
    { zoomLink: '8AqNPZp0ugg?si=Sx2aBeZTnitWoV9L%22',
        width: (100.984 - 31.359), path: '/Noy-Moudy', name: 'נוי מעודי' },
    { zoomLink: '4EaWYnmVpX0?si=ZstccIbaHNNkmCDk%22',
        width: (94.984 - 31.359), path: '/Matty-Weiss', name: 'מתי וייס' },
]