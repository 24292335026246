import EmptyRow from "../common/empty-row/empty-row"
export const GiliAdarTexst = {
    dates: '7.10.2023-29.09.1999',
    main: `מדריכה נערצת בצופים ובמחנות הצופים בארצות הברית, עשתה שנת שירות בגרעין עתיד בבת ים, שירתה כמדריכת תותחנים ובחודשים שלפני השבעה באוקטובר החלה משרה חדשה כמדריכה בגרעין צבר.
    גילי הייתה שמש, אנרגטית, חרוצה, קשובה וחברת אמת.
    
    "החיים הם לא סימולציה זה 
     לא משחק תפקידים זה הדבר 
     האמיתי צריך לחיות אותם עכשיו"`,
    mainTextMobile: `מדריכה נערצת בצופים ובמחנות הצופים בארצות הברית, עשתה שנת שירות בגרעין עתיד בבת ים, שירתה כמדריכת תותחנים ובחודשים שלפני השבעה באוקטובר החלה משרה חדשה כמדריכה בגרעין צבר.
     גילי הייתה שמש, אנרגטית, חרוצה, קשובה וחברת אמת.
     
     "החיים הם לא סימולציה זה 
      לא משחק תפקידים זה הדבר 
      האמיתי צריך לחיות 
      אותם עכשיו"`,
    slides: {
        earth: {
            mainShortText: `גילי, בתם של אלדד ואורנה ואחותה של עדי, גדלה ביישוב לפיד, ליד מודיעין.
            גילי למדה בבית הספר האנתרופוסופי בשוהם, שהוריה היו שותפים בהקמתו. בהמשך למדה בתיכון הדמוקרטי במודיעין.`,
            mainText: `לגילי, ילדה סקרנית שאוהבת לשאול שאלות, היה זיכרון פנומנאלי! כבר בגיל 3 היא דיקלמה את כל הסיפורים שסיפרה הגננת, ובמיוחד את "גן גורים" ואת הספר "ויהי ערב ויהי בוקר".
            גילי אהבה מאוד את טיולי המשפחה. "אנחנו משפחה משוטטת", אומרים ההורים.
            מסלולים רגליים בוואדיות ובהרים ברחבי הארץ, טיולי אופניים משפחתיים בחורף הגשום בטיילת תל אביב, טיסות משותפות לארה"ב, לשוויץ או ליעדים מיוחדים כמו ברזיל ועוד.\nאורנה, אמה של גילי, מספרת: "באחד מימי החורף נסענו לטיול אופניים בתל אביב והתחיל טפטוף. \nהבנות אמרו לי: 'אימא, מתחיל גשם' ואני אמרתי להן: 'עזבו, זה רק טפטוף, זה תכף יעבור'. יצאנו לדרך ומעט אחר כך התחזק הגשם והפך למבול. נרטבנו לגמרי אבל המשכנו לרכוב."
            המילים "זה רק טפטוף, זה תכף יעבור" נעשו שגורות במשפחת אדר.
            גילי ידעה גם להיות נחושה ולא מתפשרת. כשגילי רצתה משהו היא ידעה איך לרתום את כולם ולגרום לו לקרות. היא הייתה משכנעת, מסבירה, ולפעמים גם מתווכחת. חברים מספרים שהיו משתפים איתה פעולה כשהעלתה רעיונות לא שגרתיים כי ידעו שאפשר לסמוך עליה.
            גילי הרימה את הרף. אתגרה תמיד את הקיים. דרשה מעצמה מה שדרשה מאחרים, ואף יותר.
            חבריה לקמפ שהדריכה בארצות הברית סיפרו שיום אחד, כאשר הכינו כתובות אש, החליטה גילי שהיא רוצה לעשות משהו שונה. כתובת מים! בתגובה לבקשתה המוזרה סירבו חבריה ואמרו שאי אפשר. איך בכלל מכינים כתובת מים?!\nאבל גילי ראתה את התוצאה בעיני רוחה וידעה שזה אפשרי ושזה יהיה מיוחד ומדהים.\nלאחר הסברים ושכנועים הצליחה לרתום למשימה את חבריה לצוות, ובפעם הראשונה התנוססה בגאווה גם כתובת מים אחת בטקס כתובות אש – כתובת המים של גילי.`,
            imagesTexts: [<><b>כתובת המים של גילי</b></>]
        },
        water: {
            mainShortText: `גילי הייתה מאגר אנרגיה בלתי נגמר.
            שמחה בכל הזדמנות שיכלה. החום והאהבה שהפיצה מגנטו אליה אנשים.
            חבריה מספרים שגילי לא הייתה רק קרן אור. היא הייתה השמש.`,
            mainText: <>{`גילי היא זו שפונים אליה בעת צרה, האדם שידרבן וידחוף קדימה לצד הכלה אין־סופית. חברת אמת שיודעת להגיד את הדבר הנכון, שלעולם לא תבטל את רגשותיהם של אחרים. כזו שתראה גם את אלה שאחרים רואים פחות.
             גילי יכלה לשמח בן רגע את האנשים שסביבה.`}
                <EmptyRow />
                {`חברה של גילי מספרת שכשהיו חוזרות לקורס מדריכות התותחנים בצבא, לאחר סוף שבוע בבית, גילי הייתה משמיעה את השיר "כל השבוע מרגיש כמו שבת" כדי לעודד את כולן ולהרים את מצב הרוח. `} <b><a href="https://www.youtube.com/watch?v=dtHSMar3Yh4&t=1s" target="blank" style={{ color: 'white' }}>לשמיעת השיר</a></b>
                <EmptyRow />
                {`"כל אדם שמדבר עליה אומר שהיא הייתה השמש. כשאני מנסה להסביר לאנשים שלא הכירו אותה, אני פתאום לא מוצאת את המילים אבל התיאור המדויק הוא תמיד שמש. תמיד יודעת בדיוק מה להגיד, תמיד עם השיחות הכי טובות ומטעינות באנרגיה, תמיד עם החיוך והזרועות הפתוחות (מטאפורית ומילולית), לכל אחד."`}
                <EmptyRow />
                {`"אני אוהבת אותך על זה שנתת לי בית, שנתת לי כוח, שכעסת עליי שלא השקעתי מספיק, שלימדת אותי שאפשר להיות הכול מהכול, חברה אמיתית של כל כך הרבה אנשים, עמוקה ומצחיקה, קלילה ורצינית."`}
                <EmptyRow />
                {`גילי עשתה שנת שירות בעוגן – מסגרת לילדים עם קשיי השתלבות בבת ים. שנת שירות מורכבת ומשמעותית.
             ברגעים הקשים אמר לה מנהל המסגרת שליווה אותה: "את רק צריכה לעשות את מה שאת יודעת לעשות הכי טוב, 
             גילי – לגלות את האור בסדק התריס הסגור בחייו של הילד."
             וגילי גילתה את האור החודר מהסדק ושינתה את מסלול חייו של הילד שליוותה. גילתה כי הייתה מי שהיא.
             כתבה לה אימו:
             "אני רוצה לומר תודה על כל מה שהיית בשבילנו. תודה על נתינה אין־סופית עבור הבן שלנו. היית אחת הנשים המרכזיות והחשובות להתפתחות הבן שלי. לא היית רק שינשינית – היית מורת דרך ורק בזכותך הבן שלי הצליח להאמין בעצמו. החל להיות ילד עם מוטיבציה. ואת חיזקת, הענקת, טיפחת, את היית אור שכל כך היינו צריכים בחיינו."`}
                <EmptyRow />
                {`גילי הביאה איתה ניקיון, חוסר שיפוטיות, יחס אמפתי ומכיל, וכמו שאמר מנהל שנת השירות שלה: "גילי באה יחפה".`}</>,
            mainTextMobile: <>{`גילי היא זו שפונים אליה בעת צרה, האדם שידרבן וידחוף קדימה לצד הכלה אין־סופית. חברת אמת שיודעת להגיד את הדבר הנכון, שלעולם לא תבטל את רגשותיהם של אחרים. כזו שתראה גם את אלה שאחרים רואים פחות.
גילי יכלה לשמח בן רגע את האנשים שסביבה.`}
                <EmptyRow />
                {`חברה של גילי מספרת שכשהיו חוזרות לקורס מדריכות התותחנים בצבא, לאחר סוף שבוע בבית, גילי הייתה משמיעה את השיר "כל השבוע מרגיש כמו שבת" כדי לעודד את כולן ולהרים את מצב הרוח. `} <b><a href="https://www.youtube.com/watch?v=dtHSMar3Yh4&t=1s" target="blank" style={{ color: '#000' }}>לשמיעת השיר</a></b>
                <EmptyRow />
                {`"כל אדם שמדבר עליה אומר שהיא הייתה השמש. כשאני מנסה להסביר לאנשים שלא הכירו אותה, אני פתאום לא מוצאת את המילים אבל התיאור המדויק הוא תמיד שמש. תמיד יודעת בדיוק מה להגיד, תמיד עם השיחות הכי טובות ומטעינות באנרגיה, תמיד עם החיוך והזרועות הפתוחות (מטאפורית ומילולית), לכל אחד."`}
                <EmptyRow />
                {`"אני אוהבת אותך על זה שנתת לי בית, שנתת לי כוח, שכעסת עליי שלא השקעתי מספיק, שלימדת אותי שאפשר להיות הכול מהכול, חברה אמיתית של כל כך הרבה אנשים, עמוקה ומצחיקה, קלילה ורצינית."`}
                <EmptyRow />
                {`גילי עשתה שנת שירות בעוגן – מסגרת לילדים עם קשיי השתלבות בבת ים. שנת שירות 
                מורכבת ומשמעותית.
ברגעים הקשים אמר לה מנהל המסגרת שליווה אותה: "את רק צריכה לעשות את מה שאת יודעת לעשות הכי טוב, גילי – לגלות את האור בסדק התריס הסגור בחייו של הילד."
וגילי גילתה את האור החודר מהסדק ושינתה את מסלול חייו של הילד שליוותה. 
גילתה כי הייתה מי שהיא.
כתבה לה אימו:
"אני רוצה לומר תודה על כל מה שהיית בשבילנו. תודה על נתינה אין־סופית עבור הבן שלנו. היית אחת הנשים המרכזיות והחשובות להתפתחות הבן שלי. לא היית רק שינשינית – היית מורת דרך ורק בזכותך הבן שלי הצליח להאמין בעצמו. 
החל להיות ילד עם מוטיבציה. ואת חיזקת, הענקת, טיפחת, 
את היית אור שכל כך היינו צריכים בחיינו."`}
                <EmptyRow />
                {`גילי הביאה איתה ניקיון, חוסר שיפוטיות, יחס אמפתי ומכיל, וכמו שאמר מנהל שנת השירות שלה: "גילי באה יחפה".`}</>,

        },
        fire: {
            mainShortText: `גילי אהבה אנשים. כבר בתור נערה ידעה להבחין במי שסביבה.
            נער שהיה עם גילי בכיתת הצופים מספר:`,
            mainText: <>
                {` "כשהייתי בצופים, אני ועוד שני חברים נחשבנו למוזרים, ואף אחד לא רצה לדבר איתנו או ליצור איתנו מגע.`}
                <EmptyRow />
                {`במיוחד הבנות מלפיד. אבל הייתה נערה אחת בלונדינית עם חיוך מאוזן לאוזן שלא היה אכפת לה שחברות שלה יעשו לה פרצופים, והיא דיברה איתנו. התעניינה תמיד בשלומנו, ומדי פעם הייתה עושה איתנו צחוקים כדי שנרגיש גם קשורים.`}
                <EmptyRow />
                {`כל רעיון שהייתי מציע, גם אם הוא היה הכי מטומטם בעולם, גילי הייתה אומרת לי שהרעיון מטורף ומעלה לי את הביטחון (שבתקופה הזאת היה ממש נמוך). מספיק רק אדם אחד שיאמין בך, ואתה מסוגל לעשות הכול. גילי האמינה בי ואני באמת לא יודע למה. מה היא ראתה בי שאף אחד אחר לא ראה בי? כל אחד צריך איתו גילי כדי שיוכל לעשות הכול."`}
                <EmptyRow />{`זאת הייתה גילי.`}
                <EmptyRow />{`כמדריכה, ובהמשך כרכזת ההדרכה של שבט הצופים, הפעולות שהעבירה והאישיות המיוחדת והזורחת שלה נחקקו בזיכרונם של חניכיה והם מספרים:`}
                <EmptyRow />{`"גילי הייתה מדריכה שלנו, ויותר מזה – חברה שלנו. מחייכת תמיד, מספרת בדיחות, עושה חיקויים ושרה שירים.`}
                <EmptyRow />
                {`בכל מחנק (מחנה קיץ), גם אחרי כשעלינו לשכב"ג (שכבה בוגרת) וגילי כבר הייתה בוגרת השבט, הייתה מגיעה להתחבר לרמקול והיינו רוקדים כמו משוגעים עד שכל האזור היה אבק וכל השבטים מסב יב היו מסתכלים.`}
                <EmptyRow />{`כשהיינו חניכים שמסתכלים בהערצה על השכב"ג היה אפשר לראות את האור שהיא מפיצה. היא לא תצא לנו מהראש לעולם. נעשה הכול בשביל להנציח אותה ולספר לאנשים שלא חוו את גילי את כל החוויות שעברנו יחד."`}
                <EmptyRow />{`"גילי הייתה המדריכה שלי בצופים בכיתה ח'.`}
                <EmptyRow />{`היא הייתה מלאת אנרגיה ותמיד ידעה להחזיק אותנו וליהנות איתנו כאילו שהיא חברה ולא מדריכה. היא הייתה מסוג המדריכים שמעוררים בך רצון לשמור איתם על קשר. הייתה כותבת לנו בקבוצה, מייעצת לנו על בכווול – תפקידים בצופים, יציאה לשנת שירות ועוד ועוד. היא הייתה אהובה ברמות מטורפות. אין חניך שהיא לא התחברה איתו. אין אחד שלא הרגיש איתה הכי בנוח. זה לא מובן מאליו שמדריכה מכיתה ח׳ נשארת איתך בלב עד לשלבים המאוחרים של החיים. היא הייתה מדהימה, ובאמת באמת שמש מהלכת."`}
            </>,
            mainTextMobile: `כשהייתי בצופים, אני ועוד שני חברים נחשבנו למוזרים, ואף אחד לא רצה לדבר איתנו או ליצור איתנו מגע. במיוחד הבנות מלפיד. 
            אבל הייתה נערה אחת בלונדינית עם חיוך מאוזן לאוזן שלא היה אכפת לה שחברות שלה יעשו לה פרצופים, והיא דיברה איתנו. התעניינה תמיד בשלומנו, ומדי פעם הייתה עושה איתנו צחוקים כדי שנרגיש גם קשורים.\nכל רעיון שהייתי מציע, גם אם הוא היה הכי מטומטם בעולם, גילי הייתה אומרת לי שהרעיון מטורף ומעלה לי את הביטחון (שבתקופה הזאת היה ממש נמוך). מספיק רק אדם אחד שיאמין בך, ואתה מסוגל לעשות הכול. גילי האמינה בי ואני באמת לא יודע למה. מה היא ראתה בי שאף אחד אחר לא ראה בי? כל אחד צריך איתו גילי כדי שיוכל לעשות הכול."\nזאת הייתה גילי.

כמדריכה, ובהמשך כרכזת ההדרכה של שבט הצופים, הפעולות שהעבירה והאישיות המיוחדת והזורחת שלה נחקקו בזיכרונם של חניכיה 
והם מספרים:

"גילי הייתה מדריכה שלנו, ויותר מזה – חברה שלנו. מחייכת תמיד, מספרת בדיחות, עושה חיקויים ושרה שירים.\nבכל מחנק (מחנה קיץ), גם אחרי כשעלינו לשכב"ג (שכבה בוגרת) וגילי כבר הייתה בוגרת השבט, הייתה מגיעה להתחבר לרמקול והיינו רוקדים כמו משוגעים עד שכל האזור היה אבק וכל השבטים מסביב היו מסתכלים.\nכשהיינו חניכים שמסתכלים בהערצה על השכב"ג היה אפשר לראות את האור שהיא מפיצה. היא לא תצא לנו מהראש לעולם. נעשה הכול בשביל להנציח אותה ולספר לאנשים שלא חוו את גילי את כל החוויות שעברנו יחד."

"גילי הייתה המדריכה שלי בצופים בכיתה ח'.\nהיא הייתה מלאת אנרגיה ותמיד ידעה להחזיק אותנו וליהנות איתנו כאילו שהיא חברה ולא מדריכה. היא הייתה מסוג המדריכים שמעוררים בך רצון לשמור איתם על קשר. הייתה כותבת לנו בקבוצה, מייעצת לנו על בכווול – תפקידים בצופים, יציאה לשנת שירות ועוד ועוד. היא הייתה אהובה ברמות מטורפות. אין חניך שהיא לא התחברה איתו. אין אחד שלא הרגיש איתה הכי בנוח. זה לא מובן מאליו שמדריכה מכיתה ח׳ נשארת איתך בלב עד לשלבים המאוחרים של החיים. היא הייתה מדהימה, ובאמת באמת 
שמש מהלכת."\n
בשנת 2017 טסה לארה"ב כנציגה לתוכנית הצופים הישראלית. 
במהלך השליחות בארה"ב כתב לה אלדד אביה:

"אין לי ספק שאת מצליחה, מוכשרת שכמותך. בטח לא קל בהתחלה, אבל את הרי קורצת מחומר משובח. את נלחמת ולא מוותרת. מחפשת את הדרכים הנכונות ולא נרגעת עד שמוצאת אותן. מפלסת את הדרך שלך, בכוחות עצמך. ולכן אני סומך עליך, ילדה אהובה. סומך על יכולת השיפוט שלך, על קבלת ההחלטות הנבונה שלך, על חוש האחריות המפותח, על הרגישות לזולת, על חוש הצדק שאין שני לו. תמיד הרי היה לך כזה חוש צדק מפותח."

`,
            imagesTexts: [<>
                {`גילי השאירה חותם בכל מקום שהגיעה אליו.
              היא הגיעה כמתנדבת והובילה פעילויות, לימדה את החניכים ריקודים ושירים והאירה את כל מה שסביבה. לאחר שנודע דבר הירצחה פורסמו כתבות לזכרה בארה"ב. באחת מהן נכתב עליה:
            `
                }<p style={{ direction: 'ltr', textAlign: 'left', marginLeft: '25px' }}>
                    She was someone who wanted to make the world a better place by volunteering and working with kids. That's about as noble as it comes in my book.
                </p>
            </>, `בשנת 2017 טסה לארה"ב כנציגה לתוכנית הצופים הישראלית.
                במהלך השליחות בארה"ב כתב לה אלדד אביה:"אין לי ספק שאת מצליחה, מוכשרת שכמותך. בטח לא קל בהתחלה, אבל את הרי קורצת מחומר משובח. את נלחמת ולא מוותרת. מחפשת את הדרכים הנכונות ולא נרגעת עד שמוצאת אותן. מפלסת את הדרך שלך, בכוחות עצמך. ולכן אני סומך עליך, ילדה אהובה. סומך על יכולת השיפוט שלך, על קבלת ההחלטות הנבונה שלך, על חוש האחריות המפותח, על הרגישות לזולת, על חוש הצדק שאין שני לו. תמיד הרי היה לך כזה חוש צדק מפותח."`
            ]
        },
        air: {
            mainText: `וכזו היא הייתה:

            מצד אחד שטותניקית, כזו שאוהבת לחגוג, לבלות ולהצחיק, ומצד שני מלאת אמביציה ורצון לחתור קדימה. רצינית, אחראית ושקדנית.

            היה לה חשוב להסתכל על הסביבה שלה ולהתייחס לכולם ללא שיפוטיות. גם אל האנשים שבדרך כלל רובנו מפספסים.            
            היא האמינה שאפשר להגיד הכול, גם את הדברים הקשים. אלא שחשובה הדרך, המילים שאנחנו בוחרים. הייתה לה יכולת הכלה מופלאה, הקשבה וסבלנות.

            גילי הייתה בחורה של שמחה! של חיוך, של אור ושל אופטימיות. הייתה לה היכולת להסתכל על האחר ולזהות איך אפשר לעזור. היא ידעה למצוא את המילה, את החיזוק ואת התמיכה שצריכים האנשים סביבה. המסרים של גילי הם מסרים של אור, שלום, טוב לב, אמפתיה ושמחה. וכך התנהלה. היא חגגה את החיים מלכת הריקודים ממש כמו השיר האהוב עליה.`,
            mainShortText: `גילי הייתה אדם שלא ראה גבולות. הייתה לה היכולת לעשות הכול, לא לוותר על כלום. בני משפחתה נהגו להגיד לה שהיא צריכה יותר מ־24 שעות ביממה. המשפט שאפיין אותה היה "למה או או כשאפשר גם וגם".`,

        },
    },
    songHeader: 'Dancing Queen',
    songAuthor: 'Abba',
    songLink: '-sVB91NTa4A?si=HJhmiez0K62bTNXT',
    song: [],
    padletLink: '3uv1irh1k1ihg7h1'
}