import bigImage from '../../assets/images/noy-moudy/big-image.png';
import image1 from '../../assets/images/noy-moudy/image-1.png';
import image2 from '../../assets/images/noy-moudy/image-2.png';
import image3 from '../../assets/images/noy-moudy/image-3.png';
import image4 from '../../assets/images/noy-moudy/image-4.png';
import image5 from '../../assets/images/noy-moudy/image-5.png';
import image6 from '../../assets/images/noy-moudy/image-6.png';
import image7 from '../../assets/images/noy-moudy/image-7.png';
import image8 from '../../assets/images/noy-moudy/image-8.png';
import image9 from '../../assets/images/noy-moudy/image-9.png';
import image10 from '../../assets/images/noy-moudy/image-10.png';
import image11 from '../../assets/images/noy-moudy/image-11.png';
import image12 from '../../assets/images/noy-moudy/image-12.png';
import image13 from '../../assets/images/noy-moudy/image-13.png';
import image14 from '../../assets/images/noy-moudy/image-14.png';
import cImage1 from '../../assets/images/noy-moudy/c-image-1.png';
import cImage2 from '../../assets/images/noy-moudy/c-image-2.png';
import cImage3 from '../../assets/images/noy-moudy/c-image-3.png';
import cImage4 from '../../assets/images/noy-moudy/c-image-4.png';
import cImage5 from '../../assets/images/noy-moudy/c-image-5.png';
import cImage6 from '../../assets/images/noy-moudy/c-image-6.png';
import cImage7 from '../../assets/images/noy-moudy/c-image-7.png';
import cImage8 from '../../assets/images/noy-moudy/c-image-8.png';
import cImage9 from '../../assets/images/noy-moudy/c-image-9.png';
import cImage10 from '../../assets/images/noy-moudy/c-image-10.png';
import cImage11 from '../../assets/images/noy-moudy/c-image-11.png';
import cImage12 from '../../assets/images/noy-moudy/c-image-12.png';
import cImage13 from '../../assets/images/noy-moudy/c-image-13.png';
import cImage14 from '../../assets/images/noy-moudy/c-image-14.png';
import cImage15 from '../../assets/images/noy-moudy/c-image-15.png';
import cImage16 from '../../assets/images/noy-moudy/c-image-16.png';
import cImage17 from '../../assets/images/noy-moudy/c-image-17.png';
import cImage18 from '../../assets/images/noy-moudy/c-image-18.png';
import cImage19 from '../../assets/images/noy-moudy/c-image-19.png';
import cImage20 from '../../assets/images/noy-moudy/c-image-20.png';
import cImage21 from '../../assets/images/noy-moudy/c-image-21.png';
import cMobileImage0 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-1.png';
import cMobileImage1 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-2.png';
import cMobileImage2 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-3.png';
import cMobileImage3 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-4.png';
import cMobileImage4 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-5.png';
import cMobileImage5 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-6.png';
import cMobileImage6 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-7.png';
import cMobileImage7 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-8.png';
import cMobileImage8 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-9.png';
import cMobileImage9 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-10.png';
import cMobileImage10 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-11.png';
import cMobileImage11 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-12.png';
import cMobileImage12 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-13.png';
import cMobileImage13 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-14.png';
import cMobileImage14 from '../../assets/images/noy-moudy/mobile-swipper/c-image-mobile-15.png';


export const NoyMaudyImages = {
    bigImage: bigImage,
    handWirtingStatment: <svg width="1282" height="85" viewBox="0 0 1282 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.85 67.6639C10.0105 64.1178 7.17096 60.5586 3.63794 56.1489C3.11453 61.9064 2.66965 66.7348 2.22475 71.5633C1.47889 71.4717 0.733018 71.3801 0.000244141 71.2885V47.8659C0.510569 47.6173 1.02088 47.3556 1.54429 47.1069C5.94094 53.3879 10.3376 59.6688 14.7342 65.9497C14.1062 66.5255 13.4781 67.0881 12.85 67.6639Z" fill="black"/>
    <path d="M24.7575 48.0883C29.5336 41.3232 27.7278 31.5485 21.1852 26.6677C16.4353 23.1216 14.3285 26.5369 12.248 30.4493C11.6591 30.0306 11.0703 29.6119 10.4684 29.1932C11.489 27.0472 12.0124 23.7235 13.6481 23.0431C16.0558 22.0355 19.8898 21.8785 21.9965 23.1739C29.1149 27.5968 31.6926 34.9245 30.986 42.8934C30.6327 46.9499 31.1431 49.7632 33.8125 52.8644C38.9942 58.8574 38.196 65.0206 32.0852 71.1837C32.517 66.8394 34.1789 62.1549 33.0143 58.3602C31.8497 54.5916 27.8194 51.6998 24.7706 48.0752L24.7575 48.0883Z" fill="black"/>
    <path d="M48.7689 64.9421C51.4383 64.5365 54.1208 64.1309 58.2426 63.5028C51.8178 55.2329 46.1911 47.9968 40.5645 40.7606C41.1925 40.2634 41.8075 39.7662 42.4356 39.2559C48.4156 46.8584 54.461 54.4085 60.2054 62.1812C60.5587 62.6653 58.6875 65.9366 57.4706 66.1983C54.6442 66.8134 51.5954 66.3947 48.625 66.3947C48.6643 65.9105 48.7166 65.4263 48.7558 64.9421H48.7689Z" fill="black"/>
    <path d="M80.4639 15.84C63.2698 17.6457 58.8209 25.4183 65.8215 40.2832C68.9488 46.9305 73.2146 53.0544 76.9308 59.4269C77.9253 61.128 78.7759 62.8945 78.6581 65.1452C70.5976 53.7217 60.4434 43.2535 60.1948 28.0223C60.0247 17.1616 68.7787 11.548 80.4639 15.84Z" fill="black"/>
    <path d="M464.147 68.769C461.438 63.5088 458.73 58.2354 456.008 52.9751C463.911 47.8457 464.984 44.4043 460.614 38.1888C456.714 32.6407 453.011 32.2742 447.058 36.8541C446.77 36.2652 446.495 35.6895 446.207 35.1007C448.89 33.7922 452.318 30.7564 454.124 31.5285C458.154 33.2426 462.223 36.2784 464.71 39.8638C467.196 43.4622 466.633 47.9635 463.139 51.8236C461.922 53.1714 462.014 56.3249 462.459 58.4447C463.152 61.7815 464.67 64.9481 465.835 68.1933C465.272 68.3895 464.723 68.5858 464.16 68.7821L464.147 68.769Z" fill="black"/>
    <path d="M487.74 34.0348C491.351 45.3666 496.402 56.2928 498.09 70.2024C492.503 66.0544 488.603 63.1626 483.788 59.5772C482.414 63.2411 481.367 66.0413 480.32 68.8416C479.849 68.75 479.391 68.6584 478.92 68.5668C479.653 65.2039 479.548 59.6165 481.315 58.9491C484.429 57.7714 488.682 59.5641 493.065 60.2053C490.605 52.1448 487.949 43.4431 485.293 34.7414C486.104 34.5059 486.928 34.2573 487.74 34.0217V34.0348Z" fill="black"/>
    <path d="M521.437 17.1485C504.243 18.9543 499.794 26.7269 506.794 41.5918C509.922 48.2391 514.187 54.363 517.904 60.7355C518.898 62.4366 519.749 64.2031 519.631 66.4538C511.57 55.0303 501.416 44.5621 501.168 29.3309C500.997 18.4702 509.752 12.8566 521.437 17.1485Z" fill="black"/>
    <path d="M1066.17 67.6376C1060.77 55.9263 1055.02 43.5084 1049.44 31.4045C1056.15 33.0271 1067.81 58.4125 1066.17 67.6376Z" fill="black"/>
    <path d="M1093.26 19.7655C1076.07 21.5713 1071.62 29.3439 1078.62 44.2087C1081.75 50.856 1086.01 56.9799 1089.73 63.3525C1090.72 65.0535 1091.57 66.82 1091.46 69.0707C1083.4 57.6473 1073.24 47.1791 1072.99 31.9479C1072.82 21.0871 1081.58 15.4735 1093.26 19.7655Z" fill="black"/>
    <path d="M1095.85 45.1833C1094.78 38.994 1093.69 32.8177 1092.62 26.6284C1093.49 26.4714 1094.38 26.3274 1095.26 26.1704C1096.26 32.4252 1097.25 38.6799 1098.24 44.9347C1097.45 45.0132 1096.65 45.0917 1095.85 45.1702V45.1833Z" fill="black"/>
    <path d="M1108.91 17.0107C1117.81 30.6063 1120.82 46.3871 1125.03 61.723C1124.49 61.9324 1123.94 62.1418 1123.41 62.3511C1117.94 47.5386 1112.48 32.713 1107.01 17.9005C1107.64 17.5996 1108.28 17.3117 1108.91 17.0107Z" fill="black"/>
    <path d="M1145.35 59.3677C1142.09 59.4986 1137.43 61.0688 1135.85 59.4986C1131.1 54.8141 1135.21 49.7763 1136.73 43.9141C1141.22 48.0752 1130.84 60.7417 1146.78 55.7824C1146.31 56.9732 1145.82 58.177 1145.35 59.3677Z" fill="black"/>
    <path d="M1147.04 31.4045C1152.22 33.3019 1157.64 34.7151 1162.49 37.2406C1166.47 39.3081 1171.01 42.5924 1168.66 47.9835C1166.51 52.9036 1161.63 54.343 1156.17 53.885C1156.33 53.1915 1156.32 52.8905 1156.44 52.7989C1159.51 50.4174 1162.59 48.0489 1165.67 45.6805C1162.4 42.841 1159.39 39.609 1155.79 37.2929C1153.01 35.5002 1149.47 34.8591 1146.27 33.7076C1146.53 32.9355 1146.79 32.1766 1147.04 31.4045Z" fill="black"/>
    <path d="M296.76 67.6376C291.356 55.9263 285.612 43.5084 280.024 31.4045C286.737 33.0271 298.396 58.4125 296.76 67.6376Z" fill="black"/>
    <path d="M323.849 19.7655C306.655 21.5713 302.206 29.3439 309.207 44.2087C312.334 50.856 316.6 56.9799 320.316 63.3525C321.311 65.0535 322.161 66.82 322.044 69.0707C313.983 57.6473 303.829 47.1791 303.58 31.9479C303.41 21.0871 312.164 15.4735 323.849 19.7655Z" fill="black"/>
    <path d="M326.438 45.1833C325.365 38.994 324.279 32.8177 323.206 26.6284C324.082 26.4714 324.972 26.3274 325.849 26.1704C326.843 32.4252 327.838 38.6799 328.832 44.9347C328.034 45.0132 327.236 45.0917 326.438 45.1702V45.1833Z" fill="black"/>
    <path d="M339.497 17.0107C348.395 30.6063 351.404 46.3871 355.618 61.723C355.081 61.9324 354.532 62.1418 353.995 62.3511C348.526 47.5386 343.069 32.713 337.599 17.9005C338.228 17.5996 338.869 17.3117 339.497 17.0107Z" fill="black"/>
    <path d="M375.939 59.3677C372.681 59.4986 368.022 61.0688 366.439 59.4986C361.689 54.8141 365.798 49.7763 367.316 43.9141C371.804 48.0752 361.427 60.7417 377.365 55.7824C376.894 56.9732 376.41 58.177 375.939 59.3677Z" fill="black"/>
    <path d="M377.627 31.4045C382.809 33.3019 388.226 34.7151 393.081 37.2406C397.059 39.3081 401.599 42.5924 399.244 47.9835C397.098 52.9036 392.217 54.343 386.761 53.885C386.918 53.1915 386.905 52.8905 387.022 52.7989C390.097 50.4174 393.172 48.0489 396.261 45.6805C392.989 42.841 389.98 39.609 386.381 37.2929C383.594 35.5002 380.061 34.8591 376.855 33.7076C377.117 32.9355 377.379 32.1766 377.627 31.4045Z" fill="black"/>
    <path d="M984.221 57.1433C989.259 56.9078 994.297 56.6723 999.348 56.4367C999.492 55.4161 999.636 54.4085 999.78 53.3879C996.43 51.7129 993.146 49.8679 989.717 48.3893C981.277 44.7254 979.04 37.2799 983.999 28.7876C984.182 28.8792 984.536 29.0101 984.522 29.0493C981.644 40.8915 987.781 46.4527 998.236 49.5801C1000.16 50.1558 1001.36 53.1523 1002.91 55.0235C1000.88 56.4891 998.982 58.9098 996.796 59.1977C992.675 59.7342 988.383 59.0276 984.169 58.8444C984.182 58.2817 984.208 57.706 984.221 57.1433Z" fill="black"/>
    <path d="M945.92 43.1814C947.712 48.4548 949.505 53.7412 951.285 59.0146L949.413 59.7474C947.424 54.5656 945.435 49.3838 943.447 44.189C944.271 43.8488 945.095 43.5216 945.92 43.1814Z" fill="black"/>
    <path d="M972.318 58.2841C966.823 51.0741 961.588 43.6548 955.739 36.7588C953.148 33.6969 949.563 31.2238 945.978 29.3264C943.753 28.1488 940.338 27.6908 938.153 28.5937C936.962 29.0909 937.25 33.1081 936.072 36.1962C935.444 33.6315 933.678 30.5826 934.502 28.6199C935.313 26.6963 938.781 24.7859 941.032 24.8644C944.054 24.9691 947.273 26.5392 949.943 28.2272C961.17 35.3456 967.608 46.1933 972.331 58.2841H972.318Z" fill="black"/>
    <path d="M817.646 38.8953C817.267 36.3437 816.416 34.3155 817.005 33.7135C818.196 32.5097 820.682 31.0049 821.755 31.5021C826.099 33.5565 830.221 36.1474 834.094 39C834.657 39.4056 833.597 42.4021 832.864 44.0116C832.472 44.8622 831.242 45.4772 830.26 45.8697C814.139 52.2161 808.198 65.7855 804.98 81.3177C804.757 82.3645 804.43 83.3851 804.037 84.8245C802.48 77.6538 803.684 68.2717 810.672 58.497C815.736 51.4179 823.705 46.4193 830.457 40.4263C826.413 34.4725 822.632 33.4911 817.646 38.8953Z" fill="black"/>
    <path d="M856.312 4.63915e-10C856.312 14.8256 856.443 29.6643 856.076 44.4898C856.037 45.8376 852.779 47.1069 850.633 48.7033C849.102 51.8961 847.205 55.8871 845.294 59.865C844.862 59.6164 844.43 59.3678 843.999 59.1191C845.307 53.2962 846.603 47.4733 848.016 41.2054C849.508 41.5718 851.078 41.9513 853.433 42.5271V4.63915e-10C854.388 4.63915e-10 855.357 -2.71542e-05 856.312 0.0130581V4.63915e-10Z" fill="black"/>
    <path d="M879.054 54.5524C873.65 42.8411 867.905 30.4232 862.318 18.3193C869.031 19.9419 880.69 45.3273 879.054 54.5524Z" fill="black"/>
    <path d="M885.178 36.0236C884.105 29.8343 883.019 23.6581 881.946 17.4687C882.823 17.3117 883.712 17.1678 884.589 17.0107C885.584 23.2655 886.578 29.5202 887.573 35.775C886.774 35.8535 885.976 35.932 885.178 36.0105V36.0236Z" fill="black"/>
    <path d="M1227.25 43.804C1229.05 49.0773 1230.84 54.3638 1232.62 59.6372L1230.75 60.3699C1228.76 55.1882 1226.77 50.0064 1224.78 44.8115C1225.6 44.4713 1226.43 44.1442 1227.25 43.804Z" fill="black"/>
    <path d="M1253.65 58.9067C1248.16 51.6967 1242.92 44.2773 1237.07 37.3814C1234.48 34.3195 1230.9 31.8464 1227.31 29.949C1225.09 28.7713 1221.67 28.3133 1219.49 29.2162C1218.29 29.7135 1218.58 33.7306 1217.4 36.8188C1216.78 34.254 1215.01 31.2052 1215.83 29.2424C1216.65 27.3189 1220.11 25.4084 1222.36 25.487C1225.39 25.5916 1228.61 27.1618 1231.28 28.8498C1242.5 35.9682 1248.94 46.8159 1253.66 58.9067H1253.65Z" fill="black"/>
    <path d="M1256.8 44.4975C1255.73 38.3082 1254.64 32.1319 1253.57 25.9426C1254.44 25.7856 1255.33 25.6416 1256.21 25.4846C1257.2 31.7394 1258.2 37.9941 1259.19 44.2489C1258.4 44.3274 1257.6 44.4059 1256.8 44.4844V44.4975Z" fill="black"/>
    <path d="M1267.23 30.5093V51.5504C1266.61 51.5504 1265.98 51.5504 1265.35 51.5504C1265.35 43.0319 1265.28 34.5134 1265.56 26.008C1265.57 25.5762 1269.71 24.477 1270.09 25.0005C1274.05 30.5093 1277.65 36.293 1281.35 41.9982C1280.66 42.5085 1279.97 43.0188 1279.26 43.5161C1275.88 39.0017 1272.51 34.4873 1269.12 29.9598C1268.49 30.1299 1267.86 30.3131 1267.22 30.4832L1267.23 30.5093Z" fill="black"/>
    <path d="M196.711 53.0152C209.874 53.6171 211.183 52.6619 214.873 39.8121C216.748 33.2957 220.369 32.2794 225.734 36.7633C228.403 39.0139 230.968 41.3954 233.585 43.7115C233.205 44.1695 232.813 44.6275 232.433 45.0855C228.39 42.089 224.347 39.0793 221.193 36.7502C216.862 43.1358 213.63 49.77 208.592 54.4937C206.354 56.6005 200.492 54.8471 196.279 54.8471C196.423 54.2321 196.554 53.6171 196.697 53.0021L196.711 53.0152Z" fill="black"/>
    <path d="M138.56 43.804C140.352 49.0773 142.145 54.3638 143.925 59.6372L142.054 60.3699C140.065 55.1882 138.076 50.0064 136.087 44.8115C136.911 44.4713 137.735 44.1442 138.56 43.804Z" fill="black"/>
    <path d="M164.958 58.2841C159.463 51.0741 154.229 43.6548 148.379 36.7588C145.789 33.6969 142.203 31.2238 138.618 29.3264C136.393 28.1488 132.978 27.6908 130.793 28.5937C129.602 29.0909 129.89 33.1081 128.712 36.1962C128.084 33.6315 126.318 30.5826 127.142 28.6199C127.953 26.6963 131.421 24.7859 133.672 24.8644C136.694 24.9691 139.913 26.5392 142.583 28.2272C153.81 35.3456 160.248 46.1933 164.972 58.2841H164.958Z" fill="black"/>
    <path d="M168.106 43.8749C167.033 37.6856 165.947 31.5094 164.874 25.3201C165.751 25.163 166.641 25.0191 167.517 24.8621C168.512 31.1168 169.506 37.3716 170.501 43.6263C169.703 43.7048 168.905 43.7833 168.106 43.8618V43.8749Z" fill="black"/>
    <path d="M178.542 34.4349V55.476C177.914 55.476 177.286 55.476 176.658 55.476C176.658 46.9575 176.592 38.439 176.867 29.9335C176.88 29.5017 181.015 28.4026 181.395 28.926C185.359 34.4349 188.958 40.2186 192.661 45.9237C191.967 46.434 191.274 46.9444 190.567 47.4416C187.191 42.9272 183.815 38.4128 180.426 33.8853C179.798 34.0554 179.17 34.2386 178.529 34.4087L178.542 34.4349Z" fill="black"/>
    <path d="M567.9 74.0756C569.169 69.8884 570.425 65.688 571.695 61.5007L573.461 61.9979C574.181 66.8133 572.977 71.1052 569.457 74.7691L567.9 74.0756Z" fill="black"/>
    <path d="M592.657 75.9338C588.771 59.1455 584.885 42.3571 580.985 25.5687C581.98 25.3332 582.974 25.0976 583.982 24.8621C587.763 41.6504 591.545 58.4519 595.34 75.2403C594.45 75.4627 593.56 75.6983 592.657 75.9207V75.9338Z" fill="black"/>
    <path d="M612.115 51.9355C606.711 40.2242 600.966 27.8062 595.379 15.7024C602.092 17.325 613.751 42.7104 612.115 51.9355Z" fill="black"/>
    <path d="M678.405 10.4683C687.303 24.0638 690.312 39.8446 694.526 55.1806C693.989 55.3899 693.44 55.5993 692.903 55.8086C687.434 40.9961 681.977 26.1706 676.507 11.358C677.136 11.0571 677.777 10.7692 678.405 10.4683Z" fill="black"/>
    <path d="M714.847 52.8253C711.589 52.9561 706.93 54.5263 705.347 52.9561C700.597 48.2716 704.706 43.2338 706.224 37.3716C710.712 41.5327 700.335 54.1992 716.273 49.2399C715.802 50.4307 715.318 51.6345 714.847 52.8253Z" fill="black"/>
    <path d="M730.013 36.6387C731.806 41.912 733.598 47.1985 735.378 52.4719L733.507 53.2046C731.518 48.0229 729.529 42.8411 727.54 37.6463C728.364 37.306 729.189 36.9789 730.013 36.6387Z" fill="black"/>
    <path d="M656.964 55.6669C651.468 48.4569 646.234 41.0376 640.385 34.1417C637.794 31.0797 634.209 28.6066 630.623 26.7092C628.399 25.5316 624.984 25.0736 622.798 25.9765C621.608 26.4737 621.895 30.4909 620.718 33.579C620.09 31.0143 618.323 27.9655 619.148 26.0027C619.959 24.0791 623.426 22.1687 625.677 22.2472C628.7 22.3519 631.919 23.9221 634.588 25.6101C645.815 32.7284 652.253 43.5761 656.977 55.6669H656.964Z" fill="black"/>
    <path d="M756.412 51.7414C750.916 44.5314 745.682 37.1121 739.833 30.2161C737.242 27.1542 733.656 24.6811 730.071 22.7837C727.847 21.606 724.431 21.1481 722.246 22.0509C721.055 22.5482 721.343 26.5653 720.166 29.6535C719.537 27.0888 717.771 24.0399 718.595 22.0771C719.407 20.1536 722.874 18.2432 725.125 18.3217C728.148 18.4264 731.367 19.9965 734.036 21.6845C745.263 28.8029 751.701 39.6506 756.425 51.7414H756.412Z" fill="black"/>
    <path d="M667.93 37.0518V58.0929C667.302 58.0929 666.674 58.0929 666.046 58.0929C666.046 49.5744 665.981 41.0559 666.256 32.5505C666.269 32.1187 670.403 31.0195 670.783 31.5429C674.748 37.0518 678.346 42.8355 682.049 48.5407C681.356 49.051 680.662 49.5613 679.956 50.0586C676.58 45.5441 673.204 41.0298 669.815 36.5023C669.187 36.6724 668.559 36.8556 667.917 37.0257L667.93 37.0518Z" fill="black"/>
    </svg>
    ,
    handWirtingStatmentMobile: <svg width="325" height="78" viewBox="0 0 325 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.5147 75.6354C48.223 74.0223 46.9314 72.4033 45.3242 70.3973C45.0861 73.0164 44.8837 75.2128 44.6813 77.4092C44.3421 77.3676 44.0028 77.3259 43.6694 77.2842V66.6294C43.9016 66.5163 44.1337 66.3973 44.3718 66.2842C46.3718 69.1413 48.3718 71.9985 50.3718 74.8557C50.0861 75.1176 49.8004 75.3735 49.5147 75.6354Z" fill="black"/>
    <path d="M54.9317 66.7306C57.1043 63.6532 56.2829 59.2068 53.3067 56.9865C51.1459 55.3734 50.1876 56.927 49.2412 58.7068C48.9733 58.5163 48.7055 58.3258 48.4316 58.1353C48.8959 57.1592 49.134 55.6472 49.8781 55.3377C50.9733 54.8794 52.7174 54.808 53.6757 55.3972C56.9138 57.4092 58.0864 60.7425 57.765 64.3675C57.6043 66.2128 57.8364 67.4925 59.0507 68.9032C61.4079 71.6294 61.0448 74.433 58.265 77.2366C58.4614 75.2604 59.2174 73.1295 58.6876 71.4033C58.1579 69.689 56.3245 68.3735 54.9376 66.7247L54.9317 66.7306Z" fill="black"/>
    <path d="M65.8542 74.3972C67.0685 74.2127 68.2888 74.0281 70.1638 73.7424C67.2411 69.9805 64.6816 66.6888 62.1221 63.3972C62.4078 63.171 62.6875 62.9448 62.9733 62.7126C65.6935 66.171 68.4435 69.6055 71.0566 73.1412C71.2174 73.3615 70.3661 74.8496 69.8126 74.9686C68.5268 75.2484 67.14 75.0579 65.7888 75.0579C65.8066 74.8377 65.8304 74.6174 65.8483 74.3972H65.8542Z" fill="black"/>
    <path d="M80.2722 52.0609C72.4507 52.8824 70.4269 56.4181 73.6114 63.18C75.034 66.2038 76.9745 68.9896 78.665 71.8884C79.1174 72.6622 79.5043 73.4658 79.4507 74.4896C75.784 69.2931 71.165 64.5312 71.0519 57.6026C70.9745 52.6621 74.9567 50.1085 80.2722 52.0609Z" fill="black"/>
    <path d="M254.807 76.138C253.575 73.7451 252.343 71.3463 251.105 68.9534C254.7 66.6201 255.188 65.0546 253.2 62.2272C251.426 59.7034 249.742 59.5367 247.033 61.6201C246.902 61.3522 246.777 61.0903 246.646 60.8225C247.867 60.2272 249.426 58.8463 250.248 59.1975C252.081 59.9772 253.932 61.3582 255.063 62.9892C256.194 64.6261 255.938 66.6737 254.349 68.4296C253.795 69.0427 253.837 70.4773 254.039 71.4415C254.355 72.9594 255.045 74.3999 255.575 75.8761C255.319 75.9654 255.069 76.0547 254.813 76.144L254.807 76.138Z" fill="black"/>
    <path d="M265.54 60.3377C267.183 65.4925 269.48 70.4628 270.248 76.7902C267.706 74.9033 265.933 73.5878 263.742 71.9568C263.117 73.6235 262.641 74.8973 262.165 76.1711C261.95 76.1295 261.742 76.0878 261.528 76.0461C261.861 74.5164 261.814 71.9747 262.617 71.6711C264.034 71.1354 265.968 71.9509 267.962 72.2425C266.843 68.5759 265.635 64.6175 264.427 60.6592C264.796 60.552 265.171 60.4389 265.54 60.3318V60.3377Z" fill="black"/>
    <path d="M280.868 52.6561C273.047 53.4776 271.023 57.0133 274.208 63.7752C275.63 66.799 277.571 69.5848 279.261 72.4836C279.714 73.2574 280.1 74.061 280.047 75.0848C276.38 69.8883 271.761 65.1264 271.648 58.1978C271.571 53.2573 275.553 50.7037 280.868 52.6561Z" fill="black"/>
    <path d="M178.664 75.6233C176.206 70.2959 173.593 64.6471 171.051 59.1411C174.105 59.8792 179.408 71.4269 178.664 75.6233Z" fill="black"/>
    <path d="M190.987 53.8466C183.166 54.668 181.142 58.2037 184.326 64.9657C185.749 67.9895 187.689 70.7752 189.38 73.674C189.832 74.4478 190.219 75.2514 190.166 76.2752C186.499 71.0788 181.88 66.3169 181.767 59.3883C181.689 54.4478 185.672 51.8942 190.987 53.8466Z" fill="black"/>
    <path d="M192.164 65.4091C191.676 62.5936 191.182 59.7841 190.694 56.9686C191.093 56.8972 191.497 56.8317 191.896 56.7603C192.349 59.6055 192.801 62.4508 193.253 65.296C192.89 65.3317 192.527 65.3674 192.164 65.4031V65.4091Z" fill="black"/>
    <path d="M198.105 52.5935C202.152 58.7781 203.521 65.9567 205.438 72.9329C205.194 73.0281 204.944 73.1233 204.7 73.2186C202.212 66.4805 199.73 59.7364 197.242 52.9983C197.527 52.8614 197.819 52.7304 198.105 52.5935Z" fill="black"/>
    <path d="M214.682 71.8616C213.2 71.9211 211.081 72.6354 210.36 71.9211C208.2 69.7901 210.069 67.4985 210.759 64.8318C212.801 66.7247 208.081 72.4866 215.331 70.2306C215.116 70.7723 214.896 71.3199 214.682 71.8616Z" fill="black"/>
    <path d="M215.45 59.1411C217.807 60.0042 220.272 60.6471 222.48 61.7959C224.29 62.7364 226.355 64.2304 225.284 66.6828C224.307 68.9209 222.087 69.5757 219.605 69.3673C219.677 69.0519 219.671 68.9149 219.724 68.8733C221.123 67.7899 222.522 66.7126 223.927 65.6352C222.438 64.3435 221.069 62.8733 219.432 61.8197C218.165 61.0042 216.557 60.7126 215.099 60.1887C215.218 59.8376 215.337 59.4923 215.45 59.1411Z" fill="black"/>
    <path d="M133.152 68.9718C139.14 69.2456 139.735 68.8111 141.414 62.9658C142.267 60.0015 143.914 59.5392 146.354 61.5789C147.569 62.6027 148.735 63.686 149.926 64.7396C149.753 64.9479 149.575 65.1563 149.402 65.3646C147.563 64.0015 145.723 62.6325 144.289 61.5729C142.319 64.4777 140.848 67.4956 138.557 69.6444C137.539 70.6027 134.872 69.8051 132.956 69.8051C133.021 69.5254 133.081 69.2456 133.146 68.9658L133.152 68.9718Z" fill="black"/>
    <path d="M106.7 64.7815C107.515 67.1803 108.331 69.5851 109.14 71.9839L108.289 72.3173C107.384 69.9601 106.479 67.6029 105.575 65.2398C105.95 65.0851 106.325 64.9363 106.7 64.7815Z" fill="black"/>
    <path d="M118.708 71.3685C116.208 68.0888 113.827 64.7137 111.166 61.5768C109.988 60.184 108.357 59.059 106.726 58.1959C105.714 57.6601 104.16 57.4518 103.166 57.8625C102.625 58.0887 102.756 59.9161 102.22 61.3209C101.934 60.1542 101.131 58.7673 101.506 57.8744C101.875 56.9994 103.452 56.1304 104.476 56.1661C105.851 56.2137 107.315 56.928 108.529 57.6958C113.637 60.9339 116.565 65.8685 118.714 71.3685H118.708Z" fill="black"/>
    <path d="M120.14 64.8139C119.652 61.9984 119.158 59.1889 118.67 56.3734C119.069 56.3019 119.473 56.2365 119.872 56.165C120.325 59.0103 120.777 61.8555 121.229 64.7008C120.866 64.7365 120.503 64.7722 120.14 64.8079V64.8139Z" fill="black"/>
    <path d="M124.888 60.5196V70.0911C124.602 70.0911 124.316 70.0911 124.03 70.0911C124.03 66.2161 124.001 62.341 124.126 58.472C124.132 58.2755 126.013 57.7755 126.185 58.0136C127.989 60.5196 129.626 63.1506 131.31 65.7458C130.995 65.978 130.679 66.2101 130.358 66.4363C128.822 64.3827 127.286 62.3291 125.745 60.2696C125.459 60.347 125.173 60.4303 124.882 60.5077L124.888 60.5196Z" fill="black"/>
    <path d="M226.661 30.7681C224.203 25.4407 221.59 19.7919 219.048 14.2859C222.102 15.024 227.406 26.5717 226.661 30.7681Z" fill="black"/>
    <path d="M238.984 8.99134C231.163 9.81277 229.139 13.3485 232.323 20.1104C233.746 23.1343 235.686 25.92 237.377 28.8188C237.829 29.5926 238.216 30.3962 238.163 31.42C234.496 26.2236 229.877 21.4616 229.764 14.533C229.686 9.59253 233.669 7.03895 238.984 8.99134Z" fill="black"/>
    <path d="M240.161 20.5536C239.673 17.7381 239.179 14.9286 238.691 12.1131C239.09 12.0417 239.494 11.9762 239.893 11.9048C240.346 14.75 240.798 17.5953 241.25 20.4405C240.887 20.4762 240.524 20.512 240.161 20.5477V20.5536Z" fill="black"/>
    <path d="M246.102 7.73804C250.149 13.9226 251.519 21.1012 253.435 28.0774C253.191 28.1726 252.941 28.2679 252.697 28.3631C250.209 21.625 247.727 14.8809 245.239 8.1428C245.524 8.00589 245.816 7.87494 246.102 7.73804Z" fill="black"/>
    <path d="M262.679 27.0061C261.197 27.0656 259.078 27.7799 258.358 27.0656C256.197 24.9347 258.066 22.643 258.757 19.9763C260.798 21.8692 256.078 27.6311 263.328 25.3752C263.114 25.9168 262.894 26.4644 262.679 27.0061Z" fill="black"/>
    <path d="M263.447 14.2859C265.804 15.149 268.268 15.7919 270.477 16.9407C272.286 17.8812 274.352 19.3752 273.28 21.8276C272.304 24.0657 270.084 24.7204 267.602 24.5121C267.673 24.1966 267.667 24.0597 267.721 24.0181C269.12 22.9347 270.518 21.8573 271.923 20.7799C270.435 19.4883 269.066 18.018 267.429 16.9645C266.161 16.149 264.554 15.8573 263.096 15.3335C263.215 14.9823 263.334 14.6371 263.447 14.2859Z" fill="black"/>
    <path d="M189.382 25.9943C191.674 25.8872 193.965 25.78 196.263 25.6729C196.328 25.2086 196.394 24.7503 196.459 24.286C194.936 23.5241 193.442 22.6848 191.882 22.0121C188.043 20.3455 187.025 16.9586 189.281 13.0955C189.364 13.1371 189.525 13.1967 189.519 13.2145C188.209 18.6014 191.001 21.1312 195.757 22.5538C196.632 22.8157 197.18 24.1788 197.882 25.03C196.959 25.6967 196.096 26.7979 195.102 26.9288C193.227 27.1729 191.275 26.8514 189.358 26.7681C189.364 26.5122 189.376 26.2503 189.382 25.9943Z" fill="black"/>
    <path d="M171.959 19.6431C172.774 22.0419 173.59 24.4467 174.399 26.8455L173.548 27.1788C172.643 24.8217 171.739 22.4645 170.834 20.1014C171.209 19.9467 171.584 19.7978 171.959 19.6431Z" fill="black"/>
    <path d="M183.968 26.5131C181.468 23.2333 179.087 19.8583 176.426 16.7214C175.248 15.3285 173.617 14.2035 171.986 13.3404C170.974 12.8047 169.42 12.5963 168.426 13.0071C167.884 13.2332 168.015 15.0606 167.48 16.4654C167.194 15.2987 166.39 13.9118 166.765 13.019C167.134 12.144 168.712 11.2749 169.736 11.3106C171.111 11.3583 172.575 12.0725 173.789 12.8404C178.896 16.0785 181.825 21.013 183.974 26.5131H183.968Z" fill="black"/>
    <path d="M113.608 17.6934C113.435 16.5327 113.048 15.61 113.316 15.3362C113.858 14.7886 114.989 14.1041 115.477 14.3303C117.453 15.2648 119.328 16.4434 121.09 17.741C121.346 17.9255 120.864 19.2886 120.53 20.0208C120.352 20.4077 119.792 20.6875 119.346 20.866C112.013 23.7529 109.31 29.9256 107.846 36.9911C107.745 37.4673 107.596 37.9316 107.417 38.5863C106.709 35.3244 107.257 31.0565 110.435 26.6101C112.739 23.3898 116.364 21.116 119.435 18.3898C117.596 15.6815 115.876 15.235 113.608 17.6934Z" fill="black"/>
    <path d="M131.197 2.11032e-10C131.197 6.74407 131.256 13.4941 131.09 20.2382C131.072 20.8513 129.59 21.4287 128.614 22.1549C127.917 23.6072 127.054 25.4227 126.185 27.2323C125.989 27.1192 125.792 27.0061 125.596 26.893C126.191 24.2441 126.78 21.5953 127.423 18.7441C128.102 18.9108 128.816 19.0834 129.887 19.3453V2.11032e-10C130.322 2.11032e-10 130.762 -1.23523e-05 131.197 0.00594005V2.11032e-10Z" fill="black"/>
    <path d="M141.542 24.8157C139.084 19.4883 136.471 13.8395 133.929 8.3335C136.983 9.07159 142.286 20.6193 141.542 24.8157Z" fill="black"/>
    <path d="M144.328 16.3869C143.84 13.5714 143.346 10.7619 142.858 7.94637C143.257 7.87495 143.661 7.80947 144.06 7.73804C144.513 10.5833 144.965 13.4285 145.417 16.2738C145.054 16.3095 144.691 16.3452 144.328 16.3809V16.3869Z" fill="black"/>
    <path d="M299.937 19.9265C300.752 22.3253 301.567 24.7301 302.377 27.1289L301.526 27.4623C300.621 25.1051 299.716 22.748 298.812 20.3849C299.187 20.2301 299.562 20.0813 299.937 19.9265Z" fill="black"/>
    <path d="M311.945 26.7965C309.445 23.5167 307.064 20.1417 304.403 17.0048C303.225 15.6119 301.594 14.4869 299.963 13.6238C298.951 13.0881 297.397 12.8798 296.403 13.2905C295.861 13.5167 295.992 15.3441 295.457 16.7488C295.171 15.5822 294.367 14.1953 294.742 13.3024C295.111 12.4274 296.689 11.5584 297.713 11.5941C299.088 11.6417 300.552 12.356 301.766 13.1238C306.873 16.3619 309.802 21.2965 311.951 26.7965H311.945Z" fill="black"/>
    <path d="M313.377 20.2419C312.889 17.4264 312.395 14.6168 311.907 11.8014C312.306 11.7299 312.71 11.6644 313.109 11.593C313.562 14.4383 314.014 17.2835 314.466 20.1288C314.103 20.1645 313.74 20.2002 313.377 20.2359V20.2419Z" fill="black"/>
    <path d="M318.124 13.8787V23.4502C317.838 23.4502 317.552 23.4502 317.267 23.4502C317.267 19.5752 317.237 15.7002 317.362 11.8311C317.368 11.6347 319.249 11.1347 319.422 11.3728C321.225 13.8787 322.862 16.5097 324.547 19.1049C324.231 19.3371 323.916 19.5692 323.594 19.7954C322.058 17.7418 320.523 15.6883 318.981 13.6287C318.695 13.7061 318.41 13.7894 318.118 13.8668L318.124 13.8787Z" fill="black"/>
    <path d="M0 33.6966C0.577383 31.7918 1.14881 29.8811 1.72619 27.9763L2.52977 28.2025C2.85715 30.393 2.30953 32.3454 0.708338 34.012L0 33.6966Z" fill="black"/>
    <path d="M11.2617 34.5418C9.49382 26.9049 7.72597 19.268 5.95215 11.631C6.40453 11.5239 6.8569 11.4167 7.31524 11.3096C9.03548 18.9465 10.7557 26.5894 12.4819 34.2263C12.0772 34.3275 11.6724 34.4347 11.2617 34.5359V34.5418Z" fill="black"/>
    <path d="M20.1131 23.6253C17.6548 18.2979 15.0417 12.649 12.5 7.14307C15.5536 7.88116 20.8572 19.4288 20.1131 23.6253Z" fill="black"/>
    <path d="M50.2679 4.76196C54.3155 10.9465 55.6845 18.1251 57.6012 25.1013C57.3572 25.1966 57.1072 25.2918 56.8631 25.387C54.375 18.6489 51.8929 11.9048 49.4048 5.16672C49.6905 5.02982 49.9821 4.89887 50.2679 4.76196Z" fill="black"/>
    <path d="M66.8454 24.03C65.3633 24.0896 63.2442 24.8038 62.524 24.0896C60.3633 21.9586 62.2323 19.6669 62.9228 17.0002C64.9645 18.8931 60.2442 24.655 67.4942 22.3991C67.2799 22.9407 67.0597 23.4884 66.8454 24.03Z" fill="black"/>
    <path d="M73.7441 16.6667C74.5596 19.0656 75.3751 21.4704 76.1846 23.8692L75.3334 24.2025C74.4287 21.8454 73.5239 19.4882 72.6191 17.1251C72.9941 16.9703 73.3691 16.8215 73.7441 16.6667Z" fill="black"/>
    <path d="M40.5147 25.3226C38.0146 22.0429 35.6337 18.6678 32.973 15.5309C31.7944 14.1381 30.1634 13.0131 28.5325 12.15C27.5205 11.6142 25.967 11.4059 24.9729 11.8166C24.4313 12.0428 24.5622 13.8702 24.0265 15.275C23.7408 14.1083 22.9372 12.7214 23.3122 11.8285C23.6813 10.9535 25.2587 10.0845 26.2825 10.1202C27.6575 10.1678 29.1218 10.8821 30.3361 11.6499C35.4432 14.888 38.3718 19.8226 40.5206 25.3226H40.5147Z" fill="black"/>
    <path d="M85.7529 23.537C83.2529 20.2572 80.872 16.8822 78.2112 13.7453C77.0327 12.3524 75.4017 11.2274 73.7707 10.3643C72.7588 9.8286 71.2053 9.62027 70.2112 10.031C69.6695 10.2572 69.8005 12.0846 69.2648 13.4893C68.979 12.3227 68.1755 10.9358 68.5505 10.0429C68.9196 9.16789 70.4969 8.29885 71.5207 8.33456C72.8958 8.38218 74.36 9.09644 75.5743 9.8643C80.6815 13.1024 83.6101 18.037 85.7589 23.537H85.7529Z" fill="black"/>
    <path d="M45.5028 16.8548V26.4263C45.2171 26.4263 44.9314 26.4263 44.6457 26.4263C44.6457 22.5513 44.6159 18.6762 44.7409 14.8072C44.7469 14.6107 46.6278 14.1107 46.8004 14.3488C48.604 16.8548 50.241 19.4858 51.9255 22.081C51.61 22.3132 51.2945 22.5453 50.9731 22.7715C49.4374 20.7179 47.9016 18.6643 46.36 16.6048C46.0743 16.6822 45.7886 16.7655 45.4969 16.8429L45.5028 16.8548Z" fill="black"/>
    </svg>
    ,
    slides: {
        earth: [
            { imagePath: image1, description: '', width: '787.968', height: '577.483' },
            { imagePath: image2, description: '', width: '415.74', height: '313.573' },
            { imagePath: image3, description: '', width: '413.654', height: '260' },
            { imagePath: image4, description: '', width: '438.732', height: '225' },
            { imagePath: image5, description: '', width: '438.732', height: '260' },

        ],
        water: [
            { imagePath: image6, description: '', width: '335.654', height: '514' },
            { imagePath: image7, description: '', width: '280', height: '515' },
            { imagePath: image8, description: '', width: '456', height: '515' },
        ],
        fire: [
            { imagePath: image9, description: '', width: '455.619', height: '261.529' },
            { imagePath: image10, description: '', width: '415.739', height: '261.529' },
            { imagePath: image11, description: '', width: '427.211', height: '515' },
        ],
        air: [
            { imagePath: image12, description: '', width: '347.211', height: '515' },
            { imagePath: image13, description: '', width: '347.211', height: '515' },
            { imagePath: image14, description: '', width: '347.211', height: '515' },
        ]

    },
    carousle: [
        { img: cImage1 },
        { img: cImage2 },
        { img: cImage3, margin: -149 },
        { img: cImage4 },
        { img: cImage5 },
        { img: cImage6 },
        { img: cImage7, margin: -146 },
        { img: cImage8 },
        { img: cImage9, margin: -293 },
        { img: cImage10 },
        { img: cImage11, margin: -147 },
        { img: cImage12 },
        { img: cImage13, margin: -295 },
        { img: cImage14 },
        { img: cImage15, margin: -435 },
        { img: cImage16 },
        { img: cImage17, margin: -436 },
        { img: cImage18 },
        { img: cImage19, margin: -436 },
        { img: cImage20 },
        { img: cImage21, margin: -292 },
    ],
    mobileSwipper: [
        { img: cMobileImage0 },
        { img: cMobileImage1 },
        { img: cMobileImage2 },
        { img: cMobileImage3 },
        { img: cMobileImage4 },
        { img: cMobileImage5 },
        { img: cMobileImage6 },
        { img: cMobileImage7 },
        { img: cMobileImage8 },
        { img: cMobileImage9 },
        { img: cMobileImage10 },
        { img: cMobileImage11 },
        { img: cMobileImage12 },
        { img: cMobileImage13 },
        { img: cMobileImage14 }
    ]
}