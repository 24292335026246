import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const CustomModal = ({ open, onClose, content, width, minHeight = 'auto', isFullWidth, widthType = 'px' }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll='body'
            transitionDuration={900}            
            PaperProps={{
                sx: {
                    backgroundColor: '#232B2D',
                    borderRadius: '10px',
                    height: 'auto',
                    color: 'white', 
                    width: isFullWidth? '90vw': `${width}${widthType}`,
                    maxWidth: isFullWidth? '90vw': `${width}${widthType}`,
                    minWidth: isFullWidth? '90vw': `${width}${widthType}`,
                    padding: '13px 30px',
                    position: 'relative', 
                    minHeight:minHeight,
                    margin:isFullWidth ? '12vh auto' : 'unset'
                },
            }}
        >
            <IconButton
                onClick={onClose}
                sx={{
                    color: 'white',
                    position: 'absolute',
                    left: '40px',
                    top: '28px',
                    zIndex: 1,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ padding: '40px' }}>
                {content}
            </DialogContent>
        </Dialog>
    );
}
export default CustomModal