import React from "react";
import './talking-rooms-modal-btn.scss';
import CustomModal from "../custom-modal/custom-modal.jsx";
import CommonButton from "../button/button.jsx";
import useDeviceDetection from "../../../useDeviceDetection.jsx";
import { mainZoomLink, personsRouts, zoomBaseUrl } from "../../../models.js";
import ZoomRecordModal from "../elements-slides/zoom-record-modal/zoom-record-modal.jsx";





const TalkingRoomsModalBtn = ({ OpenButton, anotherActionOnClose }) => {
  const deviceType = useDeviceDetection();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleOpenModalBtnClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (anotherActionOnClose)
      anotherActionOnClose();
  };

  const roomSubjects = [
    { name: 'המליאה', zoom: mainZoomLink },
    { name: 'גילי אדר', zoom: personsRouts[4].zoomLink },
    { name: 'לבנת קוץ', zoom: personsRouts[3].zoomLink },
    { name: 'מתן רוזנברג ונוי מעודי', zoom: personsRouts[2].zoomLink },
    { name: 'מתי וייס', zoom: personsRouts[6].zoomLink },
    { name: 'משה ואליעד אוחיון', zoom: personsRouts[0].zoomLink }
  ];

  const modalContent = <div className={`schedule-modal-content ${deviceType.isMobile ? 'schedule-modal-content-mobile' : ''}`}>
    <div className="scheduler-buttons-container">
      :לצפיה בהקלטות המרגשות של ערב ההנצחה
      {roomSubjects.map((subject, i) =>
        <ZoomRecordModal openButton={(onClick) =>
          <CommonButton
            key={`room${i}`}
            width={'298px'}
            minHeight='56px'
            fillColor="#000"
            contentColor="#fff"
            outlineColor="#fff"
            onClick={onClick}
            text={`לחדר שיח של ${subject.name}`}
          />}
          zoomRecordLink={subject.zoom}
          name={subject.name}
          anotherActionOnClose={handleModalClose}
        />
      )}
    </div>
  </div>

  return (<>
    <CustomModal open={isModalOpen} onClose={handleModalClose}
      content={modalContent} width={deviceType.isMobile ? '99vw' : 407} minHeight={deviceType.isMobile ? 'auto' : "460px"} />
    {OpenButton(handleOpenModalBtnClick)}
  </>
  );
}

export default TalkingRoomsModalBtn;
