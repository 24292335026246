import EmptyRow from "../common/empty-row/empty-row"
export const MosheOhayonTexts = {
    dates: '7.10.2023-29.09.1971',
    main: <>{`קצרה היריעה מלספר על משה. ידיו היו בכול: איש עשייה בעיר אופקים, מנחה לקורסי מנהיגות בקרן מנדל, מוביל עמותות, דמות מוכרת מאוד בתנועת בני עקיבא ועוד ועוד...
    
    ביום שישי, ערב שמחת תורה, התארחו ברחבי השכונה חונכים וכשמונים חניכים עם מוגבלויות, חמישה עשר מתוכם בבית משפחת אוחיון. בשבת בבוקר התעוררו לקול אזעקות ויריות. משה, אליעד ואמתי חיים (בניו) יצאו להגן על אופקים ולשמור על אחרים. משה ו`}
        <a href="https://rememberoct7.lnet.studio/#/Eliad-Ochion" target="blank">אליעד</a>
        {` לא חזרו`}
    </>,
    slides: {
        earth: {
            mainShortText: `משה היה איש עשייה ומנהיג חברתי מתחילת דרכו:
            ראש מחלקת מפעלים, מנהל הגרעין התורני, מנכ"ל קרן אלפ"א, מנהל בית הספר למנהיגות חברתית של קרן מנדל בצפון,
            ותפקידו האחרון – מנכ"ל 929.`,
            mainText: <>
                {`משה היה בנם של יצחק ונינט אוחיון. בן בכור מבין שבעה ילדים.\nמשה נולד בבית החולים דז'אני ביפו. עד גיל 4 גר משה במעברה בבת ים, ולאחר מכן עברה המשפחה למושב יד רמב"ם.\nכבן בכור, לקח על עצמו כבר מגיל צעיר את האחריות לטיפול בלול ובעבודות משק נוספות.
בצבא שירת כמ"מ בגרעין תומר לקיבוץ מירב בנח"ל. את המסלול עזב כדי לצאת לקורס קצינים.\nבקורס קצינים שאל אותו האלוף גיורא איילנד מה היה עושה לולא היה איש צבא, ומשה ענה שהיה הולך לחזק את הפריפריה.\nפסגת שאיפותיו של משה הייתה להיות רמטכ"ל, אך למרות זאת, כשהיה מ"מ, נאלץ להשתחרר מהצבא מסיבה רפואית. 
בגיל 49 הצליח בעזרת חבר להשיג אישור למילואים בפיקוד העורף מרכז. בתחילה בתפקיד מ"פ מפקדה, ולאחר מכן מ"פ רפואה.
את שרית אשתו פגש כאשר עבדו בהנהלת התנועה של בני עקיבא – הוא כראש מחלקת מפעלים והיא כסגנית של ראש מחלקת כוח אדם.\nשרית מספרת: "בכל טיול או פעילות של ההנהלה משה היה משבץ את כל העובדים של ההנהלה. במסע פסח משה שיבץ אותי יחד עם חברה אצלו ברכב כדי לעזור לו, וכל השאר היסטוריה."\nתחילה גרו בירושלים ובמעלה אדומים, אלא שכדי לחזק את הפריפריה בחרו להקים משפחה באופקים. "יש עבודה רבה לעשות" בעיר – להפוך אותה לטובה יותר, חזקה יותר ומובילה.`}<EmptyRow />
                {`במהלך השנים משה ניהל וקידם פרויקטים רבים – בפיתוח העיר, בחינוך, בבנייה וברווחה.`}<EmptyRow />
                {`נולדו להם חמישה ילדים: אמתי חיים, אליעד (ז"ל), יאיר, שירה ואורי יוסף.\nמספרים שילדיהם של משה ושרית הם התגלגלות המעשים הטובים של הוריהם – מכפלות של מעשים טובים, אמונה בבני אדם ואחדות.`}
            </>
        },
        fire: {
            mainShortText: `התשוקה הגדולה של משה היתה עשיית טוב, לגרום לאנשים להאמין בעצמם ולהשפיע על המציאות.לצידה הייתה תשוקה לספרים, לטבע ולספורט.
            בשנים האחרונות גילה את כישרון הבישול והיה עורך ניסויים קולינריים על בני הבית והאורחים.`,
            mainShortTextMobile: `התשוקה הגדולה של משה היתה עשיית טוב, לגרום לאנשים להאמין בעצמם ולהשפיע על המציאות.לצידה הייתה תשוקה לספרים, 
            לטבע ולספורט.
            בשנים האחרונות גילה את כישרון הבישול והיה עורך ניסויים קולינריים על בני הבית והאורחים.`,
            mainText: `משה היה קרוי על שם סבא שלו. במרוקו דאג סבו למשפחות יהודיות רבות. משה הרגיש מחויבות להמשיך את שרשרת המעשים הטובים.
            העשייה זרמה בעורקיו והוא התייצב בכל מקום שנדרש. ככל שהפרויקט או ההפקה היו מורכבים וגדולים – משה היה האיש המתאים למשימה. בפרויקטים באופקים, בפעילויות ומשימות בבני עקיבא, בפיתוח קורסים ובהעברת קורסים בקרן מנדל ועוד.
            כל דבר נעשה בשלווה ובמקצועיות.
            כל רעיון של משה קרם עור וגידים בזכות החזון שלו. האמונה שלו בחזון הקרינה על הסובבים אותו. גם אם בתחילה לא היו מאמינים שהדבר אפשרי, הפך החזון למציאות. משה היה תמיד שם – עוזר, מלווה ומוביל. והכול בשקט וברוגע, בלי רעש וצלצולים.
            "כוחות בלתי נגמרים של מנהיגות וחסד," סיפר חברו.`
        },
        water: {
            mainShortText: `חבר תיאר את משה כמגנט: "משה מגנט ענק שכל אחד מתמגנט אליו, מכיוונים שונים. אחד רואה בו אבא, אחר רואה בו חבר נפש, מורה דרך שלא עוזב אותך ותמיד מכוון אותך במילים נכונות ובמעשים טובים."`,
            mainText: <>
                {` "הייחודיות של משה הייתה בהשפעה שלו על אנשים. הייתה לו יכולת ליצור קשרים אישיים בהתאם לסיטואציות שונות, ולכן היה לו מעגל קשרים מגוון ורחב. לכל משימה, מבצע אחרי מבצע – הוא נקרא לדגל.`}
                <EmptyRow height={11} />
                {`כשהגרעין התורני היה בקשיים ביקשו ממשה לנהל. במהלך ההתנתקות הוא נדרש לנהל פעילויות שונות, ויחד עם החניכים מקורס מנהיגות של "צידה לדרך" דאג ל־3000 בני נוער שהיו בנווה דקלים, בגוש קטיף. בפינוי ביקשה הנהגת הגוש את עזרתו בהוצאת הציוד הציבורי. שמו עבר בין התושבים, והחלו לבקש ממנו להוציא ציוד פרטי שלהם. הוא היה גאה על שהיה האזרח האחרון שיצא מגוש קטיף.`}
                <EmptyRow height={11} />
                {`במהלך מלחמת לבנון השנייה משה התבקש לטפל בכל המפונים לפארק אשכול והוא דאג לרווחתם.`}
                <EmptyRow height={11} />
                {`"ההפקות" של השנה האחרונה כללו בין היתר את הצעדות מהכותל לכנסת ואת סוכות ההידברות.`}
                <EmptyRow height={11} />
                {`משה היה איש חינוך בלתי פורמלי – האמין בחינוך דרך דוגמה אישית, עסק בתחומי מנהיגות שונים וניסה להשפיע על אנשים לעשות מעשים דומים לאלה ולגרום לאנשים להאמין בעצמם.`}
                <EmptyRow height={11} />
                {`בתחילה הוביל קורסי מנהיגות לבני נוער, בהמשך יזם תוכנית מנהיגות חברתית במרכז מנדל בצפון ולאחר מכן קורס מנהיגות חינוכית לאנשי החינוך בדרום, יחד עם מכללת הרצוג. לימים הפכה התוכנית להיות רשת של בוגרי הקורסים.`}
                <EmptyRow height={11} />
                {`חבריו אומרים שידעו שמשה מיוחד, אך רק לאחר מותו הבינו עד כמה. התברר שאנשים רבים ראו את הייחודיות של משה באופנים שונים. הוא נגע בכולם וטבע בהם את חותמו.`}                <EmptyRow />
            </>
        },
        air: {
            mainShortText: <><b>לקום ולעשות מעשה.</b><EmptyRow height={1}/>
            בצוואתו הרוחנית של משה מסרים שונים: להמשיך להיות מגדלור של מעשים טובים, להתאמץ עבור אחדות, להתרחק ממקומות שמחלישים אותך, להתמקד במעשים מחזקים ולעזור לאחר להצליח ולהתקדם.</>,
            mainText: <>הרשימה יכולה להתארך עוד ועוד, ודבר אחד ודאי – משה היה היה התגלמות של כל זה. בכל רמ"ח איבריו. הלכה למעשה. הוא לא רק לימד קורסים של מנהיגות אלא יישם את הפרקטיקה בצניעות ובענווה, הניע אנשים בקור רוח ובשקט נפשי, תוך כדי שמירה על אחדות וגישור מחלוקות.<EmptyRow />
                "היו במשה כל התכונות הטובות שנמנות בפרקי אבות. היה בו הכול עם הרבה ענווה."
                <video src="https://static.lnet.org.il/dev/remember7oct/moshe-ohayon.mp4" style={{ border: 'none', width: "560px", height: "315px", margin: '65px 130px 5px 0'}}
                    title='משה אוחיון הי"ד' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen controls={true}>
                </video>
            </>,
            mainTextMobile: <>הרשימה יכולה להתארך עוד ועוד, ודבר אחד ודאי – משה היה היה התגלמות של כל זה. בכל רמ"ח איבריו. הלכה למעשה. הוא לא רק לימד קורסים של מנהיגות אלא יישם את הפרקטיקה בצניעות ובענווה, הניע אנשים בקור רוח ובשקט נפשי, תוך כדי שמירה על אחדות וגישור מחלוקות.<EmptyRow />
                "היו במשה כל התכונות הטובות שנמנות בפרקי אבות. היה בו הכול עם הרבה ענווה."
                <video src="https://static.lnet.org.il/dev/remember7oct/moshe-ohayon.mp4"
                    style={{ width: '100%', borderRadius: '18px', marginTop: '16px'}}
                    title='משה אוחיון הי"ד' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen controls={true}>
                </video>
            </>,
        },
    },
    songHeader: 'אני ואתה נשנה את העולם',
    songAuthor: 'אריק איינשטיין',
    songLink: 'gP6PS-poyMg?si=ETfSPEMkjzAoYyge',
    song: [],
    padletLink: 'dq2o8jjwywmbt6hm'
}