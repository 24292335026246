import './person-3.scss'
import React from 'react';
import ElementsCarousle from '../common/elements-slides/elements-slides';
import TabHeaderAndExpandableText from '../common/elements-slides/tab-header-and-expandable-text/tab-header-and-expandable-text';
import useDeviceDetection from '../../useDeviceDetection';
import { MatanRozenbergTexst } from './person-3-texts';
import { MatanRozenbergImages } from './person-3-images';
import Swipper from '../common/swipper/swipper';
import { Outlet, useOutletContext } from 'react-router-dom';
import { personsRouts } from '../../models';
import SpeiaEdgeImage from '../common/speia-edge-image/speia-edge-image';
import Padlet from '../common/padlet/padlet';



const Person3 = () => {


  //#region elements slides
  const elementSlides = {
    earth: () => {
      return (<div className='tab-container' style={{ height: '515.12px', width: '1200px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textHeight='101px'
            text={MatanRozenbergTexst.slides.earth.mainText}
          />
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.earth[0]} />
        </div>
        <div>
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.earth[1]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: () => {
      return (<div className='tab-container' style={{ height: '515.12px', width: '1200px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"} textHeight='76px'
            text={MatanRozenbergTexst.slides.water.mainText}
          />
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.water[0]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.water[1]} />
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.water[2]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.water[3]} isCorner={true} />
        </div>
      </div>
      )
    },
    fire: () => {
      return (<div className='tab-container' style={{ height: '515.12px', width: '1204px' }}>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.fire[0]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.fire[1]} />
        </div>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"} textHeight='101px'
            text={MatanRozenbergTexst.slides.fire.mainText} />
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.fire[2]} isCorner={true} />
        </div>
      </div>
      )
    },
    air: () => {
      return (<div className='tab-container' style={{ height: '515.12px', width: '1204px' }}>
        <div className='tab-column' >
          <TabHeaderAndExpandableText header={"רוח"} textHeight='44px'
            text={MatanRozenbergTexst.slides.air.mainText}
          />
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.air[0]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.air[1]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.air[2]} isCorner={true} />
        </div>
      </div>
      )
    }

  };

  const mobileElementSlides = {
    earth: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textMobileWidth='330px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textHeight='100px'
            text={MatanRozenbergTexst.slides.earth.mainText} textFullHeight={'334.037px'} />
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.earth[0]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"}  textMobileWidth='313px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textHeight='100px'
            text={MatanRozenbergTexst.slides.water.mainTextMobile} textFullHeight={'302px'} />
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.water[2]} isCorner={true} />
        </div>
      </div>
      )
    },
    fire: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container tab-rows-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={MatanRozenbergTexst.slides.fire.mainText} textFullHeight={'565.3px'} />
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.fire[2]} isCorner={true} />
        </div>
      </div>
      )
    },
    air: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"רוח"} textMobileWidth='343px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textHeight='150px'
            text={MatanRozenbergTexst.slides.air.mainText} textFullHeight={'278.375px'}
          />
          <SpeiaEdgeImage image={MatanRozenbergImages.slides.air[2]} isCorner={true} />
        </div>
      </div>
      )
    }

  };
  //#endregion

  const deviceType = useDeviceDetection();
  const [selected, setSelected] = useOutletContext();



  return (
    <>
      <div className={`matan-rozeneberg-component ${(deviceType.isMobile) ? 'matan-rozeneberg-component-mobile' : ''}`}>
        <div className='section-start-01'>
          <div id='start' className='anchor-section-start'>
            <div className='handwriting-img'>
              <img src={MatanRozenbergImages.handWirtingStatment} alt="ואהבת לרעך כמוך" />
            </div>
            <div className='line'>
              <div> </div>
              <div className='line-style'> </div>
            </div>
            <div className='vertical-lines-container'>
              <div>
                <div className='vertical-line vertical-line-1' ></div>
                <div className='vertical-line vertical-line-2' ></div>
              </div>
              <div className='vertical-line vertical-line-3' ></div>
            </div>
          </div>
          <div className='person-section-1'>
            <img className='big-img' src={MatanRozenbergImages.bigImage} alt="{personsRouts[2].name.slice(0,-4)}" />
            <div className='text-group'>
              {deviceType.isMobile && <div className='top-right-corner'></div>}
              <div className='description-text-container'>
                <div>
                  <h1>{personsRouts[2].name}</h1>
                  <h3>{MatanRozenbergTexst.dates}</h3>
                </div>
                <p>{MatanRozenbergTexst.main}</p>
                <div className='signature'>
                  {/* {deviceType.isMobile ? MatanRozenbergImages.mobileSignature : MatanRozenbergImages.signature} */}
                </div>
                {deviceType.isMobile && <div className='bottom-left-corner'></div>}
              </div>
            </div>
          </div>
        </div>
        <div className='two-anchors-sections flex-section'>
          <div id="02" className='anchor-section-02 relative-conatainer flex-section'>
            {deviceType.isMobile && <h1  >סיפור אישי</h1>}
            <ElementsCarousle elementSlides={deviceType.isMobile ? mobileElementSlides : elementSlides}></ElementsCarousle>
          </div>
        </div>
        <div id="03" className='anchor-section-03 flex-section'>
          <div>
            <h2 className='section-header'>{MatanRozenbergTexst.songHeader}</h2>
            <div className='author-name-conatainer'>
              <h4>{MatanRozenbergTexst.songAuthor}</h4>
              <div className='decoration-line'></div>
              <div className='decoration-line decoration-line-2' style={{ marginRight: '16px' }}></div>
            </div>
          </div>
          <div className='song-container'>
            <iframe style={{
              borderRadius: '21px', border: 'none'
            }}
              title={MatanRozenbergTexst.song}
              src={`https://www.youtube.com/embed/${MatanRozenbergTexst.songLink}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}>
            </iframe>
          </div>
        </div>
        <div id="04" className='anchor-section-04 flex-section'>
          <div className='image-background-container'>
            <h2 className='section-header'>שיתופים על {personsRouts[2].name}</h2>
            <div className='decoration-line decoration-line-1'></div>
            <div className='decoration-line decoration-line-2' style={{ width: '909px', marginRight: '287px' }}></div>
            <Padlet padletLink={MatanRozenbergTexst.padletLink} />
          </div>
        </div>
        <div id="05" className='anchor-section-05 flex-section'>
          <h2 className='section-header'>אלבום תמונות</h2>
          {deviceType.isMobile && <div className='vertical-lines-container'>
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="7" viewBox="0 0 54 7" fill="none">
              <rect width="17.2037" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 17.2041 0.807129)" fill="black" />
              <rect width="12.0426" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 33.5469 0.807129)" fill="black" />
              <rect width="42.5792" height="1.29028" transform="matrix(1 0 0 -1 10.752 6.39795)" fill="black" />
            </svg>
          </div>}
          <div style={{ position: 'relative', maxWidth: !deviceType.isMobile ? '1168px' : '80vw' }}>
            {selected === '05' && <Swipper images={deviceType.isMobile ? MatanRozenbergImages.mobileSwipper : MatanRozenbergImages.carousle}
              height={'515px'} margin={'62px'} spaceBetween={20} slidesPerView={deviceType.isXLScreen ? 3.6 : 2.74} />
            } </div>
        </div>
      </div>
      <Outlet></Outlet>
    </>
  );
};
export default Person3;
