import './person-7.scss'
import React from 'react';
import ElementsCarousle from '../common/elements-slides/elements-slides';
import TabHeaderAndExpandableText from '../common/elements-slides/tab-header-and-expandable-text/tab-header-and-expandable-text';
import useDeviceDetection from '../../useDeviceDetection';
import { MattyWeissTexst } from './person-7-texts';
import Swipper from '../common/swipper/swipper';
import { Outlet, useOutletContext } from 'react-router-dom';
import { personsRouts } from '../../models';
import SpeiaEdgeImage from '../common/speia-edge-image/speia-edge-image';
import Padlet from '../common/padlet/padlet';
import { MattyWeissImages } from './person-7-images'
import TextOverlayImage from '../common/elements-slides/text-overlay-image/text-overlay-image';
import EmptyRow from '../common/empty-row/empty-row';



const Person7 = () => {

  //#region elements slides
  const elementSlides = {
    earth: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
      <div className='tab-column'>
        <div className='tab-row'>
          <TabHeaderAndExpandableText header={"אדמה"} textWidth='466px'
            text={MattyWeissTexst.slides.earth.mainText} textHeight='176px'
            mainShortText={MattyWeissTexst.slides.earth.mainShortText} 
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '852px' }} />
          <TextOverlayImage imagePath={MattyWeissImages.slides.earth[1].imagePath} width={'730px'} height={'304px'}
          />
        </div>
        <div className='tab-row'>
          <TextOverlayImage imagePath={MattyWeissImages.slides.earth[0].imagePath}
            width={'466px'} height={'219px'}
            text={MattyWeissTexst.slides.earth.imagesTexts[0]} textMaxWidth={"353px"}

          />
          <TextOverlayImage imagePath={MattyWeissImages.slides.earth[2].imagePath} width={'352px'} height={'219px'}
            text={MattyWeissTexst.slides.earth.imagesTexts[1]} textMaxWidth={"289px"}
          />
          <TextOverlayImage imagePath={MattyWeissImages.slides.earth[3].imagePath} width={'351px'} height={'219px'}
            text={MattyWeissTexst.slides.earth.imagesTexts[2]} textMaxWidth={"289px"}
            isCorner={true}
          />
        </div>
      </div>
    </div>
    )},
    water: () => {
      return (<div className='tab-container' style={{ height: '542px' , width: '1200px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"} textHeight='77px' textWidth='399px' customMoadlTextStyle={{width:'745px'}}
            text={MattyWeissTexst.slides.water.mainText} mainShortText={MattyWeissTexst.slides.water.mainShortText}
          />
          <SpeiaEdgeImage image={MattyWeissImages.slides.water[0]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MattyWeissImages.slides.water[1]} />
          <SpeiaEdgeImage image={MattyWeissImages.slides.water[2]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MattyWeissImages.slides.water[3]} isCorner={true} />
        </div>
      </div>
      )
    },
    fire: () => {
      return (<div className='tab-container' style={{ height: '542px', width: '1204px' }}>
      <div className='tab-column' >
        <TabHeaderAndExpandableText header={"אש"} textHeight='50px'
          text={MattyWeissTexst.slides.fire.mainText} textWidth='466px'
          mainShortText={MattyWeissTexst.slides.fire.mainShortText}
        />
        <SpeiaEdgeImage image={MattyWeissImages.slides.fire[0]} />
      </div>
      <div className='tab-column'>
        <SpeiaEdgeImage image={MattyWeissImages.slides.fire[1]} />
      </div>
      <div className='tab-column'>
        <SpeiaEdgeImage image={MattyWeissImages.slides.fire[2]} isCorner={true} />
      </div>
    </div>)
    },
    air: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <div className='tab-row'>
            <TabHeaderAndExpandableText header={"רוח"} textWidth='406px'
              text={MattyWeissTexst.slides.air.mainText} textHeight='72px'
              mainShortText={MattyWeissTexst.slides.air.mainShortText}
              customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }} />
            <TextOverlayImage imagePath={MattyWeissImages.slides.air[1].imagePath} width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={MattyWeissImages.slides.air[3].imagePath} width={'366px'} height={'249px'}
            />
          </div>
          <div className='tab-row'>
            <TextOverlayImage imagePath={MattyWeissImages.slides.air[0].imagePath}
              width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={MattyWeissImages.slides.air[2].imagePath} width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={MattyWeissImages.slides.air[4].imagePath} width={'366px'} height={'249px'}
              isCorner={true}
            />
          </div>
        </div>
      </div>
      )
    },

  };

  const mobileElementSlides = {
    earth: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textMobileWidth='314px'
            text={<>
              {MattyWeissTexst.slides.earth.mainShortText}
              <EmptyRow height={5} />
              {MattyWeissTexst.slides.earth.mobileMainText}
            </>
            }
            textHeight='229px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textFullHeight={'1170px'} />
          <SpeiaEdgeImage image={MattyWeissImages.slides.earth[0]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {MattyWeissTexst.slides.water.mainShortText}
              <EmptyRow height={5} />
              {MattyWeissTexst.slides.water.mainText}
            </>
            } textHeight='100px' textFullHeight={'584px'}
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }}
          />
          <SpeiaEdgeImage image={MattyWeissImages.slides.water[0]} isCorner={true} imageCrop={"top"} />
        </div>
      </div>
      )
    },
    fire: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container tab-rows-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {MattyWeissTexst.slides.fire.mainShortText}
              <EmptyRow height={5} />
              {MattyWeissTexst.slides.fire.mainText}
            </>
            }  textHeight='72px' textFullHeight={'570px'}/>
          <SpeiaEdgeImage image={MattyWeissImages.slides.fire[2]} isCorner={true} imageCrop='20%' />
        </div>
      </div>
      )
    },
    air: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"רוח"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {MattyWeissTexst.slides.air.mainShortText}
              <EmptyRow height={5} />
              {MattyWeissTexst.slides.air.mainText}
            </>
            } textHeight='100px' textFullHeight={'519px'} />

          <SpeiaEdgeImage image={MattyWeissImages.slides.air[1]} isCorner={true} />
        </div>
      </div>
      )
    }

  };
  //#endregion

  const deviceType = useDeviceDetection();
  const [selected, setSelected] = useOutletContext();
  
  // const [isMobileSongWordsWxpanded, setIsMobileSongWordsExpanded] = useState(false);

  return (
    <>
      <div className={`matty-weiss-component ${(deviceType.isMobile) ? 'matty-weiss-component-mobile' : ''}`}>
        <div className='section-start-01'>
          <div id='start' className='anchor-section-start'>
            <div className='handwriting-img'>
              {deviceType.isMobile ? MattyWeissImages.handWirtingStatmentMobile : MattyWeissImages.handWirtingStatment}
            </div>
            <div className='line'>
              <div> </div>
              <div className='line-style'> </div>
            </div>
            <div className='vertical-lines-container'>
              <div>
                <div className='vertical-line vertical-line-1' ></div>
                <div className='vertical-line vertical-line-2' ></div>
              </div>
              <div className='vertical-line vertical-line-3' ></div>
            </div>
          </div>
          <div className='person-section-1'>
            <img className='big-img' src={MattyWeissImages.bigImage} alt={personsRouts[6].name.slice(0,-4)} />
            <div className='text-group'>
              {deviceType.isMobile && <div className='top-right-corner'></div>}
              <div className='description-text-container'>
                <div>
                  <h1> {personsRouts[6].name}</h1>
                  <h3>{MattyWeissTexst.dates}</h3>
                </div>
                <div className='description-text'>{MattyWeissTexst.main}</div>  
                {deviceType.isMobile && <div className='bottom-left-corner'></div>}
              </div>
            </div>
          </div>
        </div>
        <div className='two-anchors-sections flex-section'>
          <div id="02" className='anchor-section-02 relative-conatainer flex-section'>
            {deviceType.isMobile && <h1  >סיפור אישי</h1>}
            <ElementsCarousle elementSlides={deviceType.isMobile ? mobileElementSlides : elementSlides}></ElementsCarousle>
          </div>
        </div>
        <div id="03" className='anchor-section-03 flex-section'>
          <div>
            <h2 className='section-header'>{MattyWeissTexst.songHeader}</h2>
            <div className='author-name-conatainer'>
              <h4>{MattyWeissTexst.songAuthor}</h4>
              <div className='decoration-line'></div>
              <div className='decoration-line decoration-line-2' style={{ marginRight: '16px' }}></div>
            </div>
          </div>
          <div className='song-container'>
            <iframe style={{
              borderRadius: '21px', border: 'none'
            }}
              title={MattyWeissTexst.song}
              src={`https://www.youtube.com/embed/${MattyWeissTexst.songLink}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}>
            </iframe>
          </div>  
        </div>
        <div id="04" className='anchor-section-04 flex-section'>
          <div className='image-background-container'>
            <h2 className='section-header'>שיתופים על {personsRouts[6].name}</h2>
            <div className='decoration-line decoration-line-1'></div>
            <div className='decoration-line decoration-line-2' style={{ width: '909px', marginRight: '287px' }}></div>
            <Padlet padletLink={MattyWeissTexst.padletLink} />
          </div>
        </div>
        <div id="05" className='anchor-section-05 flex-section'>
          <h2 className='section-header'>אלבום תמונות</h2>
          {deviceType.isMobile && <div className='vertical-lines-container'>
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="7" viewBox="0 0 54 7" fill="none">
              <rect width="17.2037" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 17.2041 0.807129)" fill="black" />
              <rect width="12.0426" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 33.5469 0.807129)" fill="black" />
              <rect width="42.5792" height="1.29028" transform="matrix(1 0 0 -1 10.752 6.39795)" fill="black" />
            </svg>
          </div>}
          <div style={{ position: 'relative', maxWidth: !deviceType.isMobile ? '1168px' : '80vw' }}>
          { selected === '05' && <Swipper images={deviceType.isMobile ? MattyWeissImages.mobileSwipper : MattyWeissImages.carousle} 
              height={'515px'} margin={'62px'} spaceBetween={20} slidesPerView={deviceType.isXLScreen ? 3.53 : 2.73}/>
          }</div>
        </div>
      </div>
      <Outlet></Outlet>
    </>
  );
};
export default Person7;
