import './person-4.scss'
import React from 'react';
import ElementsCarousle from '../common/elements-slides/elements-slides';
import TabHeaderAndExpandableText from '../common/elements-slides/tab-header-and-expandable-text/tab-header-and-expandable-text';
import useDeviceDetection from '../../useDeviceDetection';
import { LivnatKozTexst } from './person-4-texts';
import Swipper from '../common/swipper/swipper';
import { Outlet, useOutletContext } from 'react-router-dom';
import { personsRouts } from '../../models';
import SpeiaEdgeImage from '../common/speia-edge-image/speia-edge-image';
import Padlet from '../common/padlet/padlet';
import { LivnatKozImages } from './person-4-images'
import TextOverlayImage from '../common/elements-slides/text-overlay-image/text-overlay-image';
import EmptyRow from '../common/empty-row/empty-row';



const Person4 = () => {

  //#region elements slides
  const elementSlides = {
    earth: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textWidth='466px'
            text={LivnatKozTexst.slides.earth.mainText} textHeight='140px'
            mainShortText={LivnatKozTexst.slides.earth.mainShortText}
            customMoadlTextStyle={{ width: '742px' }} />
          <TextOverlayImage imagePath={LivnatKozImages.slides.earth[0].imagePath}
            width={'466px'} height={'276px'}
            text={LivnatKozTexst.slides.earth.imagesTexts[0]} textMaxWidth={"282px"}
          />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={LivnatKozImages.slides.earth[1].imagePath} width={'406px'} height={'288px'}
            text={LivnatKozTexst.slides.earth.imagesTexts[2]} textMaxWidth={"263px"}
          />
          <TextOverlayImage imagePath={LivnatKozImages.slides.earth[2].imagePath} width={'406px'} height={'270.833px'}
            text={LivnatKozTexst.slides.earth.imagesTexts[1]} textMaxWidth={"389px"}
          />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={LivnatKozImages.slides.earth[3].imagePath}
            text={LivnatKozTexst.slides.earth.imagesTexts[3]}
            textMaxWidth={"253px"} width={'305px'} height={'542px'} isCorner={true} />
        </div>
      </div>
      )
    },
    water: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"} textWidth={'466px'}
            text={LivnatKozTexst.slides.water.mainText} textHeight='175px'
            mainShortText={LivnatKozTexst.slides.water.mainShortText}
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }}
          />
          <TextOverlayImage imagePath={LivnatKozImages.slides.water[0].imagePath} width={'466px'} height={'248.529px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={LivnatKozImages.slides.water[1].imagePath}
            text={LivnatKozTexst.slides.water.imagesTexts[0]}
            textMaxWidth={"289px"} width={'388px'} height={'542px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={LivnatKozImages.slides.water[2].imagePath}
            text={LivnatKozTexst.slides.water.imagesTexts[1]}
            textMaxWidth={"244px"} width={'318px'} height={'542px'} isCorner={true} />
        </div>
      </div>
      )
    },
    fire: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"}
            text={LivnatKozTexst.slides.fire.mainText}
            mainShortText={LivnatKozTexst.slides.fire.mainShortText} textHeight='73px'
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }} />
          <TextOverlayImage imagePath={LivnatKozImages.slides.fire[0].imagePath} width={'466px'} height={'288px'}
            text={LivnatKozTexst.slides.fire.imagesTexts[0]} textMaxWidth={'366px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={LivnatKozImages.slides.fire[1].imagePath} text={LivnatKozTexst.slides.fire.imagesTexts[1]}
            textMaxWidth={"289px"} width={'348px'} height={'542px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={LivnatKozImages.slides.fire[2].imagePath} text={LivnatKozTexst.slides.fire.imagesTexts[2]}
            textMaxWidth={"286px"} width={'348px'} height={'542px'} isCorner={true} />
        </div>
      </div>
      )
    },
    air: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <div className='tab-row'>
            <TabHeaderAndExpandableText header={"רוח"} textWidth='406px'
              text={LivnatKozTexst.slides.air.mainText} textHeight='96px'
              mainShortText={LivnatKozTexst.slides.air.mainShortText}
              customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }} />
            <TextOverlayImage imagePath={LivnatKozImages.slides.air[1].imagePath} width={'406px'} height={'249px'}
              text={LivnatKozTexst.slides.air.imagesTexts[3]} textMaxWidth={"313px"}
            />
            <TextOverlayImage imagePath={LivnatKozImages.slides.air[3].imagePath} width={'366px'} height={'249px'}
              text={LivnatKozTexst.slides.air.imagesTexts[1]} textMaxWidth={"291px"}
            />
          </div>
          <div className='tab-row'>
            <TextOverlayImage imagePath={LivnatKozImages.slides.air[0].imagePath}
              width={'406px'} height={'249px'}
              text={LivnatKozTexst.slides.air.imagesTexts[4]} textMaxWidth={"289px"}
            />
            <TextOverlayImage imagePath={LivnatKozImages.slides.air[2].imagePath} width={'406px'} height={'249px'}
              text={LivnatKozTexst.slides.air.imagesTexts[2]} textMaxWidth={"289px"}
            />
            <TextOverlayImage imagePath={LivnatKozImages.slides.air[4].imagePath} width={'366px'} height={'249px'}
              text={LivnatKozTexst.slides.air.imagesTexts[0]} textMaxWidth={"291px"} isCorner={true}
            />
          </div>
        </div>
      </div>
      )
    },

  };

  const mobileElementSlides = {
    earth: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textMobileWidth='335px'
            text={<>
              {LivnatKozTexst.slides.earth.mainShortText}
              <EmptyRow height={20} />
              {LivnatKozTexst.slides.earth.avivMobile}
              <EmptyRow height={10} />
              {LivnatKozTexst.slides.earth.imagesTexts[2]}
              <EmptyRow height={10} />
              {LivnatKozTexst.slides.earth.imagesTexts[1]}
              <EmptyRow height={10} />
              {LivnatKozTexst.slides.earth.imagesTexts[0]}
              <EmptyRow height={10} />
              {LivnatKozTexst.slides.earth.mainTextMobile}
            </>
            }
            textHeight='191px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textFullHeight={'1468px'} />
          <SpeiaEdgeImage image={LivnatKozImages.slides.earth[4]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textMobileWidth='314px'
            text={<>
              {LivnatKozTexst.slides.water.mainShortText}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.water.mainText}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.water.imagesTexts[0]}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.water.nMobile}
            </>
            } textHeight='256px' textFullHeight={'1750px'}
          />
          <SpeiaEdgeImage image={LivnatKozImages.slides.water[2]} isCorner={true} imageCrop={"top"} />
        </div>
      </div>
      )
    },
    fire: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container tab-rows-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"} textMobileWidth='316px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {LivnatKozTexst.slides.fire.mainShortText}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.fire.mainText}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.fire.imagesTexts[0]}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.fire.imagesTexts[1]}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.fire.imagesTexts[2]}
            </>
            } textHeight='100px' textFullHeight={'2354px'} />
          <SpeiaEdgeImage image={LivnatKozImages.slides.fire[0]} isCorner={true} />
        </div>
      </div>
      )
    },
    air: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"רוח"} textMobileWidth='324px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {LivnatKozTexst.slides.air.mainShortText}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.air.mainText}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.air.imagesTexts[4]}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.air.imagesTexts[3]}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.air.imagesTexts[1]}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.air.imagesTexts[2]}
              <EmptyRow height={5} />
              {LivnatKozTexst.slides.air.imagesTexts[0]}
            </>
            } textHeight='125px' textFullHeight={'1467px'} />

          <SpeiaEdgeImage image={LivnatKozImages.slides.air[1]} isCorner={true} />
        </div>
      </div>
      )
    }

  };
  //#endregion

  const deviceType = useDeviceDetection();
  const [selected, setSelected] = useOutletContext();

  // const [isMobileSongWordsWxpanded, setIsMobileSongWordsExpanded] = useState(false);

  return (
    <>
      <div className={`livnat-kozt-component ${(deviceType.isMobile) ? 'livnat-kozt-component-mobile' : ''}`}>
        <div className='section-start-01'>
          <div id='start' className='anchor-section-start'>
            <div className='handwriting-img'>
              {deviceType.isMobile ? LivnatKozImages.handWirtingStatmentMobile : LivnatKozImages.handWirtingStatment}
            </div>
            <div className='line'>
              <div> </div>
              <div className='line-style'> </div>
            </div>
            <div className='vertical-lines-container'>
              <div>
                <div className='vertical-line vertical-line-1' ></div>
                <div className='vertical-line vertical-line-2' ></div>
              </div>
              <div className='vertical-line vertical-line-3' ></div>
            </div>
          </div>
          <div className='person-section-1'>
            <img className='big-img' src={LivnatKozImages.bigImage} alt={personsRouts[4].name.slice(0, -4)} />
            <div className='text-group'>
              {deviceType.isMobile && <div className='top-right-corner'></div>}
              <div className='description-text-container'>
                <div>
                  <h1> {personsRouts[3].name}</h1>
                  <h3>{LivnatKozTexst.dates}</h3>
                </div>
                <p>{LivnatKozTexst.main}</p>
                {deviceType.isMobile && <div className='bottom-left-corner'></div>}
              </div>
            </div>
          </div>
        </div>
        <div className='two-anchors-sections flex-section'>
          <div id="02" className='anchor-section-02 relative-conatainer flex-section'>
            {deviceType.isMobile && <h1>סיפור אישי</h1>}
            <ElementsCarousle elementSlides={deviceType.isMobile ? mobileElementSlides : elementSlides}></ElementsCarousle>
          </div>
        </div>
        <div id="03" className='anchor-section-03 flex-section'>
          <div>
            <h2 className='section-header'>{LivnatKozTexst.songHeader}</h2>
            <div className='author-name-conatainer'>
              <h4>{LivnatKozTexst.songAuthor}</h4>
              <div className='decoration-line'></div>
              <div className='decoration-line decoration-line-2' style={{ marginRight: '16px' }}></div>
            </div>
          </div>
          <div className='song-container'>
            <iframe style={{
              borderRadius: '21px', border: 'none'
            }}
              title={LivnatKozTexst.song}
              src={`https://www.youtube.com/embed/${LivnatKozTexst.songLink}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}>
            </iframe>
          </div>
        </div>
        <div id="04" className='anchor-section-04 flex-section'>
          <div className='image-background-container'>
            <h2 className='section-header'>שיתופים על {personsRouts[3].name}</h2>
            <div className='decoration-line decoration-line-1'></div>
            <div className='decoration-line decoration-line-2' style={{ width: '909px', marginRight: '287px' }}></div>
            <Padlet padletLink={LivnatKozTexst.padletLink} />
          </div>
        </div>
        <div id="05" className='anchor-section-05 flex-section'>
          <h2 className='section-header'>אלבום תמונות</h2>
          {deviceType.isMobile && <div className='vertical-lines-container'>
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="7" viewBox="0 0 54 7" fill="none">
              <rect width="17.2037" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 17.2041 0.807129)" fill="black" />
              <rect width="12.0426" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 33.5469 0.807129)" fill="black" />
              <rect width="42.5792" height="1.29028" transform="matrix(1 0 0 -1 10.752 6.39795)" fill="black" />
            </svg>
          </div>}
          <div style={{ position: 'relative', maxWidth: !deviceType.isMobile ? '1168px' : '80vw' }}>
            {selected === '05' && <Swipper images={deviceType.isMobile ? LivnatKozImages.mobileSwipper : LivnatKozImages.carousle}
              height={'515px'} margin={'62px'} spaceBetween={20} slidesPerView={deviceType.isXLScreen ? 3.53 : 2.73} />
            }</div>
        </div>
      </div>
      <Outlet></Outlet>
    </>
  );
};
export default Person4;
