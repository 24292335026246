export const MatanRozenbergTexst = {
    dates: '7.10.2023-22.02.2006',
    main: <>{`מתן, מדריך מוערך בתנועת הצופים בעיר, מילא במקצועיות רבה שני תפקידים: רשג"ד ורכז שכבה בשבט שגיא.
    מתן שימש סמל ומודל לחיקוי בקרב בני נוער רבים, והיה אהוב על כל מי שפגש בו.
    \nבשבעה באוקטובר רקד מתן את החיים במסיבת הפסידאק יחד עם שניים מבני המשפחה:  `}
        <a href="https://rememberoct7.lnet.studio/#/Noy-Moudy" target="blank">נוי מעודי הי"ד</a>
        {` ו`}<a href="https://twitter.com/bokeralmog/status/1727024554332066172" target="blank">מוטי אלקבץ הי"ד</a>
{`.
    \nרק בן 17 היה במותו, והשאיר אחריו הרבה כל־כך.\n`}</>,
    slides: {
        earth: {
            mainText: `מתן הוא בנם הבכור של שני ואיציק, הנכד הראשון מצד אימו, ואחיהם הגדול של ערן וליאור.\nהשם מתן ניתן לו כי היה מתנה עבור הוריו, אבל כולם קראו לו "ג'ינג'י".
        מתן נולד בבית החולים קפלן ברחובות, וגר בגן יבנה בשנים הראשונות לחייו.\nבהמשך עברה משפחתו לדימונה, שם גדל והתחנך.
        עוד לפני שהגיח מתן לאוויר העולם, בזמן שהיה ברחם, אימו שני נהגה להניח על בטנה אוזניות כדי שמתן יוכל לשמוע את המוזיקה הבוקעת מהן.
        ואולי בשל כך הייתה לו שמיעה מוזיקלית מצוינת והוא היטיב לנגן בפסנתר.`
        },
        water: {
            mainText: `מתן היה דמות להערצה בתחום החברתי, והטביע את חותמו בכל מקום שהיה בו.\nלמתן היו עשרות מעגלים חברתיים ברחבי הארץ. \nהוא ניחן ביכולות השפעה רבות על בני נוער, והיה מוכר כמי שמייעץ ומגיש עזרה לכל מי שרק יבקש ממנו.\n\nמתן היה חדור מטרה: מסמן לעצמו מטרות ומשיג אותן בעצמו בזכות קסמו האישי, חיוכו, טוב ליבו, ובעיקר הודות למוטיבציה העוצמתית שלו.
            `,
            mainTextMobile: `מתן היה דמות להערצה בתחום החברתי, והטביע את חותמו בכל מקום שהיה בו.\nלמתן היו עשרות מעגלים חברתיים ברחבי הארץ. \nהוא ניחן ביכולות השפעה רבות על בני נוער, והיה מוכר כמי שמייעץ ומגיש עזרה לכל מי שרק יבקש ממנו.\n\nמתן היה חדור מטרה: מסמן לעצמו מטרות ומשיג אותן בעצמו בזכות קסמו האישי, חיוכו, טוב ליבו, ובעיקר הודות למוטיבציה
             העוצמתית שלו.
            `
        },
        fire: {
            mainText: `המוזיקה הייתה אהבתו הגדולה של מתן.
            היה לו כישרון רב. הוא ניגן בפסנתר, בגיטרה, שר במקהלה, ולא איחר היום ושמו הגיע גם לאופרה הישראלית: הוא נבחר להופיע בהצגה לילדים 'חליל הקסם', שיחק ושר בה.
            מתן היה אוטודידקט, ולמד לנגן בעצמו.\nכשרצה לנגן מנגינה מסוימת, היה מאזין לה, ולאחר כמה ניסיונות הצליח לנגן אותה בפסנתר באופן מושלם.\n\nעוד בתקופת הגן הייתה למתן השפעה חזקה על הסובבים אותו ועל חבריו.
            בכיתה ד' הצטרף מתן לנבחרת הרובוטיקה בבית הספר, פעל רבות למען גיבוש הקבוצה, וזכה להוביל אותה למקומות גבוהים בתחרויות.
            בשנה זו הצטרף מתן לראשונה לתנועת הצופים, ומאז היא הפכה לחלק משמעותי וגדול בחייו.
            מהרגע שהתמנה לרשג"ד בצופים, הוא מילא את התפקיד ברצינות ובמסירות רבה. מתן השקיע רבות והקדיש את כל כולו לטובת המדריכים והחניכים: תכנן פעולות, ארגן טיולים, בישל, ניסח מטרות ומסרים שיהדהדו לחניכים, ועוד.
            `
        },


        air: {
            mainText: `מתן האמין בכל ליבו שצריך לעבוד קשה כדי להצליח, והיה לו חשוב להעביר את המסר הזה לכולם.
            מתן אהב אנשים ואהב לראות אנשים מאושרים.\n קבלת האחר וקבלת השונוּת היו חשובות לו. צריך לתת מקום לכולם.\n לאהוב זה את זה ללא תנאים.\n
            איציק, אביו של מתן, כתב סיפור קצר שממחיש מי היה מתן ומה היה הקסם שלו. הסיפור יצא כספר:\n"הפרפר הכתום"
            `
        },
    },
    songHeader: 'Say Yes To Heaven',
    songAuthor: 'Lana Del Ray',
    songLink: 'A0TlnvbPvSc?si=xA0BVuBSgzQJptpe',
    song: [],
    padletLink: 'qtx8fn2z7z7m1eq0'
}