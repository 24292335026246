
import * as React from 'react';
import CommonSquireDecoration, { fillSquireEnum } from "../squire-decoration/squire-decoration.jsx";
import useDeviceDetection from '../../../useDeviceDetection.jsx';


const SpeiaEdgeImage = ({ image, isCorner = false, imageCrop = 'center', imageCropX = 'center' }) => {

    const deviceType = useDeviceDetection();

    const backgroundImageStyle = {
        width: deviceType.isMobile ? '354px' : `${image.width}px`,
        height: deviceType.isMobile ? '223px' : `${image.height}px`,
        backgroundImage: `url(${image.imagePath})`,
        borderRadius: '6px',
        boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.30) inset, 0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        position: 'relative',
        backgroundPositionY: imageCrop,
        backgroundPositionX: imageCropX,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'lightgray'
    }

    const imageForOpenStyle = {
        width: deviceType.isMobile ? '354px' : `${image.width}px`,
        height: deviceType.isMobile ? '223px' : `${image.height}px`,
        opacity: 0
    }

    return (
        <div style={backgroundImageStyle}>
            <img src={image.imagePath} alt={image.description} style={imageForOpenStyle} />
            {isCorner && <CommonSquireDecoration fillSquire={fillSquireEnum.bottomLeft}
                defaultSet={{
                    bottom: -15, left: -13,
                    width: 87.333, height: 67.587,
                    borderRadius: 7
                }}
                mobileSet={{
                    bottom: -10, left: -10,
                    width: 40, height: 40,
                    borderRadius: 9
                }}
            />}
        </div>
    )
};
export default SpeiaEdgeImage;




