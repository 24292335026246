import useDeviceDetection from '../../../useDeviceDetection';
import './padlet.scss';
import React from "react";

const Padlet = ({padletLink = 'qtx8fn2z7z7m1eq0'}) => {
    const deviceType = useDeviceDetection();

    return (
        <div className={`padlet-embed ${(deviceType.isMobile) ? 'padlet-embed-mobile' : ''}`}>
        <p >
          <iframe src={`https://padlet.com/embed/${padletLink}`} allow="camera;microphone;geolocation"
          >
          </iframe>
        </p>
        <div className='link-to-padlet-section'>
          <a href="https://padlet.com?ref=embed" target="_blank">
            <div>
              <img src="https://padlet.net/embeds/made_with_padlet_2022.png" width="114" height="28" alt="מיוצר ב-Padlet" />
            </div>
          </a>
        </div>
      </div>
    )
}
export default Padlet;