import React from 'react';
import './text-overlay-image.scss';
import CommonSquireDecoration, { fillSquireEnum } from '../../squire-decoration/squire-decoration';

const TextOverlayImage = ({ imagePath, text, textMaxWidth, width, height, sideCaption = null, sideCaptionTop = 'unset', onClick, isCorner = false, margin = '0' }) => {

    const style = {
        height: height,
        width: width,
        cursor: onClick ? 'pointer' : 'default',
        background: `url(${imagePath}) lightgray 50% / cover no-repeat`,
        margin: margin
    }

    const textStyle = {
        maxWidth: textMaxWidth
    }

    const sideCaptionStyle = {
        top: sideCaptionTop
    }


    return (
        <div style={style} onClick={onClick}
            className={`text-overlay-image-container ${onClick ? 'text-overlay-image-container-with-hover' : ''}`}
        >
            {sideCaption?.trim()?.length && <h5 style={sideCaptionStyle}>{sideCaption}</h5>}
            {(!!text) &&
                <div className="text-overlay">
                    <div className='image-overlay-text-contnet' style={textStyle}>{text}</div>
                </div>
            }
              {isCorner && <CommonSquireDecoration fillSquire={fillSquireEnum.bottomLeft}
            defaultSet={{
                bottom: -15, left:-13,
                width: 87.333, height: 67.587,
                borderRadius: 7
              }}
              mobileSet={{
                bottom: -10, left:-10,
                width: 40, height: 40,
                borderRadius: 9
              }}            
          /> }
        </div>
    )
}
export default TextOverlayImage;



