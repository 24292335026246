import './nav.scss'
import * as React from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import { personsRouts } from '../../../models.js'
import useDeviceDetection from "../../../useDeviceDetection";
import MobileNav from '../mobile-navbar/mobile-nav.jsx';
import CommonButton from '../../common/button/button.jsx';
import zoom from '../../../assets/images/icons/zoom.svg'
import TalkingRoomsModalBtn from '../../common/talking-rooms-modal-btn/talking-rooms-modal-btn.jsx';


const Nav = () => {

  const deviceType = useDeviceDetection();
  const navigate = useNavigate();
  const location = useLocation();
  const [isHomePage, setIsHomePage] = React.useState();
  const today = new Date().setTime(0);
  const conferenceDate = new Date().setTime(0);


  React.useEffect(() => {
    let isHomePage = !personsRouts.find(r => location.pathname.includes(r.path))// || !deviceType.isMobile && location.pathname.match('[/]+(.)*[/]+(.)*');
    setIsHomePage(isHomePage);
  }, [location, deviceType.isMobile]);

  return (
    <>
      <div className='nav-component'>
        {/* today === conferenceDate && !deviceType.isMobile &&
          <div className='talk-rooms-banner'>
            <p className="marquee">
              <span>חדרי השיח יפתחו בשעה 20:30 - מזמינים אתכם להצטרף אלינו &nbsp;</span>
            </p>
          </div> */}
        {!deviceType.isMobile ? <>
          <div className={'desktop-nav-component'}>
            <img src={logo} alt='סיפור הרוח' onClick={() => navigate('/Home')} style={{ cursor: 'pointer' }} />
            <div className="nav-bar-persons-main-container">
              <div className="tags">
                <div className='navbar-persons-names'>
                  {personsRouts.map((p, i) =>
                    <NavLink key={`l${i}`} to={p.path} style={{ width: p.width }}
                      className={({ isActive }) => (isActive ? 'active-nav-link' : '')}>
                      {p.name}
                    </NavLink >
                  )}
                </div>
              </div>
            </div>
            {isHomePage ?
              <TalkingRoomsModalBtn OpenButton={(onClick) => <CommonButton
                width='176.307px'
                minHeight='48.225px'
                fillColor="#966C6C"
                hoverFillColor={'#000'}
                contentColor="#fff"
                notOutLineHover={true}
                imageSrc={zoom}
                onClick={onClick}
                text="הקלטות ערב ההנצחה"
                fontSize={'15px'}
              />} />
              : <div className='placeholder' style={{ width: '176.307px' }} />}
          </div>
        </> : <MobileNav />}
      </div>
    </>
  );

}
export default Nav

