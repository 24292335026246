import React, { useEffect } from "react";
import './Home.scss';
import Person1 from '../../assets/images/Home/imag1.png';
import Person2 from '../../assets/images/Home/imag2.png';
import Person3 from '../../assets/images/Home/imag3.png';
import Person4 from '../../assets/images/Home/imag4.png';
import Person5 from '../../assets/images/Home/imag5.png';
import Person6 from '../../assets/images/Home/imag6.png';
import Person7 from '../../assets/images/Home/imag7.png';

import Person1Mobile from '../../assets/images/Home/image-mobile1.png';
import Person2Mobile from '../../assets/images/Home/image-mobile2.png';
import Person3Mobile from '../../assets/images/Home/image-mobile3.png';
import Person4Mobile from '../../assets/images/Home/image-mobile4.png';
import Person5Mobile from '../../assets/images/Home/image-mobile5.png';
import Person6Mobile from '../../assets/images/Home/image-mobile6.png';
import Person7Mobile from '../../assets/images/Home/image-mobile7.png';

import useDeviceDetection from '../../useDeviceDetection';
import { personsRouts as personsRoutes } from '../../models.js';
import { useNavigate } from "react-router";
import HoverOverlayImage from "../common/hover-overlay-image/hover-overlay-image.jsx";
import ConferenceScheduleModalBtn from "../common/conference-schedule-modal/conference-schedule-modal.jsx";
import SideMenue from "../share/side-menue/sideMenue.jsx";
import CustomModal from "../common/custom-modal/custom-modal.jsx";
import CommonButton from "../common/button/button.jsx";
import TalkingRoomsModalBtn from "../common/talking-rooms-modal-btn/talking-rooms-modal-btn.jsx";
import zoom from '../../assets/images/icons/zoom.svg'
import EmptyRow from "../common/empty-row/empty-row"






const Home = () => {
  const deviceType = useDeviceDetection();
  const navigate = useNavigate();
  const personsImage = [Person1, Person2, Person3, Person4, Person5, Person6, Person7];
  const personsMobileImage = [Person1Mobile, Person2Mobile, Person3Mobile, Person4Mobile, Person5Mobile, Person6Mobile, Person7Mobile];
  const alwaysInOurHearts = <div className="item always-in-our-hearts">תמיד בליבנו</div>
  const [isMobileMeetingModalOpen, setIsMobileMeetingModalOpen] = React.useState(false);
  const resetScroling = () => document.body.scrollTop = document.documentElement.scrollTop = 0;
  const handleMobileMeetingModalClose = () => {
    setIsMobileMeetingModalOpen(false);
  }
  const modalContent = <div className="mobile-meeting-rooms-messege-modal">
    <h2>האתר עבר לקישור אחר
      הערב יערך באתר החדש, בקישור: 
      <a href="https://rememberoct7.lnet.co.il/" target="_self"> https://rememberoct7.lnet.co.il</a>
    </h2>
  </div>;
  // useEffect(() => {
  //   setTimeout(() => setIsMobileMeetingModalOpen(true), 1000);
  // }, []);


  return (<>
    <div className={`home-component ${(deviceType.isMobile) ? 'home-component-mobile' : ''}`}>
      {/* {<CustomModal open={isMobileMeetingModalOpen} onClose={handleMobileMeetingModalClose}
        content={modalContent} isFullWidth={true} />} */}
      <div className='section-1'>
        <div className="home-page-text">
          <span >ערב הנצחה, לזכר האהובים לנו מכל</span>
          <div className='line'>
            <div> </div>
            <div className='line-style'> </div>
          </div>
        </div>

        <div className='text-group'>
          <div className='top-right-corner'></div>
          <div className='description-text-container'>
            <div className="header-text">
              <div className="line-side"></div>
              <h1>סיפור הרוח</h1>
            </div>
            <p>
              <b>'סיפור הרוח'</b> הוא ערב הנצחה המוקדש לזכרם של אנשי חינוך ומשפחותיהם שנרצחו בשבעה באוקטובר.
              <EmptyRow height={11} />
              במרחב זיכרון זה נספר את הסיפור של משה אוחיון, אליעד אוחיון, מתן רוזנברג, ליבנת קוץ, גילי אדר, נוי מעודי ומתי וייס.
              <EmptyRow height={11} />
              מרחב הזיכרון מוצג דרך יסודות הטבע המייצגים כוחות ותכונות ייחודיות שהעולם מורכב מהם:
              <ul style={{ listStyle: 'none', padding: 0, margin: '11px 0' }}>
                <li><b>אדמה</b> – שורשים משפחתיים;</li>
                <li><b>מים</b> – ייחודיות והשפעה;</li>
                <li><b>אש</b> – תשוקות ועשייה;</li>
                <li><b>רוח</b> – מסרים והשראה. </li>
              </ul>
              <b>נזכור אתכם וננצור את המורשת שהשארתם.</b>
            </p>
          </div>
          <div className='bottom-left-corner'></div>
          <div className="button-model-loz">
            {/* <ConferenceScheduleModalBtn btnSize={{ width: '185px', minHight: '63.222px' }} /> */}
          </div>
        </div>
      </div>
      <div className='section-2 flex-section'>
        <h2 className='section-header'>תמיד בליבנו</h2>
        <iframe src="https://www.youtube.com/embed/ih1jc7G_45o?si=7nQ6-o4f4ftdJfIT" title="כנס הנצחה" frameBorder="0" style={{ borderRadius: '18px' }} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </div>
      <div className='section-3 flex-section'>
        <div className="college-img-container">
          <div className='top-right-corner'></div>

          {deviceType.isMobile ? [[0, 1], [2, 3], [4, 5], [6]].map((indexesArray, rowIndex) =>
            <div key={`row${rowIndex}`} className={`row-img-${rowIndex + 1}`}>
              {indexesArray.map(j =>
                <div className="item" key={`item${j}`}>
                  <h6>{personsRoutes[j].name}</h6>
                  <HoverOverlayImage imagePath={personsMobileImage[j]}
                    onClick={() => { navigate(personsRoutes[j].path); resetScroling(); }}
                    width={'166px'} height={'150px'}
                  />
                </div>
              )}
              {rowIndex === 3 && alwaysInOurHearts}
            </div>)
            : [[0, 1, 2, 3], [4, 5, 6]].map((indexesArray, rowIndex) =>
              <div key={`row${rowIndex}`} className={`row-img-${rowIndex + 1}`}>
                {indexesArray.map(j =>
                  <div className="item" key={`item${j}`}>
                    <h6>{personsRoutes[j].name}</h6>
                    <HoverOverlayImage imagePath={personsImage[j]}
                      onClick={() => { navigate(personsRoutes[j].path); resetScroling(); }}
                      height={'223px'}
                    />
                  </div>
                )}
              </div>)
          }
          <div className='bottom-left-corner'></div>
        </div>
      </div>
    </div>
    {/* <SideMenue isDisplayHome={false} /> */}
  </>
  );
}

export default Home;
