import EmptyRow from "../common/empty-row/empty-row";

export const EliadOhayonTexst = {
    dates: '7.10.2023-21.12.1999',
    main: `אליעד אוחיון, תושב אופקים, התנדב מגיל צעיר בעמותות למען ילדים עם מוגבלויות ולמען חולי סרטן.
    
    את הנוכחות הפיזית שלו אי אפשר היה לפספס. הצחוק והשמחה שהביא איתו היו סימני ההיכר שלו.
    
    אליעד ראה תמיד את טובת האחר. כך היה גם בשבעה באוקטובר, שעה שיחד עם אביו משה ניסה להציל אחרים, שם את טובת האחר לפני כל דבר אחר.`,
    slides: {
        earth: {
            mainShortText: <>
                אליעד היה בנם השני של משה ז"ל ושרית אוחיון תבדל"א.<EmptyRow />
                הוא נולד בבית החולים הדסה הר הצופים, קצת אחרי שהמשפחה עברה לגור באופקים.</>,
            mainText: <>
                רגישותו החברתית הגבוהה של אליעד בלטה כבר בילדותו המוקדמת. כשהיה בן שלוש אושפז במחלקת ילדים. לידו שכב ילד בדואי נטוש, ואליעד שיחק איתו כל הזמן.<EmptyRow />
                משפחתו מספרת שכאשר היה בכיתה ג', עמדה הכיתה לצאת לטיול. כולם כבר היו על האוטובוס ואליעד גילה שאחד הילדים הורד מהאוטובוס כי לא שילם לטיול.<EmptyRow />
                באותו הרגע הודיע אליעד למנהל שאם הילד הזה לא יוצא, אף אחד מהילדים לא יוצא.<EmptyRow />
                בכיתה ד' הצטרף לתנועת בני עקיבא. במהרה בלטה יכולת המנהיגות שלו, והוא היה למדריך בשבט נבטים.<EmptyRow />
                מחנכיו מספרים עליו שהיה תלמיד אנרגטי. לשבת במקום בשקט – 'לא הקטע שלו'. את האנרגיות המתפרצות ניתב אליעד לטובת עשייה חברתית.<EmptyRow />
                היה לאליעד הסכם עם מנהל התיכון: הוא ילמד בכוחות עצמו ויורשה לגשת למבחנים, בתנאי שיתנדב ולא יישאר בפנימיות. וכך, בכיתה י' התחיל להתנדב בעולם העמותות.<EmptyRow />
                אליעד הקים את הסניף של עמותת 'חברים לרפואה' בדימונה, ובשנת תשפ"ג הדריך במכינת 'ביחד' – מכינה קדם צבאית לנוער בסיכון ולנוער על הרצף האוטיסטי. מאז ועד שנהרג התנדב במספר רב של עמותות עם ילדים ובוגרים עם צרכים מיוחדים ועם חולי סרטן.<EmptyRow />
                שורשי עשייתו ההתנדבותית נטועים בביתו, בהוריו, במשפחה, בחברים ובעיר שגדל בה – אופקים.</>,
                 mainTextMobile: <>
                 רגישותו החברתית הגבוהה של אליעד בלטה כבר בילדותו המוקדמת. כשהיה בן שלוש אושפז במחלקת ילדים. לידו שכב ילד בדואי נטוש, ואליעד שיחק איתו כל הזמן.<EmptyRow />
                 משפחתו מספרת שכאשר היה בכיתה ג', עמדה הכיתה לצאת לטיול. כולם כבר היו על האוטובוס ואליעד גילה שאחד הילדים הורד מהאוטובוס כי לא שילם לטיול.<EmptyRow />
                 באותו הרגע הודיע אליעד למנהל שאם הילד הזה לא יוצא, אף אחד מהילדים לא יוצא.<EmptyRow />
                 בכיתה ד' הצטרף לתנועת בני עקיבא. במהרה בלטה יכולת המנהיגות שלו, והוא היה למדריך בשבט נבטים.<EmptyRow />
                 מחנכיו מספרים עליו שהיה תלמיד אנרגטי. לשבת במקום בשקט – 'לא הקטע שלו'. את האנרגיות המתפרצות ניתב אליעד לטובת עשייה חברתית.<EmptyRow />
                 היה לאליעד הסכם עם מנהל התיכון: הוא ילמד בכוחות עצמו ויורשה לגשת למבחנים, בתנאי שיתנדב ולא יישאר בפנימיות. וכך, בכיתה י' התחיל להתנדב בעולם העמותות.<EmptyRow />
                 אליעד הקים את הסניף של עמותת 'חברים לרפואה' בדימונה, ובשנת תשפ"ג הדריך במכינת 'ביחד' – מכינה קדם צבאית לנוער בסיכון ולנוער על הרצף האוטיסטי. מאז ועד שנהרג התנדב במספר רב של עמותות עם ילדים ובוגרים עם צרכים מיוחדים<EmptyRow height={1}/> ועם חולי סרטן.<EmptyRow />
                 שורשי עשייתו ההתנדבותית נטועים בביתו, בהוריו, במשפחה, בחברים ובעיר שגדל <EmptyRow height={1} />בה – אופקים.</>
        },
        water: {
            mainShortText: `אליעד לא חיכה שיקראו לו. הוא פשוט היה שם. אם זה להעמיס ציוד למעבר בתים ואם להחליף מדריך במסע זהות ישראלית. כזה היה אליעד – נוכֵח.`,
            mainText: `הייתה לו מומחיות לגרום לאחרים לעשות טוב עבור אחרים. האנרגיות הגבוהות שלו סחפו את כולם. חניכים שלו אומרים שבהתחלה הם לא הבינו מי האדם שהוא. אבל מהר מאוד ידעו שאליעד הוא יחיד ומיוחד. וכשהיו נתונים בקושי, הוא לא נתן להם ליפול ודאג תמיד להיות האור שלהם.
            
            אליעד רצה לקדם שוויון זכויות לאנשים חולים או עם צרכים מיוחדים. הבחין תמיד בחניכים השקטים ובאלה שקשה להם. הוא לא היה מוכן שיסבלו, ולכן לא פעם "השתתף איתם בסבל". חניך שלא יכול לאכול – גם אליעד לא יאכל, חניך לפני טיפול שצריך לעשות קרחת – גם אליעד יעשה. התרוממות הרוח של האחר הייתה חשובה לו מזו של עצמו.`
        },
        fire: {
            mainShortText: `אם יש משהו שאפיין את אליעד זה אש! אנרגיות מטורפות, והלב – המרכז!
            אליעד רצה להפוך את העולם לטוב יותר!
            הוא ידע במה הוא טוב, ועשה כל שביכולתו כדי להוסיף טוב ולשפר ככל יכולתו.`,
            mainText: `כדי לעשות טוב הוא לא פחד לעשות את הבלתי אפשרי. "כל האמצעים כשרים", העיקר שהחניכים ייהנו מהכול.

            אחד האירועים שהיו משמעותיים עבורו ועיצבו אותו היה פטירתו של אופיר חי ז"ל, חניך שלו שנפטר מסרטן.
             מותו של אופיר היה שבר גדול עבור אליעד. לאחר מותו הוא בנה לזכרו ספסלים בכל רחבי הארץ כדי שכל מי שישב לנוח עליהם יֵדע על אופיר ולא ישכח אותו.

            כתלמיד שלא מצא את מקומו במערכת החינוך, קיווה אליעד לשנות אותה כדי שתתאים לקשת רחבה של אנשים – להעצים את הטוב במערכת החינוך ולהתגבר על הקשיים. למרות קשייו עמד אליעד בבחינות הבגרות, בזכותו ולא מעט בזכות השפעת המורים שלו. גם לאחר סיום לימודיו הקשר איתם נמשך.`
        },
        air: {
            mainText: <>האמונה של אליעד באחדות הובילה אותו להתבוננות שונה על קיום שיח ועל דיאלוג עם האחר.<EmptyRow />
                כדי להגיע לעמק השווה עם האחר, צריך קודם כול להקשיב. לא רק לראות את הצורך שלי או את צורכי המערכת – אלא לראות את האדם באשר הוא, להקשיב לקשיים שלו, לחזק מקומות חלשים שלו. זו הייתה דרכו של אליעד לגעת באנשים. והוא נגע ברבים.<EmptyRow />
                בתקופה שלפני השבעה באוקטובר ראה אליעד את העם המפולג, והרגיש שעל כולנו להיות מאוחדים. מספרים עליו שהוא צריך להוות השראה לכולנו: כולנו צריכים להשתדל למען האחר, לשמור על אחדותנו ולא לתת לחששות להרחיק אותנו.<EmptyRow />
                חבריו אומרים שרובנו לא פועלים לעשות טוב לאחרים, ולכן אליעד היה חריג בנוף. כעת, כשאליעד לא כאן, מציף אותם הגעגוע לחריגות הזו, לטוב שאליעד היה עושה. חבריו מקווים שהם יוכלו לקיים את צוואתו.<EmptyRow />
                <video src="https://static.lnet.org.il/dev/remember7oct/eliad-ohayon.mp4" style={{ border: 'none', width: "560px", height: "315px", margin: '65px 59px 5px 0' }}
                    title='אליעד אוחיון הי"ד' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen controls={true}>
                </video>
            </>,
            mainTextMobile: <>האמונה של אליעד באחדות הובילה אותו להתבוננות שונה על קיום שיח ועל דיאלוג עם האחר.<EmptyRow />
                כדי להגיע לעמק השווה עם האחר, צריך קודם כול להקשיב. לא רק לראות את הצורך שלי או את צורכי המערכת – אלא לראות את האדם באשר הוא, להקשיב לקשיים שלו, לחזק מקומות חלשים שלו. זו הייתה דרכו של אליעד לגעת באנשים. והוא נגע ברבים.<EmptyRow />
                בתקופה שלפני השבעה באוקטובר ראה אליעד את העם המפולג, והרגיש שעל כולנו להיות מאוחדים. מספרים עליו שהוא צריך להוות השראה לכולנו: כולנו צריכים להשתדל למען האחר, לשמור על אחדותנו ולא לתת לחששות להרחיק אותנו.<EmptyRow />
                חבריו אומרים שרובנו לא פועלים לעשות טוב לאחרים, ולכן אליעד היה חריג בנוף. כעת, כשאליעד לא כאן, מציף אותם הגעגוע לחריגות הזו, לטוב שאליעד היה עושה. חבריו מקווים שהם יוכלו לקיים את צוואתו.<EmptyRow />
                <video src="https://static.lnet.org.il/dev/remember7oct/eliad-ohayon.mp4"
                    style={{  width: '100%', borderRadius: '18px', marginTop: '16px' }}
                    title='אליעד אוחיון הי"ד' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen controls={true}>
                </video>
            </>,
            mainShortText: <>אליעד ראה את האחר לפני עצמו.<EmptyRow />
                האני מאמין שליווה אותו – הטוב מוביל לטוב.<EmptyRow />
                המעשים הטובים שאתה עושה לאו דווקא יחזרו אליך, אבל תחושת הסיפוק בגרימת שמחה למישהו אחר, היא המעשה הטוב שאתה עושה לעצמך.</>
        },
    },
    songHeader: 'השמחה',
    songAuthor: 'רביד פלוטניק',
    songLink: 'CxkTwSapo3k?si=USIIy3D2_pKn607n',
    song: [],
    padletLink: '4kastfvmxu4hp8nf'
}