import './person-6.scss'
import React from 'react';
import ElementsCarousle from '../common/elements-slides/elements-slides';
import TabHeaderAndExpandableText from '../common/elements-slides/tab-header-and-expandable-text/tab-header-and-expandable-text';
import useDeviceDetection from '../../useDeviceDetection';
import { NoyMaudyTexst } from './person-6-texts';
import Swipper from '../common/swipper/swipper';
import { Outlet, useOutletContext } from 'react-router-dom';
import { personsRouts } from '../../models';
import SpeiaEdgeImage from '../common/speia-edge-image/speia-edge-image';
import Padlet from '../common/padlet/padlet';
import { NoyMaudyImages } from './person-6-images'
import TextOverlayImage from '../common/elements-slides/text-overlay-image/text-overlay-image';
import EmptyRow from '../common/empty-row/empty-row';



const Person6 = () => {

  //#region elements slides
  const elementSlides = {
    earth: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <div className='tab-row'>
            <TabHeaderAndExpandableText header={"אדמה"} textWidth='349px'
              text={NoyMaudyTexst.slides.earth.mainText} textHeight='96px'
              mainShortText={NoyMaudyTexst.slides.earth.mainShortText}
              customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }} />
            <TextOverlayImage imagePath={NoyMaudyImages.slides.earth[1].imagePath}
             width={'406px'} height={'249px'} margin='0 57px 0 0'/>
            <TextOverlayImage imagePath={NoyMaudyImages.slides.earth[3].imagePath} width={'366px'} height={'249px'}
            />
          </div>
          <div className='tab-row'>
            <TextOverlayImage imagePath={NoyMaudyImages.slides.earth[0].imagePath}
              width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={NoyMaudyImages.slides.earth[2].imagePath} width={'406px'} height={'249px'}

            />
            <TextOverlayImage imagePath={NoyMaudyImages.slides.earth[4].imagePath} width={'366px'} height={'249px'}
              isCorner={true}
            />
          </div>
        </div>
      </div>
      )
    },
    water: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"} textWidth={'420px'}
            textHeight='99px' 
            mainShortText={NoyMaudyTexst.slides.water.mainShortText}
            text={NoyMaudyTexst.slides.water.mainText}
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '795px' }}
          />
          <TextOverlayImage imagePath={NoyMaudyImages.slides.water[0].imagePath}
            width={'466px'} height={'278.529px'}
            textMaxWidth={"289px"} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={NoyMaudyImages.slides.water[1].imagePath}
            width={'388px'} height={'542px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={NoyMaudyImages.slides.water[2].imagePath}
            width={'318px'} height={'542px'} isCorner={true} />
        </div>
      </div>
      )
    },
    fire: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"}
            text={NoyMaudyTexst.slides.fire.mainText}
            mainShortText={NoyMaudyTexst.slides.fire.mainShortText} textHeight='73px'
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }} />
          <TextOverlayImage imagePath={NoyMaudyImages.slides.fire[0].imagePath} width={'466px'} height={'288px'}
          />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={NoyMaudyImages.slides.fire[1].imagePath}
            width={'348px'} height={'542px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={NoyMaudyImages.slides.fire[2].imagePath}
            width={'348px'} height={'542px'} isCorner={true} />
        </div>
      </div>
      )
    },
    air: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"רוח"} textWidth={'367px'}
            text={NoyMaudyTexst.slides.air.mainText} textHeight='75px'
            mainShortText={NoyMaudyTexst.slides.air.mainShortText}
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }}
          />
          <TextOverlayImage imagePath={NoyMaudyImages.slides.air[0].imagePath} width={'426px'} height={'278.529px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={NoyMaudyImages.slides.air[1].imagePath}
            width={'428px'} height={'542px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={NoyMaudyImages.slides.air[2].imagePath}
            width={'318px'} height={'542px'} isCorner={true} />
        </div>
      </div>
      )
    },

  };

  const mobileElementSlides = {
    earth: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"}
            text={<>
              {NoyMaudyTexst.slides.earth.mainShortText}
              <EmptyRow height={10} />
              {NoyMaudyTexst.slides.earth.mainText}
            </>
            }
            textHeight='99px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textFullHeight={'291px'} />
          <SpeiaEdgeImage image={NoyMaudyImages.slides.earth[2]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"} textMobileWidth='327px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {NoyMaudyTexst.slides.water.mainShortText}
              <EmptyRow height={5} />
              {NoyMaudyTexst.slides.water.mainText}
            </>
            } textHeight='100px' textFullHeight={'210px'}
          />
          <SpeiaEdgeImage image={NoyMaudyImages.slides.water[1]} isCorner={true} imageCrop={"top"} />
        </div>
      </div>
      )
    },
    fire: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container tab-rows-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"} textMobileWidth='349px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {NoyMaudyTexst.slides.fire.mainShortText}
              <EmptyRow height={5} />
              {NoyMaudyTexst.slides.fire.mainTextMobile}
            </>
            } textHeight='76px' textFullHeight={'420px'} />
          <SpeiaEdgeImage image={NoyMaudyImages.slides.fire[0]} isCorner={true} />
        </div>
      </div>
      )
    },
    air: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"רוח"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {NoyMaudyTexst.slides.air.mainShortText}
              <EmptyRow height={5} />
              {NoyMaudyTexst.slides.air.mainText}
            </>
            } textHeight='72px' textFullHeight={'391px'} />

          <SpeiaEdgeImage image={NoyMaudyImages.slides.air[0]} isCorner={true} />
        </div>
      </div>
      )
    }

  };
  //#endregion

  const deviceType = useDeviceDetection();
  const [selected, setSelected] = useOutletContext();

  return (
    <>
      <div className={`noy-maudy-component ${(deviceType.isMobile) ? 'noy-maudy-component-mobile' : ''}`}>
        <div className='section-start-01'>
          <div id='start' className='anchor-section-start'>
            <div className='handwriting-img'>
              {deviceType.isMobile ? NoyMaudyImages.handWirtingStatmentMobile : NoyMaudyImages.handWirtingStatment}
            </div>
            <div className='line'>
              <div> </div>
              <div className='line-style'> </div>
            </div>
            <div className='vertical-lines-container'>
              <div>
                <div className='vertical-line vertical-line-1' ></div>
                <div className='vertical-line vertical-line-2' ></div>
              </div>
              <div className='vertical-line vertical-line-3' ></div>
            </div>
          </div>
          <div className='person-section-1'>
            <img className='big-img' src={NoyMaudyImages.bigImage} alt={personsRouts[5].name} />
            <div className='text-group'>
              {deviceType.isMobile && <div className='top-right-corner'></div>}
              <div className='description-text-container'>
                <div>
                  <h1> {personsRouts[5].name}</h1>
                  <h3>{NoyMaudyTexst.dates}</h3>
                </div>
                <p>{NoyMaudyTexst.main}</p>
                {deviceType.isMobile && <div className='bottom-left-corner'></div>}
              </div>
            </div>
          </div>
        </div>
        <div className='two-anchors-sections flex-section'>
          <div id="02" className='anchor-section-02 relative-conatainer flex-section'>
            {deviceType.isMobile && <h1>סיפור אישי</h1>}
            <ElementsCarousle elementSlides={deviceType.isMobile ? mobileElementSlides : elementSlides}></ElementsCarousle>
          </div>
        </div>
        <div id="03" className='anchor-section-03 flex-section'>
          <div>
            <h2 className='section-header'>{NoyMaudyTexst.songHeader}</h2>
            <div className='author-name-conatainer'>
              <h4>{NoyMaudyTexst.songAuthor}</h4>
              <div className='decoration-line'></div>
              <div className='decoration-line decoration-line-2' style={{ marginRight: '16px' }}></div>
            </div>
          </div>
          <div className='song-container'>
            <iframe style={{
              borderRadius: '21px', border: 'none'
            }}
              title={NoyMaudyTexst.song}
              src={`https://www.youtube.com/embed/${NoyMaudyTexst.songLink}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}>
            </iframe>
          </div>
        </div>
        <div id="04" className='anchor-section-04 flex-section'>
          <div className='image-background-container'>
            <h2 className='section-header'>שיתופים על {personsRouts[5].name}</h2>
            <div className='decoration-line decoration-line-1'></div>
            <div className='decoration-line decoration-line-2' style={{ width: '909px', marginRight: '287px' }}></div>
            <Padlet padletLink={NoyMaudyTexst.padletLink} />
          </div>
        </div>
        <div id="05" className='anchor-section-05 flex-section'>
          <h2 className='section-header'>אלבום תמונות</h2>
          {deviceType.isMobile && <div className='vertical-lines-container'>
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="7" viewBox="0 0 54 7" fill="none">
              <rect width="17.2037" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 17.2041 0.807129)" fill="black" />
              <rect width="12.0426" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 33.5469 0.807129)" fill="black" />
              <rect width="42.5792" height="1.29028" transform="matrix(1 0 0 -1 10.752 6.39795)" fill="black" />
            </svg>
          </div>}
          <div style={{ position: 'relative', maxWidth: !deviceType.isMobile ? '1168px' : '80vw' }}>
            {selected === '05' && <Swipper images={deviceType.isMobile ? NoyMaudyImages.mobileSwipper : NoyMaudyImages.carousle}
              height={'515px'} margin={'62px'} spaceBetween={20} slidesPerView={deviceType.isXLScreen ? 3.43 : 2.63} />
            }</div>
        </div>
      </div>
      <Outlet></Outlet>
    </>
  );
};
export default Person6;
