import React from 'react';
import './sideMenue.scss'
import { useNavigate } from "react-router-dom";
import useDeviceDetection from '../../../useDeviceDetection';
import { helpZoomLink, zoomBaseUrl } from '../../../models';
import TalkingRoomsModalBtn from '../../common/talking-rooms-modal-btn/talking-rooms-modal-btn';
import useIsNotFixed from '../../../useIsNotFixed';
import ZoomRecordModal from '../../common/elements-slides/zoom-record-modal/zoom-record-modal';


const SideMenue = ({ isDisplayHome = true, zoomLink }) => {

  const navigate = useNavigate();
  const deviceType = useDeviceDetection();
  const isNotFixed = useIsNotFixed(isDisplayHome);
  const openZoom = () => { window.open(`${zoomBaseUrl}${zoomLink}`, '_blank') };
  const connectToTechenicalHelp = () => {
    //  window.open(`${zoomBaseUrl}${helpZoomLink}`, '_blank') 

  };

  return (
    <div>
      {!deviceType.isMobile &&
        <div className='side-menue-container' style={isNotFixed ?
          { bottom: `${isDisplayHome ? 301 : 281}px`, top: 'unset', position: 'absolute' }
          : {}}
        >
          <button className='side-menue-button' onClick={() => { navigate('/Home') }}  >
            <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.009 9.59825C21.009 9.43897 20.9457 9.28621 20.8331 9.17358C20.7205 9.06095 20.5677 8.99768 20.4084 8.99768C20.2492 8.99768 20.0964 9.06095 19.9838 9.17358C19.8711 9.28621 19.8079 9.43897 19.8079 9.59825H21.009ZM20.4084 9.75753C20.4084 9.59825 13.0889 1.08862 11.5753 2.08862C12.0753 0.588623 3.75176 8.99768 3.59248 8.99768C3.43319 8.99768 3.28044 9.06095 3.16781 9.17358C3.05518 9.28621 2.99191 9.43897 2.99191 9.59825L20.4084 9.75753ZM22.3855 12.4257C22.4983 12.5385 22.6512 12.6019 22.8107 12.6019C22.9702 12.6019 23.1231 12.5385 23.2359 12.4257C23.3487 12.313 23.412 12.16 23.412 12.0005C23.412 11.841 23.3487 11.6881 23.2359 11.5753L22.3855 12.4257ZM12.0005 1.19027L12.4257 0.765063C12.3699 0.709134 12.3036 0.664761 12.2306 0.634484C12.1577 0.604208 12.0795 0.588623 12.0005 0.588623C11.9215 0.588623 11.8432 0.604208 11.7703 0.634484C11.6973 0.664761 11.631 0.709134 11.5753 0.765063L12.0005 1.19027ZM0.764992 11.5753C0.652221 11.6881 0.588867 11.841 0.588867 12.0005C0.588867 12.16 0.652221 12.313 0.764992 12.4257C0.877763 12.5385 1.03071 12.6019 1.1902 12.6019C1.34968 12.6019 1.50263 12.5385 1.6154 12.4257L0.764992 11.5753ZM5.99476 23.4114H18.0062V22.2102H5.99476V23.4114ZM21.009 20.4085V9.59825H19.8079V20.4085H21.009ZM19.9838 21.6825L20.4084 9.75753L2.99191 9.59825V20.4085L19.9838 21.6825ZM23.2359 11.5753L12.4257 0.765063L11.5753 1.61547L22.3855 12.4257L23.2359 11.5753ZM11.5753 0.765063L0.764992 11.5753L1.6154 12.4257L12.4257 1.61547L11.5753 0.765063ZM18.0062 23.4114C18.8026 23.4114 19.5663 23.095 20.1295 22.5318C20.6926 21.9687 21.009 21.2049 21.009 20.4085H19.8079C19.8079 20.8864 19.618 21.3446 19.2802 21.6825C18.9423 22.0204 18.484 22.2102 18.0062 22.2102V23.4114ZM5.99476 22.2102C5.51691 22.2102 7.92675 22.9265 7.58887 22.5886C10.957 23.4947 19.9838 22.1604 19.9838 21.6825L2.99191 20.4085C2.99191 21.2049 3.30828 21.9687 3.87142 22.5318C4.43456 23.095 5.19835 23.4114 5.99476 23.4114V22.2102Z" fill="black" />
            </svg>
            <p>לעמוד הבית</p>
          </button>
          {/* {zoomLink ?*/}

          <ZoomRecordModal openButton={(onClick) =>
            <button className='side-menue-button' onClick={onClick}>
              <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.6469 4.32759C27.6469 3.75345 26.998 3.41948 26.5308 3.7532L19.2746 8.93615C18.8805 9.21767 18.8805 9.80343 19.2746 10.085L26.5308 15.2679C26.998 15.6016 27.6469 15.2677 27.6469 14.6935V4.32759Z" fill="black" />
                <path d="M17.1594 0.334106H2.73928C1.29127 0.334106 0.117432 1.50795 0.117432 2.95596V16.0652C0.117432 17.5132 1.29127 18.687 2.73928 18.687H17.1594C18.6075 18.687 19.7813 17.5132 19.7813 16.0652V2.95596C19.7813 1.50795 18.6075 0.334106 17.1594 0.334106Z" fill="black" />
              </svg>
              <p>הקלטה מערב ההנצחה</p>
            </button>}
            zoomRecordLink={zoomLink}
          />
          {/*:  <TalkingRoomsModalBtn OpenButton={(onClick) => <button className='side-menue-button' onClick={onClick}>
              <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.6469 4.32759C27.6469 3.75345 26.998 3.41948 26.5308 3.7532L19.2746 8.93615C18.8805 9.21767 18.8805 9.80343 19.2746 10.085L26.5308 15.2679C26.998 15.6016 27.6469 15.2677 27.6469 14.6935V4.32759Z" fill="black" />
                <path d="M17.1594 0.334106H2.73928C1.29127 0.334106 0.117432 1.50795 0.117432 2.95596V16.0652C0.117432 17.5132 1.29127 18.687 2.73928 18.687H17.1594C18.6075 18.687 19.7813 17.5132 19.7813 16.0652V2.95596C19.7813 1.50795 18.6075 0.334106 17.1594 0.334106Z" fill="black" />
              </svg>
              <p>הקלטות ערב ההנצחה</p>
            </button>} />} */}
          {/* } */}
          {/* <button className='side-menue-button' onClick={connectToTechenicalHelp}>
            <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Layer_1">
                <path id="Vector" d="M25.5 14V12.5797C25.5 6.73729 20.7988 2 15 2C9.20124 2 4.5 6.73729 4.5 12.5797V14" stroke="black" strokeWidth="1.64" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M4.07631 14H6.5V21H4.07631C3.20559 21 2.5 20.3333 2.5 19.5106V15.4894C2.5 14.6667 3.20559 14 4.07631 14Z" fill="black" stroke="black" strokeWidth="1.64" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_3" d="M25.9237 14H23.5V21H25.9237C26.7944 21 27.5 20.3333 27.5 19.5106V15.4894C27.5 14.6667 26.7944 14 25.9237 14Z" fill="black" stroke="black" strokeWidth="1.64" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_4" d="M8.5 26H13.5" stroke="black" strokeWidth="1.64" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_5" d="M4.5 22V21" stroke="black" strokeWidth="1.64" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_6" d="M13.5 25.5C13.5 26.3282 14.1718 27 15 27C15.8282 27 16.5 26.3282 16.5 25.5C16.5 24.6718 15.8282 24 15 24C14.1718 24 13.5 24.6718 13.5 25.5Z" fill="black" stroke="black" strokeWidth="1.64" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_7" d="M8.5 26C6.29077 26 4.5 24.2088 4.5 22" stroke="black" strokeWidth="1.64" strokeLinecap="round" strokeLinejoin="round" />
              </g>
            </svg>
            <p>תמיכה טכנית</p>
          </button> */}
        </div>}
    </div>
  );
};
export default SideMenue;
