import './person-1.scss'
import React from 'react';
import ElementsCarousle from '../common/elements-slides/elements-slides';
import TabHeaderAndExpandableText from '../common/elements-slides/tab-header-and-expandable-text/tab-header-and-expandable-text';
import useDeviceDetection from '../../useDeviceDetection';
import { MosheOhayonTexts } from './person-1-texts';
import Swipper from '../common/swipper/swipper';
import { Outlet, useOutletContext } from 'react-router-dom';
import { personsRouts } from '../../models';
import SpeiaEdgeImage from '../common/speia-edge-image/speia-edge-image';
import Padlet from '../common/padlet/padlet';
import { MosheOhayonImages } from './person-1-images'
import TextOverlayImage from '../common/elements-slides/text-overlay-image/text-overlay-image';
import EmptyRow from '../common/empty-row/empty-row';



const Person1 = () => {

  //#region elements slides
  const elementSlides = {
    earth: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textWidth='432px'
            text={MosheOhayonTexts.slides.earth.mainText} textHeight='99px'
            mainShortText={MosheOhayonTexts.slides.earth.mainShortText}
            customMoadlTextStyle={{ width: '793px' }} />
          <TextOverlayImage imagePath={MosheOhayonImages.slides.earth[0].imagePath}
            width={'432px'} height={'286px'}
            text={MosheOhayonTexts.slides.earth.imagesTexts} textMaxWidth={"229px"}
          />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MosheOhayonImages.slides.earth[1]} />
          <SpeiaEdgeImage image={MosheOhayonImages.slides.earth[2]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MosheOhayonImages.slides.earth[3]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <div className='tab-row'>
            <TabHeaderAndExpandableText header={"מים"}
              text={MosheOhayonTexts.slides.water.mainText} textWidth='404px'
              mainShortText={MosheOhayonTexts.slides.water.mainShortText} textHeight='97px'
              customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }} />
            <TextOverlayImage imagePath={MosheOhayonImages.slides.water[0].imagePath} width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={MosheOhayonImages.slides.water[1].imagePath} width={'366px'} height={'249px'}
            />
          </div>
          <div className='tab-row'>
            <TextOverlayImage imagePath={MosheOhayonImages.slides.water[2].imagePath}
              width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={MosheOhayonImages.slides.water[3].imagePath} width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={MosheOhayonImages.slides.water[4].imagePath} width={'366px'} height={'249px'}
              isCorner={true}
            />
          </div>
        </div>
      </div>
      )
    },
    fire: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"} textWidth='406px'
            text={MosheOhayonTexts.slides.fire.mainText} textHeight='127px'
            mainShortText={MosheOhayonTexts.slides.fire.mainShortText}
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }} />
          <TextOverlayImage imagePath={MosheOhayonImages.slides.fire[0].imagePath} width={'466px'} height={'288px'}
          />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={MosheOhayonImages.slides.fire[1].imagePath}
            width={'348px'} height={'542px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={MosheOhayonImages.slides.fire[2].imagePath}
            width={'348px'} height={'542px'} isCorner={true} />
        </div>
      </div>
      )
    },
    air: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"רוח"}
            text={MosheOhayonTexts.slides.air.mainText} textWidth='404px'
            mainShortText={MosheOhayonTexts.slides.air.mainShortText} textHeight='46px'
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }} />
          <TextOverlayImage imagePath={MosheOhayonImages.slides.air[0].imagePath} width={'466px'} height={'288px'}
          />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={MosheOhayonImages.slides.air[1]} isCorner={true} />
        </div>
      </div>
      )
    },

  };

  const mobileElementSlides = {
    earth: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textMobileWidth='330px'
            text={<>
              {MosheOhayonTexts.slides.earth.mainShortText}
              <EmptyRow height={20} />
              {MosheOhayonTexts.slides.earth.mainText}
            </>
            }
            textHeight='127px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textFullHeight={'1085.1px'} />
          <SpeiaEdgeImage image={MosheOhayonImages.slides.earth[3]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"} textMobileWidth='324px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {MosheOhayonTexts.slides.water.mainShortText}
              <EmptyRow height={5} />
              {MosheOhayonTexts.slides.water.mainText}
            </>
            } textHeight='127px' textFullHeight={'990px'}
          />
          <SpeiaEdgeImage image={MosheOhayonImages.slides.water[3]} isCorner={true} imageCrop={"top"} />
        </div>
      </div>
      )
    },
    fire: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container tab-rows-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"} textMobileWidth='342px '
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {MosheOhayonTexts.slides.fire.mainShortTextMobile}
              <EmptyRow height={5} />
              {MosheOhayonTexts.slides.fire.mainText}
            </>
            } textHeight='153px' textFullHeight={'566px'} />
          <SpeiaEdgeImage image={MosheOhayonImages.slides.fire[0]} isCorner={true} />
        </div>
      </div>
      )
    },
    air: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"רוח"} textMobileWidth='302px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {MosheOhayonTexts.slides.air.mainShortText}
              <EmptyRow height={5} />
              {MosheOhayonTexts.slides.air.mainTextMobile}
            </>
            } textHeight='152px' textFullHeight={'571px'} />

          <SpeiaEdgeImage image={MosheOhayonImages.slides.air[1]} isCorner={true} />
        </div>
      </div>
      )
    }

  };
  //#endregion

  const deviceType = useDeviceDetection();
  const [selected, setSelected] = useOutletContext();
  // const [isFirstTime, setisFirstTime] = useState(false);
  // useEffect(() => {
  //   if (selected === '05') {
  //     setisFirstTime(true);
  //     console.log('loaddddddddddd.....');
  //     return;
  //   }
  // });




  return (
    <>
      <div className={`moshe-ohayon-component ${(deviceType.isMobile) ? 'moshe-ohayon-component-mobile' : ''}`}>
        <div className='section-start-01'>
          <div id='start' className='anchor-section-start'>
            <div className='handwriting-img'>
              {deviceType.isMobile ? MosheOhayonImages.handWirtingStatmentMobile : MosheOhayonImages.handWirtingStatment}
            </div>
            <div className='line'>
              <div> </div>
              <div className='line-style'> </div>
            </div>
            <div className='vertical-lines-container'>
              <div>
                <div className='vertical-line vertical-line-1' ></div>
                <div className='vertical-line vertical-line-2' ></div>
              </div>
              <div className='vertical-line vertical-line-3' ></div>
            </div>
          </div>
          <div className='person-section-1'>
            <img className='big-img' src={MosheOhayonImages.bigImage} alt={personsRouts[0].name} />
            <div className='text-group'>
              {deviceType.isMobile && <div className='top-right-corner'></div>}
              <div className='description-text-container'>
                <div>
                  <h1> {personsRouts[0].name}</h1>
                  <h3>{MosheOhayonTexts.dates}</h3>
                </div>
                <p>{MosheOhayonTexts.main}</p>
                {deviceType.isMobile && <div className='bottom-left-corner'></div>}
              </div>
            </div>
          </div>
        </div>
        <div className='two-anchors-sections flex-section'>
          <div id="02" className='anchor-section-02 relative-conatainer flex-section'>
            {deviceType.isMobile && <h1  >סיפור אישי</h1>}
            <ElementsCarousle elementSlides={deviceType.isMobile ? mobileElementSlides : elementSlides}></ElementsCarousle>
          </div>
        </div>
        <div id="03" className='anchor-section-03 flex-section'>
          <div>
            <h2 className='section-header'>{MosheOhayonTexts.songHeader}</h2>
            <div className='author-name-conatainer'>
              <h4>{MosheOhayonTexts.songAuthor}</h4>
              <div className='decoration-line'></div>
              <div className='decoration-line decoration-line-2' style={{ marginRight: '16px' }}></div>
            </div>
          </div>
          <div className='song-container'>
            <iframe style={{
              borderRadius: '21px', border: 'none'
            }}
              title={MosheOhayonTexts.song}
              src={`https://www.youtube.com/embed/${MosheOhayonTexts.songLink}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}>
            </iframe>
          </div>
        </div>
        <div id="04" className='anchor-section-04 flex-section'>
          <div className='image-background-container'>
            <h2 className='section-header'>שיתופים על {personsRouts[0].name}</h2>
            <div className='decoration-line decoration-line-1'></div>
            <div className='decoration-line decoration-line-2' style={{ width: '909px', marginRight: '287px' }}></div>
            <Padlet padletLink={MosheOhayonTexts.padletLink} />
          </div>
        </div>
        <div id="05" className='anchor-section-05 flex-section'>
          <h2 className='section-header'>אלבום תמונות</h2>
          {deviceType.isMobile && <div className='vertical-lines-container'>
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="7" viewBox="0 0 54 7" fill="none">
              <rect width="17.2037" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 17.2041 0.807129)" fill="black" />
              <rect width="12.0426" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 33.5469 0.807129)" fill="black" />
              <rect width="42.5792" height="1.29028" transform="matrix(1 0 0 -1 10.752 6.39795)" fill="black" />
            </svg>
          </div>}
          <div style={{ position: 'relative', maxWidth: !deviceType.isMobile ? '1168px' : '80vw' }}>
            {selected === '05' && <Swipper images={deviceType.isMobile ? MosheOhayonImages.mobileSwipper : MosheOhayonImages.carousle}
              height={'515px'} margin={'62px'} spaceBetween={20} slidesPerView={deviceType.isXLScreen ? 3.43 : 2.83} />
            }</div>
        </div>
      </div>
      <Outlet></Outlet>
    </>
  );
};
export default Person1;
