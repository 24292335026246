import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import './assets/fonts/FbExtrim-Bold.otf'
import './assets/fonts/FbExtrim-Regular.otf'
import Home from "./componenets/Home/Home";
import Nav from "./componenets/share/navbar/Nav";
import Footer from "./componenets/share/footer/Footer";
import Person1 from './componenets/person-1/person-1'
import Person2 from './componenets/person-2/person-2'
import Person3 from './componenets/person-3/person-3'
import Person4 from './componenets/person-4/person-4'
import Person5 from './componenets/person-5/person-5'
import Person6 from './componenets/person-6/person-6'
import Person7 from './componenets/person-7/person-7.jsx'
import SideNavigation from "./componenets/share/side-navigation/sideNavigation";
import SideMenue from "./componenets/share/side-menue/sideMenue";
import { personsRouts } from "./models.js";
import useDeviceDetection from "./useDeviceDetection.jsx";

function App() {
  const deviceType = useDeviceDetection();
  const personsComps = [<Person1 />, <Person2 />, <Person3 />, <Person4 />, <Person5 />, <Person6 />, <Person7 />]

  return (
    <HashRouter>
      <div className="App">
        <Nav />
        <div className={`body ${deviceType.isMobile ? 'body-mobile' : ''}`}>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="'/Home'" element={<Home />} />
            <Route Component={SideNavigation}>
              {personsRouts.map((r, i) =>
                <Route path={r.path} element={personsComps[i]} key={i}>
                  {!deviceType.isMobile && <Route path='' element={<SideMenue zoomLink={r.zoomLink} key={'zoom' + i}/>} />}
                </Route>)}
            </Route>
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </HashRouter>

  );
}

export default App;
