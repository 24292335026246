import React, { useState } from 'react';
import './elements-slides.scss'
import elementsSvg from './svg-elements'
import useDeviceDetection from '../../../useDeviceDetection';

const ElementsCarousle = ({ elementSlides }) => {

    const deviceType = useDeviceDetection();
    const elements = [
        { id: 1, description: 'earth', name: 'אדמה' },
        { id: 2, description: 'water', name: 'מים' },
        { id: 3, description: 'fire', name: 'אש' },
        { id: 4, description: 'air', name: 'רוח' }
    ];

    const [selectedElement, setActiveSlide] = useState(elements[0]);
    const [isHover, setIsHover] = useState();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isNoTransition, setisNoTransition] = useState();


    const handleSetActiveSlide = (element) => {
        setisNoTransition(true);
        setActiveSlide(element);
        setIsExpanded(false);
        setisNoTransition(false);
        setTimeout(() => setisNoTransition(false), 5000)
    };


    const tabsContainer = <div className="tabs-container">
        {elements.map(element => (
            <div key={element.id} className='element-tab'>
                <div onClick={() => handleSetActiveSlide(element)}
                    onMouseEnter={() => setIsHover(element.id && !deviceType.isMobile)}
                    onMouseLeave={() => setIsHover(null)}
                    className={`element-svg-container ${deviceType.isMobile ? 'element-svg-container-' + element.description : ''} ${selectedElement.id === element.id ? 'selected-svg' : ''}`}
                >
                    {elementsSvg[element.description][selectedElement.id === element.id || isHover === element.id ? `selected${deviceType.isMobile? '': ''}` : 'unselected']}
                </div>
                <div className={`element-tab-name ${'element-tab-name-' + element.description}
                    ${(!deviceType.isMobile) && selectedElement.id === element.id ? 'selected' : ''}`}
                    onClick={() => handleSetActiveSlide(element)}>{element.name}</div>
            </div>
        ))}
    </div>

    return (
        <div className={`element-carusle-component ${deviceType.isMobile ? 'mobile-elements-carusle-component ' : ''} 
        ${isNoTransition ? 'mobile-elements-carusle-component-no-transition' : ''}`} >
            {tabsContainer}
            <div className={`slides-container`}>
                {elementSlides[selectedElement.description]({ isExpanded, setIsExpanded })}

            </div>
            {/* {!deviceType.isMobile && <div className="bottom-pagination">
                {elements.map(element => (
                    <button key={element.id}
                        className={'bottom-pagination-btn ' + (selectedElement.id === element.id ? 'selected' : '')}
                        onClick={() => setActiveSlide(element)}>
                    </button>
                ))}
            </div>} */}

        </div>
    )
}
export default ElementsCarousle;



