import bigImage from '../../assets/images/matan-rozenberg/big-image.png';
import handwriting from '../../assets/images/matan-rozenberg/משפט בכתב היד של הנרצח.png';
import image1 from '../../assets/images/matan-rozenberg/image-3.png';
import image2 from '../../assets/images/matan-rozenberg/image-1.png';
import image3 from '../../assets/images/matan-rozenberg/image-13.png';
import image4 from '../../assets/images/matan-rozenberg/image-4.png';
import image5 from '../../assets/images/matan-rozenberg/image-5.png';
import image6 from '../../assets/images/matan-rozenberg/image-6.png';
import image7 from '../../assets/images/matan-rozenberg/image-7.png';
import image8 from '../../assets/images/matan-rozenberg/image-8.png';
import image9 from '../../assets/images/matan-rozenberg/image-9.png';
import image10 from '../../assets/images/matan-rozenberg/image-14.png';
import image11 from '../../assets/images/matan-rozenberg/image-11.png';
import image12 from '../../assets/images/matan-rozenberg/image-12.png';
import cImage1 from '../../assets/images/matan-rozenberg/c-image-1.png';
import cImage2 from '../../assets/images/matan-rozenberg/c-image-2.png';
import cImage3 from '../../assets/images/matan-rozenberg/c-image-3.png';
import cImage4 from '../../assets/images/matan-rozenberg/c-image-4.png';
import cImage5 from '../../assets/images/matan-rozenberg/c-image-5.png';
import cImage6 from '../../assets/images/matan-rozenberg/c-image-6.png';
import cImage7 from '../../assets/images/matan-rozenberg/c-image-17.png';
import cImage8 from '../../assets/images/matan-rozenberg/c-image-8.png';
import cImage9 from '../../assets/images/matan-rozenberg/c-image-9.png';
import cImage10 from '../../assets/images/matan-rozenberg/c-image-10.png';
import cImage11 from '../../assets/images/matan-rozenberg/c-image-11.png';
// import cImage12 from '../../assets/images/matan-rozenberg/c-image-12.png';
import cImage13 from '../../assets/images/matan-rozenberg/c-image-13.png';
import cImage14 from '../../assets/images/matan-rozenberg/c-image-14.png';
import cImage15 from '../../assets/images/matan-rozenberg/c-image-15.png';
import cImage16 from '../../assets/images/matan-rozenberg/c-image-16.png';
import cMobileImage0 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-0.png';
import cMobileImage1 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-1.png';
import cMobileImage2 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-2.png';
import cMobileImage3 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-3.png';
import cMobileImage4 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-4.png';
import cMobileImage5 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-5.png';
import cMobileImage6 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-6.png';
import cMobileImage7 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-7.png';
import cMobileImage8 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-8.png';
import cMobileImage9 from '../../assets/images/matan-rozenberg/mobile-swipper/unsplash_CBh4D3l0EwM-9.png';


export const MatanRozenbergImages = {
    bigImage: bigImage,
    handWirtingStatment: handwriting,
    signature: (<svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none">
        <path d="M45.1492 15.11C40.6292 12.42 36.7292 10.1 32.8292 7.77997C32.2592 8.19997 31.6992 8.62997 31.1292 9.04997C31.8692 14.04 32.3892 19.07 33.3992 24C34.6692 30.19 36.4292 36.28 37.8092 42.44C41.2092 57.62 32.3692 65.4 20.5092 67.66C15.6792 68.58 10.5192 67.81 5.51922 67.81C5.49922 67.37 5.46922 66.93 5.44922 66.48C9.54922 65.96 13.7892 65.93 17.6992 64.78C21.7292 63.6 25.6192 61.62 29.2392 59.45C33.3392 57 34.9392 52.72 34.1592 48.22C32.9192 41.03 30.8592 33.99 29.3892 26.84C28.2892 21.48 27.1492 16.07 26.7692 10.63C26.3492 4.64997 28.7092 2.67997 34.2792 4.58997C37.8692 5.81997 41.0692 8.41997 44.1092 10.83C45.0392 11.56 44.8492 13.7 45.1492 15.1V15.11Z" fill="white" />
        <path d="M25.6494 55.1899C25.2894 54.8999 24.8194 54.6699 24.5994 54.2999C18.2394 43.7999 11.8194 33.3299 5.62944 22.7399C3.62944 19.3199 1.83944 15.6699 0.729438 11.8999C0.0894381 9.7299 0.999438 7.0999 1.20944 4.6699C1.73944 4.5799 2.25944 4.4899 2.78944 4.3999C3.65944 5.4799 5.38944 6.6699 5.25944 7.6099C4.04944 16.4699 10.6994 21.9699 14.0894 28.8199C17.8294 36.3999 21.8894 43.8199 25.7394 51.3499C26.2894 52.4199 26.3994 53.7099 26.7094 54.8899L25.6494 55.1899Z" fill="white" />
        <path d="M53.2495 19.72C53.4695 15.34 53.5495 11.78 53.8795 8.24C54.0095 6.91 54.7195 5.64 55.1695 4.34C56.1795 5.15 57.2795 5.88 58.1895 6.79C59.5395 8.14 60.7495 9.63 62.0195 11.06C62.4795 10.97 62.9395 10.87 63.3895 10.78C63.4995 9.04 63.6495 7.3 63.7095 5.56C63.7595 3.92 63.7195 2.27 63.7195 0C67.9695 2.78 69.4795 8.3 67.8595 12.23C66.5095 15.49 64.9795 15.93 59.6295 14.62C56.9095 20.56 55.5595 21.32 53.2495 19.71V19.72Z" fill="white" />
    </svg>),
    mobileSignature: (<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.6988 6.66721C17.7044 5.48028 15.9836 4.45661 14.2627 3.43294C14.0112 3.61825 13.7641 3.80799 13.5126 3.99331C13.8391 6.19508 14.0686 8.41451 14.5142 10.5898C15.0746 13.3211 15.8512 16.0082 16.4601 18.7262C17.9603 25.4242 14.0598 28.857 8.82668 29.8542C6.6955 30.2602 4.41872 29.9204 2.21253 29.9204C2.2037 29.7263 2.19047 29.5321 2.18164 29.3336C3.99071 29.1041 5.86156 29.0909 7.5868 28.5835C9.36499 28.0628 11.0814 27.1892 12.6787 26.2317C14.4878 25.1507 15.1937 23.2622 14.8496 21.2766C14.3024 18.1041 13.3935 14.9978 12.7449 11.8429C12.2595 9.47789 11.7565 7.0908 11.5888 4.69046C11.4035 2.05186 12.4448 1.18262 14.9025 2.02539C16.4866 2.56811 17.8985 3.71533 19.2399 4.77871C19.6502 5.10081 19.5664 6.04506 19.6988 6.66279V6.66721Z" fill="white" />
        <path d="M11.0947 24.3519C10.9359 24.2239 10.7285 24.1224 10.6314 23.9592C7.82515 19.3262 4.99241 14.7064 2.26115 10.0337C1.37867 8.52467 0.588856 6.91415 0.0990823 5.25069C-0.18331 4.2932 0.218216 3.13275 0.310876 2.06054C0.544732 2.02083 0.774176 1.98112 1.00803 1.94141C1.39191 2.41794 2.15525 2.94302 2.09789 3.35778C1.56399 7.26714 4.49822 9.69395 5.99402 12.7164C7.64425 16.061 9.43567 19.335 11.1344 22.6575C11.3771 23.1296 11.4257 23.6988 11.5624 24.2195L11.0947 24.3519Z" fill="white" />
        <path d="M23.2729 8.70121C23.37 6.76859 23.4053 5.19778 23.5509 3.6358C23.6083 3.04895 23.9216 2.48858 24.1201 1.91497C24.5658 2.27237 25.0511 2.59448 25.4527 2.996C26.0483 3.59167 26.5822 4.24912 27.1426 4.88009C27.3456 4.84038 27.5485 4.79625 27.7471 4.75654C27.7956 3.98879 27.8618 3.22104 27.8883 2.45328C27.9104 1.72965 27.8927 1.00161 27.8927 0C29.768 1.22664 30.4342 3.66227 29.7194 5.39634C29.1238 6.83477 28.4487 7.02892 26.088 6.45089C24.8879 9.07185 24.2922 9.40719 23.2729 8.69679V8.70121Z" fill="white" />
    </svg>
    ),
    slides: {
        earth: [
            { imagePath: image1, description: 'מתן עם אחיו', width: '415.74', height: '243.573' },
            { imagePath: image2, description: 'מתן בטיול', width: '787.968', height: '515' }
        ],
        water: [
            { imagePath: image3, description: '', width: '413.654', height: '260' },
            { imagePath: image4, description: '', width: '438.732', height: '225' },
            { imagePath: image5, description: 'מתן בצופים', width: '438.732', height: '260' },
            {imagePath: image6, description: '', width: '335.654', height: '514'}
        ],
        fire: [
            { imagePath: image7, description: '', width: '280', height: '515' },
            { imagePath: image8, description: '', width: '456', height: '515' },
            { imagePath: image9, description: '', width: '455.619', height: '261.529' }
        ],
        air: [
            { imagePath: image10, description: '', width: '415.739', height: '261.529' },
            { imagePath: image11, description: '', width: '427.211', height: '515' },
            { imagePath: image12, description: '', width: '347.211', height: '515' }
        ]

    },
    carousle: [
        { img: cImage1 },
        { img: cImage9 },
        { img: cImage2, margin: -149 },
        { img: cImage10 },
        { img: cImage3, margin: -299 },

        { img: cImage11 },
        { img: cImage4, margin: -145 },
        { img: cImage13 },
        { img: cImage5, margin: -285 },
        { img: cImage6 },
        { img: cImage16, margin: -289 },
        { img: cImage14 },
        { img: cImage7, margin: -440 },
        { img: cImage15 },
        { img: cImage8, margin: -440 }
    ],
    mobileSwipper: [
        { img: cMobileImage0 },
        { img: cMobileImage1 },
        { img: cMobileImage2 },
        { img: cMobileImage3 },
        { img: cMobileImage4 },
        { img: cMobileImage5 },
        { img: cMobileImage6 },
        { img: cMobileImage7 },
        { img: cMobileImage8 },
        { img: cMobileImage9 }
    ]
}