
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Grid } from 'swiper/modules';

import { IconButton } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useDeviceDetection from '../../../useDeviceDetection';

import "swiper/css";
import 'swiper/css/grid';
import './customSwiperStyles.scss'
import CommonVideo from "../common-video/common-video";


const Swipper = ({ images, height = '430px', margin = '30px', spaceBetween = 30, slidesPerView=4}) => {

  const deviceType = useDeviceDetection();

  return (<>
    <Swiper
      className={`${deviceType.isMobile ? 'swiper-container-mobile' : ''}`}
      modules={[Navigation, Autoplay, Grid]}
      spaceBetween={spaceBetween}
      style={{'--height': height, '--margin':margin}}
      slidesPerView={deviceType.isMobile ? 1 : slidesPerView}
      grid={deviceType.isMobile ? false : { rows: 2 }}
      navigation={{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
        enabled: true
      }}
      autoplay={{
        delay: 4000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }}
      loop={false}
      slidesOffsetAfter={100}
      slidesPerGroup={1}
      dir='ltr'
      grabCursor={true}
    >
      {images.map((image, index) => (
        <SwiperSlide key={`img_${index}`} className={`swiper-slide`} style={{ marginLeft: `${!deviceType.isMobile && image.margin ? image.margin : 0}px` }}>
          {image.isVideo?
           <CommonVideo src={image.img} style={deviceType.isMobile? {...image.style, width:'70vw', height:'auto'}:image.style} description={image.description} />
           : <img src={image.img} style={deviceType.isMobile? {width:'70vw', objectFit:'cover'}:{}}/>}
        </SwiperSlide>
      ))}
    </Swiper>


    <>
      <IconButton className="swiper-button-prev" color="inherit" edge="start"
        style={{ left: `-${deviceType.isMobile ? 33 : 76}px` }}>
        <ArrowBackIcon fontSize='small' />
      </IconButton>

      <IconButton className="swiper-button-next" color="inherit"
        style={{ right: `-${deviceType.isMobile ? 43 : 76}px` }}>
        <ArrowForwardIcon fontSize='small' />
      </IconButton>
    </>
  </>);
}

export default Swipper;
