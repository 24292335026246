import bigImage from '../../assets/images/livnat-koz/big-image.png';

import handwriting from '../../assets/images/matan-rozenberg/משפט בכתב היד של הנרצח.png';
import image1 from '../../assets/images/livnat-koz/image-1.png';
import image2 from '../../assets/images/livnat-koz/image-2.png';
import image3 from '../../assets/images/livnat-koz/image-3.png';
import image4 from '../../assets/images/livnat-koz/image-4.png';
import image5 from '../../assets/images/livnat-koz/image-5.png';
import image6 from '../../assets/images/livnat-koz/image-6.png';
import image7 from '../../assets/images/livnat-koz/image-7.png';
import image8 from '../../assets/images/livnat-koz/image-8.png';
import image9 from '../../assets/images/livnat-koz/image-9.png';
import image10 from '../../assets/images/livnat-koz/image-10.png';
import image11 from '../../assets/images/livnat-koz/image-11.png';
import image12 from '../../assets/images/livnat-koz/image-12.png';
import image13 from '../../assets/images/livnat-koz/image-13.png';
import image14 from '../../assets/images/livnat-koz/image-14.png';
import image15 from '../../assets/images/livnat-koz/image-15.png';
import elementsMobileImage from '../../assets/images/livnat-koz/elements-mobile-image.png';
import cImage1 from '../../assets/images/livnat-koz/c-image-1.png';
import cImage2 from '../../assets/images/livnat-koz/c-image-2.png';
import cImage3 from '../../assets/images/livnat-koz/c-image-3.png';
import cImage4 from '../../assets/images/livnat-koz/c-image-4.png';
import cImage5 from '../../assets/images/livnat-koz/c-image-5.png';
import cImage6 from '../../assets/images/livnat-koz/c-image-6.png';
import cImage7 from '../../assets/images/livnat-koz/c-image-7.png';
import cImage8 from '../../assets/images/livnat-koz/c-image-8.png';
import cImage9 from '../../assets/images/livnat-koz/c-image-9.png';
import cImage12 from '../../assets/images/livnat-koz/c-image-12.png';
import cImage13 from '../../assets/images/livnat-koz/c-image-13.png';
import cImage14 from '../../assets/images/livnat-koz/c-image-14.png';
import cImage15 from '../../assets/images/livnat-koz/c-image-15.png';
import cImage16 from '../../assets/images/livnat-koz/c-image-16.png';
import cImage17 from '../../assets/images/livnat-koz/c-image-17.png';
import cImage18 from '../../assets/images/livnat-koz/c-image-18.png';
import cImage19 from '../../assets/images/livnat-koz/c-image-19.png';
import cImage20 from '../../assets/images/livnat-koz/c-image-20.png';
import cImage21 from '../../assets/images/livnat-koz/c-image-21.png';
import cImage22 from '../../assets/images/livnat-koz/c-image-22.png';
import cImage23 from '../../assets/images/livnat-koz/c-image-23.png';
import cImage24 from '../../assets/images/livnat-koz/c-image-24.png';
import cImage25 from '../../assets/images/livnat-koz/slide-placeholder.png';
import cMobileImage0 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-1.png';
import cMobileImage1 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-2.png';
import cMobileImage2 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-3.png';
import cMobileImage3 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-4.png';
import cMobileImage4 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-5.png';
import cMobileImage5 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-6.png';
import cMobileImage6 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-7.png';
import cMobileImage7 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-8.png';
import cMobileImage8 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-9.png';
import cMobileImage9 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-10.png';
import cMobileImage10 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-11.png';
import cMobileImage11 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-12.png';
import cMobileImage12 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-13.png';
import cMobileImage13 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-14.png';
import cMobileImage14 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-15.png';
import cMobileImage15 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-16.png';
import cMobileImage16 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-17.png';
import cMobileImage17 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-18.png';
import cMobileImage18 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-19.png';
import cMobileImage19 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-20.png';
import cMobileImage20 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-21.png';
import cMobileImage21 from '../../assets/images/livnat-koz/mobile-swipper/c-image-mobile-22.png';


export const LivnatKozImages = {
    bigImage: bigImage,
    handWirtingStatment: <svg viewBox="0 0 1224 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4436 48.9218C13.9251 52.3215 11.7946 55.5853 11.092 59.1436C10.3894 62.7246 9.98145 67.0308 14.3557 69.2293C15.2623 69.6826 17.2568 69.7506 17.6195 69.2293C20.0899 65.603 23.6709 61.886 24.0789 57.9196C24.3962 54.7466 20.7472 51.1656 18.866 47.7659" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M25.6421 38.7222C21.857 40.6033 15.9189 44.9776 14.7177 43.935C10.8874 40.6033 7.05699 41.1926 3 40.9886" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M39.2635 29.656C38.5156 35.7075 37.745 41.7363 36.9971 47.7878" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M72.8366 44.3882C68.689 44.7735 63.9294 44.0029 60.5297 45.8161C56.4727 47.9692 53.0957 52.0262 50.6252 55.9925C50.1719 56.6951 55.0902 61.636 58.1953 63.4946C62.6149 66.1463 67.4651 70.2033 72.8366 64.7865" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M70.5715 29.656C73.2233 37.2034 75.8524 44.7507 78.5042 52.3208" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M134.432 40.9658C132.913 40.9658 131.418 40.9658 129.899 40.9658C118.793 41.0565 116.821 44.4109 121.966 54.5647C127.678 65.8744 132.981 69.4101 142.365 68.1635" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M102.702 22.8567C102.317 31.1747 102.271 39.4927 101.478 47.7654C100.844 54.2248 99.3251 60.6163 98.1919 67.0304" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M194.13 32.3621C193.044 39.8861 192.316 47.4823 190.815 54.9053C186.513 75.9851 187.98 96.8224 192.559 117.607C192.815 118.74 192.584 119.986 192.575 121.183" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M283.891 19.921C281.896 20.5783 279.856 21.1449 277.907 21.9155C272.989 23.91 270.994 27.3551 272.626 32.6587C274.054 37.3276 275.459 41.9965 276.592 46.7334C277.431 50.3371 278.066 54.0541 278.338 57.7485C278.632 61.6695 278.02 66.3611 273.079 65.8171C269.634 65.4318 265.555 62.7348 263.56 59.8564C261.158 56.3887 262.971 52.173 267.345 51.0171C272.196 49.7479 271.81 46.9828 270.904 43.3791C269.906 39.3901 268.682 35.3557 268.41 31.2987C267.685 20.3517 271.561 17.1786 283.891 19.9437V19.921Z" fill="black" />
        <path d="M223.938 33.7322C218.952 45.1552 219.292 52.5439 225.071 57.5302C229.808 61.6098 239.214 62.2899 244.291 58.6182C247.532 56.261 250.365 53.3825 253.402 50.7534" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M210.363 28.0659C209.229 28.8139 207.167 29.5165 207.099 30.3324C206.51 40.1462 206.192 49.96 205.83 59.7739" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M567.056 61.4773C557.446 69.0474 548.131 69.7046 538.521 63.6078C533.716 60.5708 532.175 53.1367 535.008 47.6972C537.932 42.0763 546.182 37.7247 550.692 39.8098C552.052 40.4218 552.755 42.4843 554.613 45.0454C551.735 45.3854 550.126 45.8613 548.607 45.7027C543.485 45.1134 540.561 47.4705 540.198 52.3661C539.836 57.443 543.213 59.7775 547.791 60.3441C551.123 60.7521 554.523 60.7294 557.877 60.9107C560.733 61.0694 563.566 61.2507 567.056 61.4547V61.4773Z" fill="black" />
        <path d="M524.289 56.2871C528.278 41.6683 531.587 29.5653 535.282 16.0571C538.681 26.3243 533.809 44.2521 524.289 56.2871Z" fill="black" />
        <path d="M596.943 6.99146C591.277 8.87263 584.455 9.41659 580.262 13.0203C575.548 17.0773 573.893 23.7181 576.409 30.8348C578.993 38.1329 580.783 45.8162 582.007 53.4769C582.528 56.6726 581.758 61.2962 579.695 63.3814C577.157 65.9425 571.989 68.413 568.839 64.628C565.031 60.0497 569.292 57.5565 573.168 55.7207" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M427.942 25.1233C416.338 28.387 404.756 49.8052 410.014 58.9844C410.898 60.5256 414.933 61.8854 416.519 61.1602C425.54 56.9672 425.97 45.3176 417.743 36.4557" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M413.232 35.3225C405.685 32.0361 398.137 32.0361 390.567 35.3225" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M378.079 54.6877C377.331 51.288 376.288 47.9336 375.858 44.4886C374.77 35.672 368.242 36.9185 362.214 36.4879C354.689 35.9439 357.114 42.4034 355.641 45.7351C354.53 48.2509 356.275 52.0132 355.278 54.6424C351.199 65.3175 343.878 67.6066 331.639 62.6204" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M442.696 25.1233C444.577 25.8712 446.458 27.2765 448.362 27.2765C457.179 27.3218 460.896 29.5203 460.805 35.3224C460.692 43.1191 455.139 43.1871 449.495 43.3004C447.592 43.3457 445.71 44.003 443.829 44.3883" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M454.028 44.3882C458.04 50.213 456.657 54.678 450.629 57.987" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M477.826 22.8567L474.427 43.255" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M336.172 34.189C335.424 38.7219 334.653 43.2549 333.905 47.7878" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M690.864 29.3734C691.317 24.6138 693.878 21.4181 698.615 21.1915C704.621 20.8968 710.446 21.5541 715.183 26.359C720.872 32.1386 720.6 39.0966 719.399 47.4372H757.498C757.498 48.0265 757.498 48.6158 757.498 49.2278C740.862 50.2477 724.226 51.2676 705.029 52.4462C715.999 45.6694 717.495 41.6804 713.868 34.0877C710.786 27.6283 703.556 25.1352 695.963 27.7869C694.241 28.3989 692.382 28.6709 690.592 29.0788L690.909 29.3734H690.864Z" fill="black" />
        <path d="M690.523 29.0789C690.954 50.7237 671.87 69.0141 655.665 63.3479C661.059 60.1522 666.884 56.7752 672.618 53.2848C679.735 48.9558 684.132 43.0403 684.766 34.2917C684.902 32.5239 688.733 31.0053 690.863 29.3734L690.546 29.0789H690.523Z" fill="black" />
        <path d="M752.353 38.6443C746.165 35.6298 740.998 33.1141 735.4 30.417C742.108 25.7934 751.469 27.9239 755.594 34.2927C759.742 40.6841 757.747 47.3929 750.313 50.8606C743.038 54.2376 735.445 55.1442 725.926 52.2658C735.014 46.7809 747.888 51.3819 752.353 38.6669V38.6443Z" fill="black" />
        <path d="M807.381 7.63208C800.944 7.63208 796.637 7.2467 792.422 7.7C781.883 8.8559 780.138 13.185 782.177 23.8147C783.832 32.4273 783.152 41.5386 783.039 50.4231C783.016 52.5536 780.591 56.5652 780.047 56.4519C777.055 55.7493 773.225 54.7521 771.661 52.5083C767.967 47.114 768.103 40.4506 772.069 35.5097C776.126 30.4555 775.876 25.3559 775.695 19.6897C775.333 8.38003 777.44 6.06822 788.795 3.9604C798.02 2.26054 799.471 2.44183 807.381 7.60939V7.63208Z" fill="black" />
        <path d="M824.358 10.1123C820.777 11.1322 818.579 11.9708 816.29 12.3788C808.652 13.784 805.116 18.0223 806.249 25.479C806.929 29.8986 808.833 34.1369 809.671 38.5339C810.442 42.6136 810.805 47.0105 806.226 49.4583C801.331 52.0647 796.594 50.9768 792.537 47.7358C788.027 44.1321 788.321 37.242 793.67 35.9501C802.94 33.7063 802.464 27.3602 803.031 20.4701C804.028 7.89115 810.193 4.60473 824.336 10.0896L824.358 10.1123Z" fill="black" />
        <path d="M647.732 57.3953C647.483 68.977 641.681 77.5669 633 84.593" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M889.112 25.6648C888.364 30.1977 887.593 34.7307 886.845 39.2636" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M940.04 39.076C937.252 44.3116 939.541 48.4365 943.575 51.3376C944.89 52.2895 948.312 51.3376 950.08 50.1817C950.941 49.6151 951.032 45.6488 950.103 44.9009C942.397 38.5774 934.713 32.1405 926.327 26.8143C921.318 23.6413 915.834 27.4263 915.154 33.4098C914.338 40.5719 913.658 47.7566 912.865 54.9187C911.822 64.2112 906.405 69.1067 895.889 69.5827C887.593 69.9453 879.275 69.6508 870.98 69.6508" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1003.36 3.01115C998.575 2.7845 997.328 6.04825 995.22 9.69728C988.036 22.1176 984.659 35.7617 982.143 49.5192C981.055 55.4347 977.565 56.0013 973.916 55.9334C971.989 55.888 969.768 52.6923 968.476 50.4711C968.113 49.8365 970.471 47.5474 971.649 46.0742C975.253 41.6092 978.018 44.193 980.692 47.2074" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1025.13 49.4162C1025.87 43.3647 1027.26 37.3358 1027.21 31.2844C1027.17 25.2555 1021.14 18.4334 1017.19 18.8867C1010.58 19.6346 1007.56 23.7823 1005.68 30.1057C1002.92 39.353 1006.75 47.5803 1008.13 56.2156" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1085.14 41.6304C1084.39 38.2306 1083.35 34.8763 1082.92 31.4312C1081.83 22.6146 1075.3 23.8611 1069.27 23.4305C1061.75 22.8865 1064.17 29.346 1062.7 32.6778C1061.59 35.1935 1063.34 38.9559 1062.34 41.585C1058.26 52.2601 1050.94 54.5493 1038.7 49.563" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1105.56 21.1318C1112.4 23.7383 1120.09 24.3728 1124.92 31.2629C1128.68 36.6345 1130.97 41.8701 1128.22 48.3295" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1103.29 34.7307C1103.68 37.3825 1104.52 40.0569 1104.31 42.6407C1104.06 45.7004 1102.93 48.6922 1102.16 51.7066" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1182.65 30.1751C1181.51 33.5748 1180.79 37.1785 1179.16 40.3289C1176.48 45.4284 1174.1 51.208 1170 54.8797C1162.79 61.3165 1154.45 57.2368 1149.8 46.0404C1146.31 37.5865 1146.84 21.7438 1150.94 12.0659C1158.85 21.1545 1158.53 28.1579 1149.8 38.1304" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1183.75 49.8058C1194.88 47.0181 1207.51 49.6925 1216.73 39.72C1221.79 34.2351 1223.19 30.1781 1217.59 26.2118C1211.11 21.6335 1204.15 16.194 1195.09 20.3416" stroke="black" stroke-width="4.53295" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    ,
    handWirtingStatmentMobile: <svg viewBox="0 0 358 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.40992 90.6874C8.50564 92.7119 7.23697 94.6554 6.81857 96.7744C6.40017 98.9069 6.15723 101.471 8.76211 102.78C9.30198 103.05 10.4897 103.091 10.7056 102.78C12.1768 100.621 14.3092 98.4075 14.5522 96.0455C14.7411 94.156 12.5682 92.0235 11.4479 89.999" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.4833 84.6135C13.2293 85.7338 9.69318 88.3386 8.97785 87.7178C6.69689 85.7337 4.41592 86.0847 2 85.9632" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M23.5948 79.2146C23.1494 82.8182 22.6905 86.4084 22.2451 90.012" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M43.5875 87.9878C41.1176 88.2172 38.2833 87.7584 36.2588 88.8381C33.8428 90.1203 31.8318 92.5362 30.3607 94.8981C30.0907 95.3165 33.0196 98.2588 34.8686 99.3656C37.5005 100.945 40.3888 103.361 43.5875 100.135" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M42.2383 79.2146C43.8174 83.709 45.383 88.2034 46.9621 92.7114" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M80.267 85.9495C79.3627 85.9495 78.4719 85.9495 77.5676 85.9495C70.9542 86.0034 69.78 88.001 72.8437 94.0475C76.2449 100.782 79.4032 102.888 84.9908 102.146" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M61.3724 75.1658C61.1429 80.1191 61.1159 85.0725 60.6435 89.9988C60.2656 93.8454 59.3614 97.6515 58.6865 101.471" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M115.816 80.8262C115.17 85.3067 114.736 89.8302 113.842 94.2506C111.28 106.804 112.154 119.212 114.881 131.589C115.034 132.264 114.896 133.006 114.891 133.719" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M169.269 73.4173C168.082 73.8087 166.867 74.1462 165.706 74.6051C162.777 75.7928 161.59 77.8444 162.562 81.0026C163.412 83.7829 164.249 86.5632 164.923 89.384C165.423 91.53 165.801 93.7435 165.963 95.9434C166.138 98.2784 165.774 101.072 162.831 100.748C160.78 100.519 158.351 98.9128 157.163 97.1987C155.732 95.1337 156.812 92.6233 159.417 91.9349C162.305 91.1791 162.076 89.5325 161.536 87.3865C160.942 85.0111 160.213 82.6087 160.051 80.1927C159.619 73.6738 161.927 71.7842 169.269 73.4308V73.4173Z" fill="black"/>
    <path d="M133.568 81.6421C130.598 88.4445 130.801 92.8444 134.243 95.8137C137.063 98.2431 142.665 98.6481 145.688 96.4616C147.618 95.0579 149.305 93.3438 151.113 91.7782" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M125.483 78.2678C124.808 78.7132 123.58 79.1316 123.54 79.6175C123.189 85.4616 123 91.3057 122.784 97.1498" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M337.893 98.1642C332.17 102.672 326.623 103.064 320.9 99.4329C318.039 97.6243 317.121 93.1974 318.808 89.9582C320.549 86.611 325.462 84.0196 328.148 85.2613C328.958 85.6257 329.376 86.8539 330.483 88.379C328.769 88.5815 327.811 88.8649 326.906 88.7705C323.856 88.4195 322.115 89.8232 321.899 92.7385C321.683 95.7618 323.694 97.1519 326.421 97.4894C328.405 97.7323 330.429 97.7188 332.427 97.8268C334.127 97.9213 335.814 98.0293 337.893 98.1507V98.1642Z" fill="black"/>
    <path d="M312.425 95.0735C314.801 86.368 316.771 79.1608 318.971 71.1167C320.996 77.2307 318.094 87.9067 312.425 95.0735Z" fill="black"/>
    <path d="M355.691 65.718C352.316 66.8382 348.254 67.1622 345.757 69.3082C342.95 71.7241 341.964 75.6787 343.463 79.9166C345.001 84.2626 346.067 88.838 346.796 93.3999C347.107 95.3029 346.648 98.0563 345.42 99.298C343.908 100.823 340.831 102.294 338.955 100.04C336.687 97.314 339.225 95.8293 341.532 94.7361" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M255.051 76.5156C248.141 78.4592 241.244 91.2136 244.375 96.6798C244.902 97.5976 247.304 98.4073 248.249 97.9754C253.621 95.4785 253.877 88.5412 248.978 83.264" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M246.291 82.5891C241.797 80.6321 237.302 80.6321 232.794 82.5891" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M225.357 94.1211C224.912 92.0966 224.291 90.0991 224.035 88.0476C223.387 82.7973 219.5 83.5396 215.91 83.2832C211.429 82.9592 212.873 86.8058 211.996 88.7899C211.334 90.288 212.374 92.5285 211.78 94.0941C209.35 100.451 204.991 101.814 197.703 98.845" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M263.837 76.5156C264.957 76.961 266.077 77.7978 267.211 77.7978C272.461 77.8248 274.675 79.134 274.621 82.5892C274.553 87.2321 271.247 87.2725 267.886 87.34C266.752 87.367 265.632 87.7584 264.512 87.9879" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M270.586 87.9878C272.974 91.4565 272.151 94.1153 268.561 96.0859" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M284.757 75.1658L282.732 87.3129" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M200.402 81.9141C199.957 84.6134 199.498 87.3128 199.053 90.0121" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M40.0306 17.7052C40.3005 14.8709 41.8257 12.9679 44.6465 12.8329C48.2231 12.6574 51.6918 13.0488 54.5126 15.9101C57.9003 19.3518 57.7383 23.4953 57.023 28.4621H79.7111C79.7111 28.813 79.7111 29.164 79.7111 29.5284C69.8044 30.1357 59.8978 30.7431 48.4661 31.4449C54.9985 27.4094 55.8893 25.034 53.7298 20.5126C51.8942 16.666 47.5888 15.1813 43.0674 16.7605C42.0416 17.1249 40.9349 17.2868 39.8687 17.5298L40.0576 17.7052H40.0306Z" fill="black"/>
    <path d="M39.8279 17.53C40.0843 30.4194 28.72 41.3113 19.0698 37.9371C22.2821 36.0341 25.7508 34.0231 29.1655 31.9446C33.4034 29.3667 36.0218 25.844 36.3997 20.6343C36.4807 19.5815 38.7616 18.6772 40.0303 17.7055L39.8414 17.53H39.8279Z" fill="black"/>
    <path d="M76.6469 23.226C72.9622 21.431 69.885 19.9329 66.5513 18.3268C70.5463 15.5734 76.1205 16.8421 78.5769 20.6347C81.0468 24.4408 79.8591 28.4358 75.4322 30.5008C71.0997 32.5118 66.5783 33.0517 60.9097 31.3376C66.3219 28.0714 73.988 30.8112 76.6469 23.2396V23.226Z" fill="black"/>
    <path d="M109.416 4.75838C105.582 4.75838 103.018 4.5289 100.508 4.79883C94.2316 5.48717 93.1924 8.06511 94.4071 14.3951C95.3924 19.5239 94.9875 24.9496 94.92 30.2403C94.9065 31.509 93.4624 33.8979 93.1384 33.8304C91.3569 33.412 89.0759 32.8181 88.1446 31.482C85.9447 28.2697 86.0256 24.3017 88.3876 21.3594C90.8035 18.3496 90.655 15.3129 90.547 11.9387C90.3311 5.20379 91.5863 3.82711 98.3482 2.57191C103.841 1.55966 104.705 1.66761 109.416 4.74487V4.75838Z" fill="black"/>
    <path d="M119.526 6.2352C117.393 6.84255 116.084 7.34193 114.721 7.58487C110.173 8.42167 108.067 10.9456 108.742 15.386C109.147 18.0179 110.281 20.5418 110.78 23.1602C111.239 25.5896 111.455 28.2079 108.728 29.6656C105.813 31.2177 102.992 30.5699 100.576 28.6398C97.8906 26.4939 98.0661 22.3908 101.251 21.6215C106.771 20.2853 106.488 16.5062 106.825 12.4032C107.419 4.91252 111.09 2.95547 119.512 6.22169L119.526 6.2352Z" fill="black"/>
    <path d="M14.3457 34.3921C14.1972 41.2889 10.742 46.4042 5.57275 50.5882" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M158.086 15.4968C157.641 18.1962 157.182 20.8955 156.737 23.5949" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M188.413 23.4832C186.753 26.6009 188.116 29.0573 190.519 30.7849C191.302 31.3517 193.34 30.7849 194.392 30.0965C194.905 29.7591 194.959 27.3972 194.406 26.9518C189.817 23.1862 185.242 19.3531 180.248 16.1814C177.265 14.2918 173.999 16.5458 173.594 20.109C173.108 24.3739 172.703 28.6524 172.231 32.9174C171.61 38.4511 168.384 41.3663 162.122 41.6498C157.182 41.8657 152.228 41.6903 147.289 41.6903" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M226.119 2.00664C223.271 1.87167 222.529 3.81522 221.274 5.9882C216.995 13.3844 214.984 21.5095 213.486 29.702C212.838 33.2246 210.76 33.5621 208.587 33.5216C207.44 33.4946 206.117 31.5916 205.348 30.2689C205.132 29.891 206.535 28.5278 207.237 27.6505C209.383 24.9916 211.03 26.5303 212.622 28.3253" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M239.083 29.6405C239.528 26.0368 240.352 22.4467 240.325 18.843C240.298 15.2529 236.708 11.1903 234.359 11.4603C230.418 11.9057 228.623 14.3756 227.503 18.1412C225.856 23.6479 228.137 28.5472 228.96 33.6895" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M274.819 25.0042C274.374 22.9796 273.753 20.9821 273.497 18.9306C272.849 13.6804 268.962 14.4226 265.372 14.1662C260.891 13.8423 262.335 17.6889 261.458 19.6729C260.796 21.1711 261.836 23.4115 261.242 24.9771C258.812 31.3341 254.453 32.6973 247.165 29.728" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M286.98 12.7974C291.056 14.3495 295.632 14.7274 298.507 18.8304C300.747 22.0291 302.11 25.1469 300.477 28.9935" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M285.631 20.8955C285.86 22.4746 286.36 24.0672 286.238 25.6059C286.09 27.4279 285.415 29.2095 284.956 31.0046" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M332.884 18.1826C332.209 20.2071 331.778 22.3531 330.806 24.2292C329.213 27.2659 327.796 30.7076 325.353 32.8941C321.061 36.7272 316.094 34.2978 313.327 27.6304C311.249 22.5961 311.559 13.1618 314.002 7.39868C318.713 12.8109 318.524 16.9814 313.327 22.92" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M333.544 29.8725C340.171 28.2124 347.689 29.805 353.182 23.8664C356.192 20.6002 357.028 18.1843 353.695 15.8224C349.835 13.096 345.691 9.85679 340.292 12.3267" stroke="black" stroke-width="2.69935" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    ,
    signature: (<svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none">
        <path d="M45.1492 15.11C40.6292 12.42 36.7292 10.1 32.8292 7.77997C32.2592 8.19997 31.6992 8.62997 31.1292 9.04997C31.8692 14.04 32.3892 19.07 33.3992 24C34.6692 30.19 36.4292 36.28 37.8092 42.44C41.2092 57.62 32.3692 65.4 20.5092 67.66C15.6792 68.58 10.5192 67.81 5.51922 67.81C5.49922 67.37 5.46922 66.93 5.44922 66.48C9.54922 65.96 13.7892 65.93 17.6992 64.78C21.7292 63.6 25.6192 61.62 29.2392 59.45C33.3392 57 34.9392 52.72 34.1592 48.22C32.9192 41.03 30.8592 33.99 29.3892 26.84C28.2892 21.48 27.1492 16.07 26.7692 10.63C26.3492 4.64997 28.7092 2.67997 34.2792 4.58997C37.8692 5.81997 41.0692 8.41997 44.1092 10.83C45.0392 11.56 44.8492 13.7 45.1492 15.1V15.11Z" fill="white" />
        <path d="M25.6494 55.1899C25.2894 54.8999 24.8194 54.6699 24.5994 54.2999C18.2394 43.7999 11.8194 33.3299 5.62944 22.7399C3.62944 19.3199 1.83944 15.6699 0.729438 11.8999C0.0894381 9.7299 0.999438 7.0999 1.20944 4.6699C1.73944 4.5799 2.25944 4.4899 2.78944 4.3999C3.65944 5.4799 5.38944 6.6699 5.25944 7.6099C4.04944 16.4699 10.6994 21.9699 14.0894 28.8199C17.8294 36.3999 21.8894 43.8199 25.7394 51.3499C26.2894 52.4199 26.3994 53.7099 26.7094 54.8899L25.6494 55.1899Z" fill="white" />
        <path d="M53.2495 19.72C53.4695 15.34 53.5495 11.78 53.8795 8.24C54.0095 6.91 54.7195 5.64 55.1695 4.34C56.1795 5.15 57.2795 5.88 58.1895 6.79C59.5395 8.14 60.7495 9.63 62.0195 11.06C62.4795 10.97 62.9395 10.87 63.3895 10.78C63.4995 9.04 63.6495 7.3 63.7095 5.56C63.7595 3.92 63.7195 2.27 63.7195 0C67.9695 2.78 69.4795 8.3 67.8595 12.23C66.5095 15.49 64.9795 15.93 59.6295 14.62C56.9095 20.56 55.5595 21.32 53.2495 19.71V19.72Z" fill="white" />
    </svg>),
    mobileSignature: (<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.6988 6.66721C17.7044 5.48028 15.9836 4.45661 14.2627 3.43294C14.0112 3.61825 13.7641 3.80799 13.5126 3.99331C13.8391 6.19508 14.0686 8.41451 14.5142 10.5898C15.0746 13.3211 15.8512 16.0082 16.4601 18.7262C17.9603 25.4242 14.0598 28.857 8.82668 29.8542C6.6955 30.2602 4.41872 29.9204 2.21253 29.9204C2.2037 29.7263 2.19047 29.5321 2.18164 29.3336C3.99071 29.1041 5.86156 29.0909 7.5868 28.5835C9.36499 28.0628 11.0814 27.1892 12.6787 26.2317C14.4878 25.1507 15.1937 23.2622 14.8496 21.2766C14.3024 18.1041 13.3935 14.9978 12.7449 11.8429C12.2595 9.47789 11.7565 7.0908 11.5888 4.69046C11.4035 2.05186 12.4448 1.18262 14.9025 2.02539C16.4866 2.56811 17.8985 3.71533 19.2399 4.77871C19.6502 5.10081 19.5664 6.04506 19.6988 6.66279V6.66721Z" fill="white" />
        <path d="M11.0947 24.3519C10.9359 24.2239 10.7285 24.1224 10.6314 23.9592C7.82515 19.3262 4.99241 14.7064 2.26115 10.0337C1.37867 8.52467 0.588856 6.91415 0.0990823 5.25069C-0.18331 4.2932 0.218216 3.13275 0.310876 2.06054C0.544732 2.02083 0.774176 1.98112 1.00803 1.94141C1.39191 2.41794 2.15525 2.94302 2.09789 3.35778C1.56399 7.26714 4.49822 9.69395 5.99402 12.7164C7.64425 16.061 9.43567 19.335 11.1344 22.6575C11.3771 23.1296 11.4257 23.6988 11.5624 24.2195L11.0947 24.3519Z" fill="white" />
        <path d="M23.2729 8.70121C23.37 6.76859 23.4053 5.19778 23.5509 3.6358C23.6083 3.04895 23.9216 2.48858 24.1201 1.91497C24.5658 2.27237 25.0511 2.59448 25.4527 2.996C26.0483 3.59167 26.5822 4.24912 27.1426 4.88009C27.3456 4.84038 27.5485 4.79625 27.7471 4.75654C27.7956 3.98879 27.8618 3.22104 27.8883 2.45328C27.9104 1.72965 27.8927 1.00161 27.8927 0C29.768 1.22664 30.4342 3.66227 29.7194 5.39634C29.1238 6.83477 28.4487 7.02892 26.088 6.45089C24.8879 9.07185 24.2922 9.40719 23.2729 8.69679V8.70121Z" fill="white" />
    </svg>
    ),
    slides: {
        earth: [
            { imagePath: image1, description: 'מתן נוהג ברכב', width: '787.968', height: '577.483' },
            { imagePath: image2, description: '', width: '415.74', height: '313.573' },
            { imagePath: image3, description: '', width: '413.654', height: '260' },
            { imagePath: image4, description: '', width: '438.732', height: '225' },
            { imagePath: elementsMobileImage, description: '', width: '427.211', height: '515' }
        ],
        water: [
            { imagePath: image5, description: 'מתן בצופים', width: '438.732', height: '260' },
            { imagePath: image6, description: '', width: '335.654', height: '514' },
            { imagePath: image7, description: '', width: '280', height: '515' }
        ],
        fire: [
            { imagePath: image8, description: '', width: '456', height: '515' },
            { imagePath: image9, description: '', width: '455.619', height: '261.529' },
            { imagePath: image10, description: '', width: '415.739', height: '261.529' },

        ],
        air: [
            { imagePath: image11, description: '', width: '427.211', height: '515' },
            { imagePath: image12, description: '', width: '347.211', height: '515' },
            { imagePath: image13, description: '', width: '347.211', height: '515' },
            { imagePath: image14, description: '', width: '347.211', height: '515' },
            { imagePath: image15, description: '', width: '347.211', height: '515' }
        ]

    },
    carousle: [
        { img: cImage1 },
        { img: cImage2 },
        { img: cImage3, margin: -149 },
        { img: cImage4 },
        { img: cImage5, margin: -299 },

        { img: cImage6 },
        { img: cImage7, margin: -153 },
        { img: cImage8 },
        { img: cImage9, margin: -293 },
        { img: cImage12 },
        { img: cImage13, margin: -445 },
        { img: cImage15 },
        { img: cImage14, margin: -445 },
        { img: cImage17 },
        { img: cImage16, margin: -445 },
        { img: cImage19 },
        { img: cImage18, margin: -579 },
        { img: cImage21 },
        { img: cImage20, margin: -579 },
        { img: cImage23 },
        { img: cImage22, margin: -579 },
        { img: cImage25 },
        { img: cImage24, margin: -312 }
    ],
    mobileSwipper: [
        { img: cMobileImage0 },
        { img: cMobileImage1 },
        { img: cMobileImage2 },
        { img: cMobileImage3 },
        { img: cMobileImage4 },
        { img: cMobileImage5 },
        { img: cMobileImage6 },
        { img: cMobileImage7 },
        { img: cMobileImage8 },
        { img: cMobileImage9 },
        { img: cMobileImage10 },
        { img: cMobileImage11 },
        { img: cMobileImage12 },
        { img: cMobileImage13 },
        { img: cMobileImage14 },
        { img: cMobileImage15 },
        { img: cMobileImage16 },
        { img: cMobileImage17 },
        { img: cMobileImage18 },
        { img: cMobileImage19 },
        { img: cMobileImage20 },
        { img: cMobileImage21 }
    ]
}