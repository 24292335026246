import EmptyRow from "../common/empty-row/empty-row"
export const LivnatKozTexst = {
    dates: '7.10.2023-25.10.1973',
    main: `ליבנת, יזמית ואשת חינוך שרצתה ליצור חינוך אחר, עם הידיים, הקימה את 'ביתמלאכה' שער הנגב. 
    אל 'ביתמלאכה' הגיעו כל תושבי האזור. 

    מַסָּע מֻצְלָח לֹא מִסְתַּיֵּם לְעוֹלָם,
     הַקִּילוֹמֶטְרִים כֵּן.
    הוּא נִשְׁאַר חָקוּק בְּתוֹכְךָ
    הוֹפֵךְ חֵלֶק מִמְּךָ
    בְּסוֹף הַמַּסָּע אֵינֶנִּי מַרְגִּישׁ מְנַצֵּחַ
    אֶלָּא יוֹתֵר אֲסִיר תּוֹדָה
    כְּאִלּוּ שֶׁהַדֶּרֶךְ עָבְרָה בְּתוֹכִי
    וְלֹא אֲנִי בְּתוֹכָהּ.`,
    slides: {
        earth: {
            mainShortText: <>
                <big>"כשהתחלתי ללכת, לא ידעתי לאן אגיע"</big>
                <EmptyRow height={15} />
                {`משפחת קוץ הי"ד – ליבנת (כמעט בת 50), אביב (53) וילדיהם רותם (18), יונתן (16) ויפתח (14) – התגוררה כ-19 שנים בכפר עזה.
משפחה מלאה בצחוק, תקווה, עשייה, מלאכה ומעורבות קהילתית.
כל אחד – עולם ומלואו.`}
            </>,
            mainText: <>{`חוסר אמון במערכת החינוך הפורמלית ליווה את ליבנת שנים רבות. חוויית הלמידה שלה כילדה הייתה רצופת כישלונות וחוסר אמון עצמי. היא למדה פעמיים בכיתה ו', ורק בהמשך החיים הבוגרים גילתה שסבלה מלקות למידה. סיפרה תמיד שהפעם היחידה שחוותה הצלחה ותחושת משמעות הייתה בשיעור מלאכה, כשהמורה ביקשה ממנה לתפור כרית. היא תפרה בשקיקה ובהתרגשות והרגישה סוף סוף שהיא מאמינה בעצמה.`}
                <iframe src="https://www.youtube.com/embed/IQZmUu0XV4I?si=5gC-9jamfDP_s1HK" style={{ border: 'none', width: "560px", height: "315px", margin: '65px 59px 5px 0' }}
                    title='ליבנת קוץ הי"ד' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                </iframe>
            </>,
            mainTextMobile: <>{`חוסר אמון במערכת החינוך הפורמלית ליווה את ליבנת שנים רבות. חוויית הלמידה שלה כילדה הייתה רצופת כישלונות וחוסר אמון עצמי. היא למדה פעמיים בכיתה ו', ורק בהמשך החיים הבוגרים גילתה שסבלה מלקות למידה. סיפרה תמיד שהפעם היחידה שחוותה הצלחה ותחושת משמעות הייתה בשיעור מלאכה, כשהמורה ביקשה ממנה לתפור כרית. היא תפרה בשקיקה ובהתרגשות והרגישה סוף סוף שהיא מאמינה בעצמה.`}
                <iframe src="https://www.youtube.com/embed/IQZmUu0XV4I?si=5gC-9jamfDP_s1HK" 
                    style={{ border: 'none', width: '95%',  height: '196px', margin: '30px 17px 0 0', borderRadius:'18px'}}
                    title='ליבנת קוץ הי"ד' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                </iframe>
            </>,
            avivMobile:<><b>אביב</b>
            {`, איש רוח ועבודה, איש משפחה ואב חם, 
            אוהב ודואג.`}
            <EmptyRow></EmptyRow>
            {`אביב עבד כסמנכ״ל חטיבת הסמיקונדוקטור בחברת AVIV ובשעות הפנאי התפנה 
            ליצירה ולמלאכה.`}
            <EmptyRow></EmptyRow>
            {`הוא ריתך ויצר פסלים מרהיבים, יזם את "העפיפוניאדה" השנתית בקיבוץ כפר עזה וארגן אותה מדי שנה. כינס את הקהילה כולה ליום אחד ומיוחד שעפיפונים הועפו בו לשמיים לסימן של תקווה ושלום.`}<EmptyRow></EmptyRow>
            {`"העפיפונידאה" הייתה אמורה להתקיים בשבת, שמחת תורה, 7.10.2023.`}</>,
            imagesTexts: [
                <><b>יפתח</b>
                    {`, הצעיר בילדים, התחיל רק בספטמבר האחרון את דרכו בקבוצת הנוער בכדורסל של הפועל תל אביב, ושאף ללכת בעקבות אחיו לפנימיית הכפר הירוק.
                    ילד חכם וביישן, חיוך תמידי על פניו, השרה בדרכו הצנועה שמחת חיים על כל סובביו.`}</>
                , <><b>יונתן</b>
                    {`, כדורסלן מצטיין בקבוצת הנוער של הפועל
    תל אביב, למד בפנימיית הכפר הירוק כדי
    להגשים את חלומו ולשחק בנבחרות המובילות
    בארץ ובעולם.
    רגישות גדולה אפיינה את דמותו, ושמחה אין־סופית
    הציפה את סביבתו, מלווה בצחוקו המתגלגל.`}</>
                , <><b>רותם</b>
                    {`, ילדת הביכורים, שירתה כמפקדת טירונים בבסיס ההדרכה (בה"ד 7) וטיפלה במסירות ובאהבה בפעוטות ובילדים בכפר עזה. בדרכה היצירתית תפרה, אפתה וחלמה לטייל בכל העולם.`}</>
                , <><b>אביב</b>
                    {`, איש רוח ועבודה, איש משפחה ואב חם, אוהב ודואג.`}
                    <EmptyRow></EmptyRow>
                    {`אביב עבד כסמנכ״ל חטיבת הסמיקונדוקטור בחברת AVIV ובשעות הפנאי התפנה ליצירה ולמלאכה.`}
                    <EmptyRow></EmptyRow>
                    {`הוא ריתך ויצר פסלים מרהיבים, יזם את "העפיפוניאדה" השנתית בקיבוץ כפר עזה וארגן אותה מדי שנה. כינס את הקהילה כולה ליום אחד ומיוחד שעפיפונים הועפו בו לשמיים לסימן של תקווה ושלום.`}<EmptyRow></EmptyRow>
                    {`"העפיפונידאה" הייתה אמורה להתקיים בשבת, שמחת תורה, 7.10.2023.`}</>
            ]
        }
        ,
        water: {
            mainShortText: `ליבנת עשתה הכול בדרך שלה. היא ידעה ליצור יש מאין – לנצל כל משאב או כל פסולת, ולברוא יצירה ואומנות. היא רדפה אחרי עוד ועוד פרויקטים והמשיכה לנסות לגעת בעוד קהלי יעד (קשישים, נוער בסיכון, ילדים, חברה ערבית). תוך כדי עשייה הבינה ליבנת שביתמלאכה אינו חייב להיות תָחום רק במבנה שלו, אלא יכול להתקיים ממש בתוך הקהילה. כך הפכה התורה של "PLACE MAKING" לאחת מאבני היסוד של העשייה החינוכית והקהילתית שלה.`,
            mainText: <>{<b style={{ fontSize: '24px;' }}>איך השפיעה הנוכחות שלה על אנשים?</b>}{`
            ליבנת הייתה אדם של אנשים. היא ו`}<b>ביתמלאכה</b>{` היו מילים נרדפות. היא סחפה אחריה בהתלהבות צוות נאמן ואוהב שהיה מוכן לעמוד לצידה בכל הרפתקה.`}
                <EmptyRow />
                <b>ביתמלאכה</b>{` אסף סביבו אנשים רבים ומגוונים, והכול בזכות האופי המיוחד של ליבנת. בזכות הנועם, הקלילות והצחוק.
            
            היא הכירה כל מי שנכנס בדלת, ילד כמבוגר. הכירה בשמו וידעה מי אחיו ומי הוריו. עם כולם דיברה בגובה העיניים ופתחה בפניהם את הדלת, את הלב ואת המקרר.`}
                <EmptyRow />
                {`מ`}<b>ביתמלאכה</b>{` לא יצאת רעב, עצוב או בהרגשת חוסר משמעות.`}
                <EmptyRow />
                {`ליבנת נלחמה בטחנות רוח ולא התייאשה גם כשהתקציב היה דל והפרויקט היה בהפסד... היא ביססה את פרויקט חייה שנה אחרי שנה, שיכללה אותו, למדה עוד מתודה ועוד טכניקה והעבירה אותן הלאה, אל הילדים והמבוגרים שבאו אל "הבית" שלה. שאפה תמיד להמשיך, לחדש ולשנות. לא נשארה במקום…`}
                <EmptyRow />
                {`ליבנת לימדה אותנו התמדה, עקשנות, ערכיות, יצירתיות. ועוד הרבה כל כך.`}
                <EmptyRow />
                {`היא חלשה על `}<b>ביתמלאכה</b>{` כמנהלת וכאחרונת הפועלים, מוּנעת מדאגה אמיתית ואמהית לצוות ולמודרכים ומחתירה לשחרור מתחים. כדי להשיג זאת הייתה משנה את סדר החומרים ואת פינות העבודה בן לילה. הכול לצורך העניין.`}
                <EmptyRow />
                {`בכל פעם שנדרשה למצוא פתרון לחוסר מקום ולחוסר אמצעים, וגם כשאילוצים שונים הרסו את מעשה ידיה היא שבה והקימה הכול מחדש. כמו עוף החול. ללא לאות.
            `}</>,
            nMobile: `נ', מאחד הקיבוצים, פרשה מתחום הבניין והתקבלה למלא את מקומה של מדריכה 
            שיצאה להשתלמות.
             נ' מספרת שהתאהבה בליבנת מהרגע הראשון והתרגשה עד דמעות כבר בריאיון העבודה איתה שהתקיים על שרפרפים בפינת הקדרות.\n ליבנת קידמה אותה, ראתה בה את הפוטנציאל ונתנה לה יד חופשית לחלום, להשתלם בתחומים שונים, ואף תמכה ביציאתה של נ' ללימודי אומנות. כיום נ' מקיימת חוגי יצירה וסדנאות ברוחה של ליבנת במסגרות שונות לבני כל הגילים, מילדי בית הספר היסודי ועד לבני הגיל השלישי, ופועלת כאומנית מציגה בקהילה.`
       ,
            imagesTexts: [
                `י', תושבת מקיבוצי האזור, התמודדה עם קושי נפשי במשך הרבה שנים. לא הצליחה להתמיד במקום עבודה וחשה בדידות.\n ליבנת הציעה לה לבוא להתנדב בביתמלאכה באופן קבוע. י' קיבלה את ההצעה והתחילה להתנדב יומיים בשבוע, ולפעמים אפילו יותר. היא הרגישה משמעותית וחשובה, הפכה לחלק בלתי נפרד מהצוות והצליחה להתמיד לאורך שנים. הכול בזכות הרגישות, החמלה והרצון לעזור של ליבנת.`
                , `נ', מאחד הקיבוצים, פרשה מתחום הבניין והתקבלה למלא את מקומה של מדריכה שיצאה להשתלמות.
                \n נ' מספרת שהתאהבה בליבנת מהרגע הראשון והתרגשה עד דמעות כבר בריאיון העבודה איתה שהתקיים על שרפרפים בפינת הקדרות.\n ליבנת קידמה אותה, ראתה בה את הפוטנציאל ונתנה לה יד חופשית לחלום, להשתלם בתחומים שונים, ואף תמכה ביציאתה של נ' ללימודי אומנות. כיום נ' מקיימת חוגי יצירה וסדנאות ברוחה של ליבנת במסגרות שונות לבני כל הגילים, מילדי בית הספר היסודי ועד לבני הגיל השלישי, ופועלת כאומנית מציגה בקהילה.`
            ]
        },
        fire: {
            mainShortText: `ליבנת אהבה ללמוד דברים חדשים, לראות תערוכות מיוחדות (זומו בעיקר), לצרוך תרבות, ובעיקר אהבה ליצור יש מאַין ולהציב לעצמה אתגרים חדשים כל הזמן.`,
            mainText: <><b style={{ fontSize: '24px;' }}>איך הכול התחיל?</b>{`
        לפני כעשר שנים, במהלך הפינוי של כפר עזה לניר העמק במבצע צוק איתן, התנדבה ליבנת להפעיל שם את הילדים בסדנת בנייה בעץ. התוצרים של הסדנה הזו – מטוסים, טנקים ופצצות – הבהירו לה שדרוש שהילדים יוכלו לבטא בו את עצמם מהמקום שהמילים נגמרות, באמצעות הידיים.`}
                <EmptyRow />
                {`בשובה הביתה בתום המבצע חוותה משבר והתקף חרדה שלאחריו הבינה שהיא חייבת לשנות את מסלול חייה.`}
                <EmptyRow />
                {`היא החליטה להתפטר מעבודתה כמעצבת גרפית באינטל, ופנתה לארגון `}<b>PresenTense</b>{` – ארגון שחרט על דגלו הנגשת יזמות טכנולוגית ו/או חברתית־קהילתית לאוכלוסיות בפריפריה.`}
                <EmptyRow />
                {`אנשי הארגון סייעו לה בהכוונה ובייעוץ, ויחד איתם, בתהליך אמיץ של הסתכלות פנימה ודיוק עצמי, הבינה שהיא רוצה להוביל לריפוי את אנשי הקהילה הפגועה שסביבה – מקטן ועד גדול, דרך יצירה, עבודות יד ומלאכות בסיסיות וקדומות.

        התהליך שעברה הוביל אותה לגיבוש את הרעיון והמיזם `}<b>ביתמלאכה</b>{` – בית לידיים יוצרות.`}
                <EmptyRow />
                {`בהתלהבות ובנחישות הצליחה ליבנת לשכנע את ראש המועצה, מנהל המתנ"ס והנהלת בית הספר היסודי בנחיצות ובחשיבות הפרויקט. היא קיבלה מבנה בלב בית הספר והקימה בו יש מאין את הבית שחלמה עליו.`}
                <EmptyRow />
                {`אט־אט הצליחה לשכנע את המערכת ולשלב ביקורים ב`}<b>ביתמלאכה</b>{` בתוכנית הלימודים. במרוצת הזמן הוסדרו ביקורים אלה הפכו מוסדרים וחלק מהתכנית השנתית, כל כיתה על פי הצרכים הלימודיים שלה ובתיאום עם המורות.`}
                <EmptyRow />
                {`לדוגמה, כשלמדו ילדי ב' על תיבת נוח הגיעו ל`}<b>ביתמלאכה</b>{` כדי ליצור דגמים תלת־ממדיים של התיבה.`}
                <EmptyRow />
                {`בעת לימוד האלף־בית יצרו ילדי א' בבית מלאכה דגמים של האותיות בחימר, בעץ, בבד וכד'. 
        `}</>,
            imagesTexts: [
                `ליבנת יזמה גם חוגים לאחר שעות הלימוד כמו קרמיקה ונגרות, מפגשי יצירה של הורה־ילד, קיימה ביישובים מפגשי יצירה נקודתיים סביב נושא או צורך שעלה במערכות החינוך בקהילה, סדנאות וחוגי יצירה ובישול לבוגרים ואף לפנסיונרים, הרצאות ומפגשי תרבות אינטימיים על מרפסת בית המלאכה, כמו הערב האחרון שהתקיים שבועות ספורים לפני השבעה באוקטובר, ערב ספוקן ווארד מרתק ונעים של יוצרים מקומיים.`
                , `אביב, האיש שאיתה, נתן לה תחושת שייכות ורוח גבית. הוא חיזק אצלה את האמון העצמי וביכולת שלה ליזום, להצליח ולעוף עם החלומות שלה. 
            הייתה לו סבלנות אין־סופית, יכולת הכלה, ואף למעלה מזה. הוא סייע לה באופן פעיל בכל רעיון שהעלתה, ואף יזם והביא בעצמו רעיונות שקידמו את ביתמלאכה, כמו למשל סדרת הרצאות עם צלמים מהשורה הראשונה שהוא יזם וארגן בשנה הראשונה של הפעילות. המיזם נועד לחשוף את המקום לציבור בשער הנגב. אביב האמין בליבנת, העריך אותה ודאג לה.
            בהמשך הבשילה בליבנת ההחלטה להשיג תואר ותעודת הוראה והיא יצאה ללימודים במכללת קיי. היא סיימה בגאווה את מסלול "שבילים", במקביל לעבודה ולניהול של המיזם הפעיל שלה. הייתה לה מורה אחת במכללת קיי – המורה למלאכה – שגרמה לה להאמין בעצמה ולהצליח. `
                , <>
                    <b>הרצון ליצור שינוי</b>{`
            כשפתחה את ביתמלאכה לא ציפתה ליבנת לשינוי מרחיק לכת.
            בסך הכול רצתה להעניק לילדים הצלחות קטנות שיבנו את החוסן ואת תחושת המסוגלות ויעצימו את הביטחון בעצמם. 
            היא חלמה שילדים יגיעו לביתמלאכה ו"רק" ישייפו קוביות עץ. זה הספיק לה.  החזון שלה היה לשנות את התפיסה הקונבנציונאלית שילד חייב ללמוד BY THE BOOK.
            ליבנת האמינה שיש מגוון מלאכות שיכולות להעניק לילדים ריפוי ושאפשר ללמד ילדים בדרכים יצירתיות על כישורי חיים. לא רק בלמידה תאורטית. 
            היא רצתה לתת בית לילדים שלא מוצאים את עצמם בכיתה ומסתובבים ברחבי בית הספר. היא נהגה להמציא פרויקטים שהיו תפורים לילדים ולנערים מסוימים, כדי לגרות אותם לעשייה. בעקבות העשייה תגיע ההצלחה, האמינה ליבנת. ובעקבות ההצלחה – תחושת מסוגלות ואושר.
            `}</>
            ]
        },


        air: {
            mainText: <>{`אחד מהערכים הרבים שליבנת פעלה לפיהם היה שיתוף הקהילה והנעתה לעשייה. ניתן לראות זאת באומנות הקיר שיצרה חודשים ספורים לפני האסון. "כנפיים של בובה", קירות אריחי קרמיקה בכפר עזה ובקריית החינוך, פינות זולה מעץ שבנתה במועדוני הנעורים עם הנוער, שביל במה שעסק ביצירה קהילתית בכל קיבוצי האזור, פרויקט יצירת ספסל בבוסתן עם בני המצווה ועוד ועוד –במסגרת `}
                <b>ביתמלאכה</b>{`, בית הספר, קיבוץ כפר עזה וקיבוצי האזור.

            מִחזור וקהילתיות הובילו בכל תכנון של אירוע או סדנה. ליבנת תמיד ביקשה מהילדים לעבוד לאט "כמו צב". זה היה המוטו שלה. "כי אין לאן למהר, צריך ליהנות מהדרך." מעולם לא עודדה תחרותיות. חשיבות הדרך גדולה מחשיבות התוצר.

            אמנם עבדה בשיתוף פעולה עם בתי הספר היסודיים (והם לעיתים הצרו את צעדיה בשל דרישות כאלה או אחרות של משרד החינוך), אלא שחיפשה תמיד דרכים חדשות ליצירה מלמדת. עודדה את הילדים להקשיב לחברים ולכבד את הזולת. היתה תמיד בכמה מקומות בו־זמנית, מוסחת למיליון כיוונים, וכל מילה הדליקה אצלה רעיון חדש. 
            `}
            </>,
            mainShortText: `ליבנת היתה אשת חינוך מזן שונה, כזה שלא רואים בדרך כלל בבתי הספר "הרגילים". הייתה לה זיקה לשיטות האלטרנטיביות, האנתרופוסופיות למשל, ששמות דגש גדול במלאכות יד, בישול, נגינה ועוד.`,
            imagesTexts: [
                <b>ליבנת היתה רוצה שנעיז, שננסה, בביטחון ובלב שלם. שניתן מעצמנו לאחר, שנתנדב, שנאהב. שביתמלאכה ימשיך בדרך שלה…</b>,
                `תרבות, אומנות ומוזיקה היו מהדברים שניחמו אותה, בעיקר בתקופות עמוסות וקשות. הן נתנו לה פסק זמן ואוויר לנשימה לצד השראה.\n הרצאה טובה או אירוע חתרני "בתדר" היו משרים עליה התרוממות רוח ויצירתיות. 
                מנוחה וחוסר עשייה גרמו לה אי שקט ותחושת בזבוז. המילים "משעמם לי! בוא נעשה משהו!" היו אחד ממאפיניה הבולטים.`,
                `נהגה לומר שהשנים הראשונות בחינוך הילדים הן החשובות ביותר, והיא סמכה על הילדים: "הם ייקחו את מה שלימדנו אותם ויעשו עם זה מה שיראו לנכון!". עוד אמרה שיצירה ואומנות הם כמו"פילאטיס לנשמה".`,
                `ליבנת טענה שכל אחד יכול למצוא משהו שהוא טוב בו, ולכן "אל תפסיקו לנסות". ללמוד, לחקור, לשמוע וללמוד מאנשים אחרים. 
                לא לקבל את הדברים כפשוטם, לערער על המובן מאליו. לשאוף ליותר ולהודות על מה שיש…`,
                `ליבנת היתה צנועת דרך. הייתה מוכנה תמיד ללמוד מכל אחד מיומנות חדשה והקשיבה ליוזמות ולרעיונות של אחרים בסביבתה.
                ליבנת השאירה אחריה כל כך הרבה...
                מורשת רחבת היקף של אמונות, ערכים ומסורות. היא השאירה אחריה בית.`
            ]
        },
    },
    songHeader: 'באת לי פתאום',
    songAuthor: 'קרן פלס ורוני אלטר',
    songLink: '3o08TyqShzw?si=FWFmuXEc1Zzy8T3Q',
    song: [ ],
    padletLink: 'jhux8d5l23l8pjt1'
}