import React from 'react';
import './hover-overlay-image.scss';

const HoverOverlayImage = ({ imagePath, width, height, onClick }) => {

    const style = {
        height: height,
        width: width,
        cursor: onClick ? 'pointer' : 'default',
        background: `url(${imagePath}) lightgray 50% / cover no-repeat`
    }


    return (
        <div style={style} onClick={onClick}
            className={'hover-overlay-image-container'}
        ><div className="hover-overlay"></div>
        </div>
    )
}
export default HoverOverlayImage;



