import { useState, useEffect } from 'react';
import useDeviceDetection from './useDeviceDetection';

const useIsNotFixed = (isDisplayHome = true) => {
  const deviceType = useDeviceDetection();
  
  const [isNotFixed, setIsNotFixed] = useState(false);
  useEffect(() => {
    const STOP_POSITION = isDisplayHome ? 867.6666870117188 : 747.6666870117188;
    const handleFixedAccordingToScroll = () => {

      if (deviceType.isMobile)
        return;

      const footer = document.querySelector('.footer-main-component');
      const footerBottom = footer.getBoundingClientRect().bottom;
      setIsNotFixed(footerBottom <= STOP_POSITION);
    };

    window.addEventListener('scroll', handleFixedAccordingToScroll);
    return () => window.removeEventListener('scroll', handleFixedAccordingToScroll);

  }, []);

  return isNotFixed;
};

export default useIsNotFixed;