import './person-5.scss'
import React from 'react';
import ElementsCarousle from '../common/elements-slides/elements-slides';
import TabHeaderAndExpandableText from '../common/elements-slides/tab-header-and-expandable-text/tab-header-and-expandable-text';
import useDeviceDetection from '../../useDeviceDetection';
import { GiliAdarTexst } from './person-5-texts';
import Swipper from '../common/swipper/swipper';
import { Outlet, useOutletContext } from 'react-router-dom';
import { personsRouts } from '../../models';
import SpeiaEdgeImage from '../common/speia-edge-image/speia-edge-image';
import Padlet from '../common/padlet/padlet';
import { GiliAdarImages } from './person-5-images'
import TextOverlayImage from '../common/elements-slides/text-overlay-image/text-overlay-image';
import EmptyRow from '../common/empty-row/empty-row';



const Person5 = () => {

  //#region elements slides
  const elementSlides = {
    earth: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textWidth='432px'
            text={GiliAdarTexst.slides.earth.mainText} textHeight='99px'
            mainShortText={GiliAdarTexst.slides.earth.mainShortText}
            customMoadlTextStyle={{ width: '744px' }} />
          <TextOverlayImage imagePath={GiliAdarImages.slides.earth[0].imagePath}
            width={'432px'} height={'286px'}
            text={GiliAdarTexst.slides.earth.imagesTexts} textMaxWidth={"229px"}
          />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={GiliAdarImages.slides.earth[1]} />
          <SpeiaEdgeImage image={GiliAdarImages.slides.earth[2]} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={GiliAdarImages.slides.earth[3]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"} textWidth={'466px'} modalWidth={1075}
            text={GiliAdarTexst.slides.water.mainText} textHeight='101px'
            mainShortText={GiliAdarTexst.slides.water.mainShortText}
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }}
          />
          <TextOverlayImage imagePath={GiliAdarImages.slides.water[0].imagePath}
            width={'466px'} height={'248.529px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={GiliAdarImages.slides.water[1].imagePath}
            textMaxWidth={"289px"} width={'388px'} height={'542px'} />
        </div>
        <div className='tab-column'>
          <SpeiaEdgeImage image={GiliAdarImages.slides.water[2]}
            imageCropX={'left'}
            isCorner={true} />
        </div>
      </div>
      )
    },
    fire: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"}
            text={GiliAdarTexst.slides.fire.mainText} textWidth='404px'
            mainShortText={GiliAdarTexst.slides.fire.mainShortText} textHeight='46px'
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '844px' }} />
          <TextOverlayImage imagePath={GiliAdarImages.slides.fire[0].imagePath} width={'466px'} height={'288px'}
          />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={GiliAdarImages.slides.fire[1].imagePath}
            text={GiliAdarTexst.slides.fire.imagesTexts[0]}
            textMaxWidth={"289px"} width={'348px'} height={'542px'} />
        </div>
        <div className='tab-column'>
          <TextOverlayImage imagePath={GiliAdarImages.slides.fire[2].imagePath}
            text={GiliAdarTexst.slides.fire.imagesTexts[1]}
            textMaxWidth={"286px"} width={'348px'} height={'542px'} isCorner={true} />
        </div>
      </div>
      )
    },
    air: () => {
      return (<div className='tab-container' style={{ height: '542px' }}>
        <div className='tab-column'>
          <div className='tab-row'>
            <TabHeaderAndExpandableText header={"רוח"} textWidth='406px'
              text={GiliAdarTexst.slides.air.mainText} textHeight='96px'
              mainShortText={GiliAdarTexst.slides.air.mainShortText}
              customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }} />
            <TextOverlayImage imagePath={GiliAdarImages.slides.air[1].imagePath} width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={GiliAdarImages.slides.air[3].imagePath} width={'366px'} height={'249px'}
            />
          </div>
          <div className='tab-row'>
            <TextOverlayImage imagePath={GiliAdarImages.slides.air[0].imagePath}
              width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={GiliAdarImages.slides.air[2].imagePath} width={'406px'} height={'249px'}
            />
            <TextOverlayImage imagePath={GiliAdarImages.slides.air[4].imagePath} width={'366px'} height={'249px'}
              isCorner={true}
            />
          </div>
        </div>
      </div>
      )
    },

  };

  const mobileElementSlides = {
    earth: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אדמה"} textMobileWidth='326px'
            text={<>
              {GiliAdarTexst.slides.earth.mainShortText}
              <EmptyRow height={20} />
              {GiliAdarTexst.slides.earth.mainText}
            </>
            }
            textHeight='129px'
            isExpanded={isExpanded} setIsExpanded={setIsExpanded} textFullHeight={'1095.1px'} />
          <SpeiaEdgeImage image={GiliAdarImages.slides.earth[2]} isCorner={true} />
        </div>
      </div>
      )
    },
    water: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"מים"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {GiliAdarTexst.slides.water.mainShortText}
              <EmptyRow height={5} />
              {GiliAdarTexst.slides.water.mainTextMobile}
            </>
            } textHeight='123px' textFullHeight={'1320px'}
            customMoadlTextStyle={{ fontSize: '20px', fontWeight: 400, width: '835px' }}
          />
          <SpeiaEdgeImage image={GiliAdarImages.slides.water[0]} isCorner={true} imageCrop={"top"} />
        </div>
      </div>
      )
    },
    fire: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container tab-rows-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"אש"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {GiliAdarTexst.slides.fire.mainShortText}
              <EmptyRow height={5} />
              {GiliAdarTexst.slides.fire.mainTextMobile}
              {GiliAdarTexst.slides.fire.imagesTexts[0]}
            </>}
            textHeight='72px' textFllHeight={'2071.140'} />
          <SpeiaEdgeImage image={GiliAdarImages.slides.fire[0]} isCorner={true} />
        </div>
      </div>
      )
    },
    air: ({ isExpanded, setIsExpanded }) => {
      return (<div className='tab-container'>
        <div className='tab-column'>
          <TabHeaderAndExpandableText header={"רוח"}
            isExpanded={isExpanded} setIsExpanded={setIsExpanded}
            text={<>
              {GiliAdarTexst.slides.air.mainShortText}
              <EmptyRow height={5} />
              {GiliAdarTexst.slides.air.mainText}
            </>
            } textHeight='125px' textFullHeight={'700px'} />

          <SpeiaEdgeImage image={GiliAdarImages.slides.air[4]} isCorner={true} />
        </div>
      </div>
      )
    }

  };
  //#endregion

  const deviceType = useDeviceDetection();
  const [selected, setSelected] = useOutletContext();

  // const [isMobileSongWordsWxpanded, setIsMobileSongWordsExpanded] = useState(false);

  return (
    <>
      <div className={`Gili-adar-component ${(deviceType.isMobile) ? 'Gili-adar-component-mobile' : ''}`}>
        <div className='section-start-01'>
          <div id='start' className='anchor-section-start'>
            <div className='handwriting-img'>
              {deviceType.isMobile ? GiliAdarImages.handWirtingStatmentMobile : GiliAdarImages.handWirtingStatment}
            </div>
            <div className='line'>
              <div> </div>
              <div className='line-style'> </div>
            </div>
            <div className='vertical-lines-container'>
              <div>
                <div className='vertical-line vertical-line-1' ></div>
                <div className='vertical-line vertical-line-2' ></div>
              </div>
              <div className='vertical-line vertical-line-3' ></div>
            </div>
          </div>
          <div className='person-section-1'>
            <img className='big-img' src={GiliAdarImages.bigImage} alt="{personsRouts[4].name.slice(0,-4)}" />
            <div className='text-group'>
              {deviceType.isMobile && <div className='top-right-corner'></div>}
              <div className='description-text-container'>
                <div>
                  <h1> {personsRouts[4].name}</h1>
                  <h3>{GiliAdarTexst.dates}</h3>
                </div>
                <p>{deviceType.isMobile ? GiliAdarTexst.mainTextMobile : GiliAdarTexst.main}</p>
                {deviceType.isMobile && <div className='bottom-left-corner'></div>}
              </div>
            </div>
          </div>
        </div>
        <div className='two-anchors-sections flex-section'>
          <div id="02" className='anchor-section-02 relative-conatainer flex-section'>
            {deviceType.isMobile && <h1  >סיפור אישי</h1>}
            <ElementsCarousle elementSlides={deviceType.isMobile ? mobileElementSlides : elementSlides}></ElementsCarousle>
          </div>
        </div>
        <div id="03" className='anchor-section-03 flex-section'>
          <div>
            <h2 className='section-header'>{GiliAdarTexst.songHeader}</h2>
            <div className='author-name-conatainer'>
              <h4>{GiliAdarTexst.songAuthor}</h4>
              <div className='decoration-line'></div>
              <div className='decoration-line decoration-line-2' style={{ marginRight: '16px' }}></div>
            </div>
          </div>
          <div className='song-container'>
            <iframe style={{
              borderRadius: '21px', border: 'none'
            }}
              title={GiliAdarTexst.song}
              src={`https://www.youtube.com/embed/${GiliAdarTexst.songLink}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}>
            </iframe>
          </div>
          {/* <div className='song-text-container'>
            <div className='song-word-header'><h3>מילות השיר</h3></div>
            <div className={`english-song-words ${isMobileSongWordsWxpanded ? 'english-song-words-expanded3' : ''}`}>
              {GiliAdarTexst.song.map((songColumn, i) =>
                <p key={'col' + i}>{songColumn}</p>
              )}
            </div>
            {deviceType.isMobile && <CommonButton
              width={'100%'}
              fillColor="#000"
              contentColor="#fff"
              borderRadius={'0px 0px 7px 7px'}
              onClick={() => { setIsMobileSongWordsExpanded(!isMobileSongWordsWxpanded) }}
              text={isMobileSongWordsWxpanded ? 'סגירה' : "קרא עוד..."} />}
          </div> */}
        </div>
        <div id="04" className='anchor-section-04 flex-section'>
          <div className='image-background-container'>
            <h2 className='section-header'>שיתופים על {personsRouts[4].name}</h2>
            <div className='decoration-line decoration-line-1'></div>
            <div className='decoration-line decoration-line-2' style={{ width: '909px', marginRight: '287px' }}></div>
            <Padlet padletLink={GiliAdarTexst.padletLink} />
          </div>
        </div>
        <div id="05" className='anchor-section-05 flex-section'>
          <h2 className='section-header'>אלבום תמונות</h2>
          {deviceType.isMobile && <div className='vertical-lines-container'>
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="7" viewBox="0 0 54 7" fill="none">
              <rect width="17.2037" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 17.2041 0.807129)" fill="black" />
              <rect width="12.0426" height="1.29028" transform="matrix(-1 3.17865e-08 3.17865e-08 1 33.5469 0.807129)" fill="black" />
              <rect width="42.5792" height="1.29028" transform="matrix(1 0 0 -1 10.752 6.39795)" fill="black" />
            </svg>
          </div>}
          <div style={{ position: 'relative', maxWidth: !deviceType.isMobile ? '1168px' : '80vw' }}>
            {selected === '05' && <Swipper images={deviceType.isMobile ? GiliAdarImages.mobileSwipper : GiliAdarImages.carousle}
              height={'515px'} margin={'62px'} spaceBetween={20} slidesPerView={deviceType.isXLScreen ? 3.23 : 2.53} />
            }</div>
        </div>
      </div>
      <Outlet></Outlet>
    </>
  );
};
export default Person5;
