const elementsSvg = {
  earth: {
    selected: <svg xmlns="http://www.w3.org/2000/svg" width="127" height="127" viewBox="0 0 127 127" fill="none">
      <g id="Group 31" filter="url(#filter0_i_809_3303)">
        <g id="Vector" filter="url(#filter1_i_809_3303)">
          <path d="M34.4464 115.086C106.768 120.139 90.1157 30.8502 90.1157 30.8502C90.1157 30.8502 -1.13956 30.6742 17.3519 100.775C-0.22941 79.287 -0.757526 47.6825 17.6104 25.4867C38.484 0.26451 75.8491 -3.26 101.071 17.6099C126.293 38.4835 129.818 75.8486 108.948 101.071C90.4303 123.446 58.9344 128.746 34.4501 115.083L34.4464 115.086Z" stroke="#3C7A5F" strokeWidth="7.61693" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <g id="Vector_2" filter="url(#filter2_i_809_3303)">
          <path d="M77.2913 50.7427C77.2913 50.7427 52.1778 103.225 11.0522 114.675" stroke="#3C7A5F" strokeWidth="7.61693" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <filter id="filter0_i_809_3303" x="0.191406" y="0.191406" width="126.175" height="129.174" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3303" />
        </filter>
        <filter id="filter1_i_809_3303" x="0.191406" y="0.191406" width="126.175" height="128.721" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.39662" />
          <feGaussianBlur stdDeviation="1.27373" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3303" />
        </filter>
        <filter id="filter2_i_809_3303" x="7.24268" y="46.9333" width="73.8579" height="74.098" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.39662" />
          <feGaussianBlur stdDeviation="1.27373" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3303" />
        </filter>
      </defs>
    </svg>,
    selectedMobile: <svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none">
    <g filter="url(#filter0_i_809_3398)">
      <g filter="url(#filter1_i_809_3398)">
        <path d="M18.4868 62.1537C57.6493 64.8897 48.632 16.5395 48.632 16.5395C48.632 16.5395 -0.783093 16.4442 9.23013 54.4039C-0.290241 42.7682 -0.576217 25.6542 9.37007 13.6351C20.6732 -0.0227817 40.9065 -1.93132 54.5644 9.36981C68.2223 20.673 70.1309 40.9063 58.8297 54.5642C48.8023 66.6806 31.7472 69.5505 18.4889 62.1517L18.4868 62.1537Z" stroke="#3C7A5F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g filter="url(#filter2_i_809_3398)">
        <path d="M41.6871 27.3115C41.6871 27.3115 28.088 55.7306 5.81836 61.9309" stroke="#3C7A5F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_i_809_3398" x="0" y="0" width="68.1992" height="71.1987" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3398"/>
      </filter>
      <filter id="filter1_i_809_3398" x="0" y="0" width="68.1992" height="71.1987" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3398"/>
      </filter>
      <filter id="filter2_i_809_3398" x="3.81738" y="25.311" width="39.8701" height="41.6201" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3398"/>
      </filter>
    </defs>
  </svg>,
    unselected: <svg width="123" height="123" viewBox="0 0 123 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.4464 113.086C104.768 118.139 88.1157 28.8502 88.1157 28.8502C88.1157 28.8502 -3.13956 28.6742 15.3519 98.7749C-2.22941 77.287 -2.75752 45.6825 15.6104 23.4867C36.484 -1.73549 73.8491 -5.26 99.0712 15.6099C124.293 36.4835 127.818 73.8486 106.948 99.0708C88.4303 121.446 56.9344 126.746 32.4501 113.083L32.4464 113.086Z" stroke="black" strokeWidth="3.37115" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M75.2908 48.7427C75.2908 48.7427 50.1773 101.225 9.05176 112.675" stroke="black" strokeWidth="3.37115" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  },
  water: {
    selected: <svg width="173" height="127" viewBox="0 0 173 127" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_809_3296)">
        <path fillRule="evenodd" clipRule="evenodd" d="M43.8168 40.5577C46.4619 36.9567 50.7326 34.6157 55.5461 34.6157C60.3607 34.6157 64.631 36.9614 67.2753 40.5577L67.2759 40.5585C69.3128 43.3318 72.5926 45.1256 76.2886 45.1256C79.9834 45.1256 83.2638 43.3291 85.3014 40.5585C87.9464 36.957 92.2174 34.6157 97.0312 34.6157C101.846 34.6157 106.116 36.9614 108.76 40.5577L108.761 40.5585C110.798 43.3318 114.078 45.1256 117.774 45.1256C121.469 45.1256 124.749 43.3288 126.787 40.5577C129.432 36.9567 133.703 34.6157 138.516 34.6157C143.331 34.6157 147.601 36.9614 150.246 40.5577L150.246 40.5585C152.283 43.3318 155.563 45.1256 159.259 45.1256C162.954 45.1256 166.235 43.3288 168.272 40.5577C168.824 39.8077 169.879 39.6468 170.629 40.1982C171.379 40.7497 171.54 41.8047 170.988 42.5547C168.344 46.151 164.073 48.4967 159.259 48.4967C154.445 48.4967 150.175 46.1558 147.53 42.5547C145.492 39.7837 142.211 37.9869 138.516 37.9869C134.82 37.9869 131.54 39.7806 129.504 42.554L129.503 42.5547C126.859 46.151 122.588 48.4967 117.774 48.4967C112.96 48.4967 108.69 46.1558 106.044 42.5547C104.007 39.7837 100.726 37.9869 97.0312 37.9869C93.3352 37.9869 90.0553 39.7806 88.0185 42.554L88.0179 42.5547C85.3736 46.151 81.1033 48.4967 76.2886 48.4967C71.4748 48.4967 67.2038 46.1554 64.5588 42.554C62.5212 39.7833 59.2409 37.9869 55.5461 37.9869C51.8501 37.9869 48.5702 39.7806 46.5334 42.554L46.5328 42.5547C43.8885 46.151 39.6182 48.4967 34.8035 48.4967C29.9897 48.4967 25.7187 46.1554 23.0737 42.554C22.5227 41.8037 22.6842 40.7487 23.4345 40.1977C24.1848 39.6466 25.2398 39.8082 25.7908 40.5585C27.8277 43.3318 31.1075 45.1256 34.8035 45.1256C38.4986 45.1256 41.7793 43.3288 43.8168 40.5577Z" fill="#0B537C" stroke="#0B537C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.32714 62.2821C4.97217 58.6806 9.24321 56.3394 14.057 56.3394C18.8708 56.3394 23.1418 58.6806 25.7868 62.2821C27.8236 65.0555 31.1035 66.8492 34.7995 66.8492C38.4943 66.8492 41.7746 65.0527 43.8122 62.2821C46.4573 58.6806 50.7283 56.3394 55.542 56.3394C60.3558 56.3394 64.6268 58.6806 67.2719 62.2821C69.3087 65.0555 72.5886 66.8492 76.2846 66.8492C79.9794 66.8492 83.2597 65.0527 85.2973 62.2821C87.9424 58.6806 92.2134 56.3394 97.0271 56.3394C101.841 56.3394 106.112 58.6806 108.757 62.2821C110.794 65.0555 114.074 66.8492 117.77 66.8492C121.465 66.8492 124.745 65.0524 126.783 62.2813C129.428 58.6803 133.699 56.3394 138.512 56.3394C143.326 56.3394 147.597 58.6806 150.242 62.2821C150.793 63.0324 150.632 64.0874 149.881 64.6384C149.131 65.1894 148.076 65.0279 147.525 64.2776C145.488 61.5042 142.208 59.7105 138.512 59.7105C134.816 59.7105 131.536 61.5042 129.5 64.2776L129.499 64.2784C126.855 67.8747 122.584 70.2204 117.77 70.2204C112.956 70.2204 108.685 67.8791 106.04 64.2776C104.003 61.5042 100.723 59.7105 97.0271 59.7105C93.3311 59.7105 90.0513 61.5042 88.0144 64.2776L88.0139 64.2784C85.3695 67.8747 81.0992 70.2204 76.2846 70.2204C71.4708 70.2204 67.1998 67.8791 64.5548 64.2776C62.5179 61.5042 59.238 59.7105 55.542 59.7105C51.8461 59.7105 48.5662 61.5042 46.5293 64.2776L46.5288 64.2784C43.8845 67.8746 39.6142 70.2204 34.7995 70.2204C29.9858 70.2204 25.7147 67.8791 23.0697 64.2776C21.0328 61.5042 17.753 59.7105 14.057 59.7105C10.361 59.7105 7.08107 61.5042 5.04423 64.2776C4.49319 65.0279 3.43823 65.1894 2.68793 64.6384C1.93763 64.0874 1.7761 63.0324 2.32714 62.2821Z" fill="#0B537C" stroke="#0B537C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M41.2466 84.0023C43.8916 80.4009 48.1626 78.0596 52.9764 78.0596C57.791 78.0596 62.0613 80.4053 64.7057 84.0016L64.7062 84.0023C66.7431 86.7757 70.0229 88.5694 73.7189 88.5694C77.4137 88.5694 80.6941 86.773 82.7317 84.0023C85.3767 80.4009 89.6477 78.0596 94.4615 78.0596C99.2761 78.0596 103.546 80.4053 106.191 84.0016L106.191 84.0023C108.228 86.7757 111.508 88.5694 115.204 88.5694C118.899 88.5694 122.18 86.7726 124.217 84.0016C126.862 80.4005 131.133 78.0596 135.947 78.0596C140.761 78.0596 145.032 80.4053 147.676 84.0016L147.676 84.0023C149.713 86.7757 152.993 88.5694 156.689 88.5694C160.384 88.5694 163.665 86.7726 165.702 84.0016C166.254 83.2516 167.309 83.0906 168.059 83.6421C168.809 84.1936 168.97 85.2486 168.418 85.9986C165.774 89.5949 161.504 91.9406 156.689 91.9406C151.876 91.9406 147.605 89.5996 144.96 85.9986C142.922 83.2275 139.642 81.4307 135.947 81.4307C132.251 81.4307 128.971 83.2245 126.934 85.9978L126.933 85.9986C124.289 89.5949 120.019 91.9406 115.204 91.9406C110.39 91.9406 106.119 89.5993 103.474 85.9978C101.437 83.2272 98.1563 81.4307 94.4615 81.4307C90.7655 81.4307 87.4856 83.2245 85.4488 85.9978L85.4482 85.9986C82.8039 89.5949 78.5336 91.9406 73.7189 91.9406C68.9055 91.9406 64.6348 89.5996 61.9897 85.9986C59.9521 83.2275 56.6715 81.4307 52.9764 81.4307C49.2804 81.4307 46.0005 83.2245 43.9637 85.9978C43.4126 86.7481 42.3577 86.9097 41.6074 86.3586C40.8571 85.8076 40.6955 84.7526 41.2466 84.0023Z" fill="#0B537C" stroke="#0B537C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M142.969 81.8351C135.179 105.484 112.912 122.556 86.6557 122.556C54.9052 122.556 28.9826 97.5924 27.4507 66.2239" stroke="#0B537C" strokeWidth="7.37" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M29.9751 45.8709C37.4099 21.6263 59.9765 4 86.6557 4C109.694 4 129.665 17.1429 139.475 36.3423" stroke="#0B537C" strokeWidth="7.37" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <filter id="filter0_i_809_3296" x="0.5" y="0.314941" width="172.316" height="128.476" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.275" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3296" />
        </filter>
      </defs>
    </svg>,
    selectedMobile: <svg xmlns="http://www.w3.org/2000/svg" width="99" height="72" viewBox="0 0 99 72" fill="none">
    <g filter="url(#filter0_i_809_3389)">
      <g filter="url(#filter1_d_809_3389)">
        <path fillRule="evenodd" clipRule="evenodd" d="M26.643 21.7962C28.0753 19.8462 30.3879 18.5786 32.9944 18.5786C35.6016 18.5786 37.914 19.8488 39.3459 21.7962L39.3462 21.7966C40.4492 23.2984 42.2252 24.2697 44.2266 24.2697C46.2273 24.2697 48.0037 23.2969 49.1071 21.7966C50.5393 19.8464 52.8521 18.5786 55.4588 18.5786C58.0659 18.5786 60.3783 19.8488 61.8102 21.7962L61.8105 21.7966C62.9135 23.2984 64.6896 24.2697 66.6909 24.2697C68.6919 24.2697 70.4683 23.2968 71.5717 21.7962C73.004 19.8462 75.3166 18.5786 77.9231 18.5786C80.5303 18.5786 82.8427 19.8488 84.2746 21.7962L84.2749 21.7966C85.3778 23.2984 87.1539 24.2697 89.1553 24.2697C91.1562 24.2697 92.9327 23.2968 94.036 21.7962C94.3346 21.3901 94.906 21.3029 95.3121 21.6016C95.7182 21.9002 95.8054 22.4715 95.5067 22.8776C94.0748 24.825 91.7624 26.0952 89.1553 26.0952C86.5488 26.0952 84.2362 24.8276 82.8039 22.8776C81.7005 21.3771 79.924 20.4041 77.9231 20.4041C75.9217 20.4041 74.1457 21.3754 73.0427 22.8772L73.0424 22.8776C71.6105 24.825 69.2981 26.0952 66.6909 26.0952C64.0844 26.0952 61.7718 24.8276 60.3395 22.8776C59.2362 21.3771 57.4597 20.4041 55.4588 20.4041C53.4574 20.4041 51.6813 21.3754 50.5784 22.8772L50.5781 22.8776C49.1462 24.825 46.8338 26.0952 44.2266 26.0952C41.6199 26.0952 39.3072 24.8274 37.8749 22.8772C36.7715 21.3769 34.9952 20.4041 32.9944 20.4041C30.9931 20.4041 29.217 21.3754 28.114 22.8772L28.1137 22.8776C26.6818 24.825 24.3694 26.0952 21.7623 26.0952C19.1556 26.0952 16.8428 24.8274 15.4105 22.8772C15.1122 22.4709 15.1996 21.8997 15.6059 21.6013C16.0122 21.3029 16.5835 21.3903 16.8819 21.7966C17.9848 23.2984 19.7609 24.2697 21.7623 24.2697C23.7632 24.2697 25.5397 23.2968 26.643 21.7962Z" fill="#0B537C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.643 21.7962C28.0753 19.8462 30.3879 18.5786 32.9944 18.5786C35.6016 18.5786 37.914 19.8488 39.3459 21.7962L39.3462 21.7966C40.4492 23.2984 42.2252 24.2697 44.2266 24.2697C46.2273 24.2697 48.0037 23.2969 49.1071 21.7966C50.5393 19.8464 52.8521 18.5786 55.4588 18.5786C58.0659 18.5786 60.3783 19.8488 61.8102 21.7962L61.8105 21.7966C62.9135 23.2984 64.6896 24.2697 66.6909 24.2697C68.6919 24.2697 70.4683 23.2968 71.5717 21.7962C73.004 19.8462 75.3166 18.5786 77.9231 18.5786C80.5303 18.5786 82.8427 19.8488 84.2746 21.7962L84.2749 21.7966C85.3778 23.2984 87.1539 24.2697 89.1553 24.2697C91.1562 24.2697 92.9327 23.2968 94.036 21.7962C94.3346 21.3901 94.906 21.3029 95.3121 21.6016C95.7182 21.9002 95.8054 22.4715 95.5067 22.8776C94.0748 24.825 91.7624 26.0952 89.1553 26.0952C86.5488 26.0952 84.2362 24.8276 82.8039 22.8776C81.7005 21.3771 79.924 20.4041 77.9231 20.4041C75.9217 20.4041 74.1457 21.3754 73.0427 22.8772L73.0424 22.8776C71.6105 24.825 69.2981 26.0952 66.6909 26.0952C64.0844 26.0952 61.7718 24.8276 60.3395 22.8776C59.2362 21.3771 57.4597 20.4041 55.4588 20.4041C53.4574 20.4041 51.6813 21.3754 50.5784 22.8772L50.5781 22.8776C49.1462 24.825 46.8338 26.0952 44.2266 26.0952C41.6199 26.0952 39.3072 24.8274 37.8749 22.8772C36.7715 21.3769 34.9952 20.4041 32.9944 20.4041C30.9931 20.4041 29.217 21.3754 28.114 22.8772L28.1137 22.8776C26.6818 24.825 24.3694 26.0952 21.7623 26.0952C19.1556 26.0952 16.8428 24.8274 15.4105 22.8772C15.1122 22.4709 15.1996 21.8997 15.6059 21.6013C16.0122 21.3029 16.5835 21.3903 16.8819 21.7966C17.9848 23.2984 19.7609 24.2697 21.7623 24.2697C23.7632 24.2697 25.5397 23.2968 26.643 21.7962Z" stroke="#0B537C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g filter="url(#filter2_d_809_3389)">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.17715 33.5603C5.60944 31.6101 7.92223 30.3423 10.5289 30.3423C13.1356 30.3423 15.4483 31.6101 16.8806 33.5603C17.9836 35.0621 19.7597 36.0334 21.7611 36.0334C23.7618 36.0334 25.5381 35.0606 26.6415 33.5603C28.0738 31.6101 30.3865 30.3423 32.9932 30.3423C35.5999 30.3423 37.9127 31.6101 39.345 33.5603C40.4479 35.0621 42.224 36.0334 44.2254 36.0334C46.2261 36.0334 48.0025 35.0606 49.1058 33.5603C50.5381 31.6101 52.8509 30.3423 55.4575 30.3423C58.0642 30.3423 60.377 31.6101 61.8093 33.5603C62.9123 35.0621 64.6883 36.0334 66.6897 36.0334C68.6906 36.0334 70.4671 35.0604 71.5705 33.5599C73.0028 31.6099 75.3154 30.3423 77.9219 30.3423C80.5286 30.3423 82.8413 31.6101 84.2736 33.5603C84.572 33.9666 84.4846 34.5379 84.0783 34.8362C83.672 35.1346 83.1007 35.0472 82.8023 34.6409C81.6994 33.1391 79.9233 32.1678 77.9219 32.1678C75.9205 32.1678 74.1444 33.1391 73.0415 34.6409L73.0412 34.6413C71.6093 36.5887 69.2969 37.8589 66.6897 37.8589C64.083 37.8589 61.7703 36.5911 60.338 34.6409C59.235 33.1391 57.4589 32.1678 55.4575 32.1678C53.4562 32.1678 51.6801 33.1391 50.5771 34.6409L50.5768 34.6413C49.1449 36.5887 46.8325 37.8589 44.2254 37.8589C41.6187 37.8589 39.3059 36.5911 37.8737 34.6409C36.7707 33.1391 34.9946 32.1678 32.9932 32.1678C30.9918 32.1678 29.2158 33.1391 28.1128 34.6409L28.1125 34.6413C26.6806 36.5887 24.3682 37.8589 21.7611 37.8589C19.1544 37.8589 16.8416 36.5911 15.4093 34.6409C14.3064 33.1391 12.5303 32.1678 10.5289 32.1678C8.52751 32.1678 6.75142 33.1391 5.64846 34.6409C5.35007 35.0472 4.77881 35.1346 4.37252 34.8362C3.96622 34.5379 3.87876 33.9666 4.17715 33.5603Z" fill="#0B537C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.17715 33.5603C5.60944 31.6101 7.92223 30.3423 10.5289 30.3423C13.1356 30.3423 15.4483 31.6101 16.8806 33.5603C17.9836 35.0621 19.7597 36.0334 21.7611 36.0334C23.7618 36.0334 25.5381 35.0606 26.6415 33.5603C28.0738 31.6101 30.3865 30.3423 32.9932 30.3423C35.5999 30.3423 37.9127 31.6101 39.345 33.5603C40.4479 35.0621 42.224 36.0334 44.2254 36.0334C46.2261 36.0334 48.0025 35.0606 49.1058 33.5603C50.5381 31.6101 52.8509 30.3423 55.4575 30.3423C58.0642 30.3423 60.377 31.6101 61.8093 33.5603C62.9123 35.0621 64.6883 36.0334 66.6897 36.0334C68.6906 36.0334 70.4671 35.0604 71.5705 33.5599C73.0028 31.6099 75.3154 30.3423 77.9219 30.3423C80.5286 30.3423 82.8413 31.6101 84.2736 33.5603C84.572 33.9666 84.4846 34.5379 84.0783 34.8362C83.672 35.1346 83.1007 35.0472 82.8023 34.6409C81.6994 33.1391 79.9233 32.1678 77.9219 32.1678C75.9205 32.1678 74.1444 33.1391 73.0415 34.6409L73.0412 34.6413C71.6093 36.5887 69.2969 37.8589 66.6897 37.8589C64.083 37.8589 61.7703 36.5911 60.338 34.6409C59.235 33.1391 57.4589 32.1678 55.4575 32.1678C53.4562 32.1678 51.6801 33.1391 50.5771 34.6409L50.5768 34.6413C49.1449 36.5887 46.8325 37.8589 44.2254 37.8589C41.6187 37.8589 39.3059 36.5911 37.8737 34.6409C36.7707 33.1391 34.9946 32.1678 32.9932 32.1678C30.9918 32.1678 29.2158 33.1391 28.1128 34.6409L28.1125 34.6413C26.6806 36.5887 24.3682 37.8589 21.7611 37.8589C19.1544 37.8589 16.8416 36.5911 15.4093 34.6409C14.3064 33.1391 12.5303 32.1678 10.5289 32.1678C8.52751 32.1678 6.75142 33.1391 5.64846 34.6409C5.35007 35.0472 4.77881 35.1346 4.37252 34.8362C3.96622 34.5379 3.87876 33.9666 4.17715 33.5603Z" stroke="#0B537C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g filter="url(#filter3_d_809_3389)">
        <path fillRule="evenodd" clipRule="evenodd" d="M25.2523 45.3215C26.6846 43.3713 28.9974 42.1035 31.6041 42.1035C34.2112 42.1035 36.5236 43.3737 37.9555 45.3211L37.9558 45.3215C39.0588 46.8233 40.8348 47.7946 42.8362 47.7946C44.837 47.7946 46.6133 46.8218 47.7167 45.3215C49.149 43.3713 51.4617 42.1035 54.0684 42.1035C56.6756 42.1035 58.9879 43.3737 60.4199 45.3211L60.4202 45.3215C61.5231 46.8233 63.2992 47.7946 65.3006 47.7946C67.3015 47.7946 69.078 46.8217 70.1813 45.3211C71.6136 43.3712 73.9262 42.1035 76.5327 42.1035C79.1399 42.1035 81.4523 43.3737 82.8842 45.3211L82.8845 45.3215C83.9875 46.8233 85.7635 47.7946 87.7649 47.7946C89.7658 47.7946 91.5423 46.8217 92.6457 45.3211C92.9443 44.915 93.5156 44.8278 93.9217 45.1265C94.3278 45.4251 94.415 45.9964 94.1164 46.4025C92.6844 48.3499 90.3721 49.6201 87.7649 49.6201C85.1584 49.6201 82.8458 48.3525 81.4135 46.4025C80.3101 44.902 78.5337 43.929 76.5327 43.929C74.5313 43.929 72.7553 44.9003 71.6523 46.4021L71.652 46.4025C70.2201 48.3499 67.9077 49.6201 65.3006 49.6201C62.6939 49.6201 60.3811 48.3523 58.9488 46.4021C57.8455 44.9018 56.0691 43.929 54.0684 43.929C52.067 43.929 50.291 44.9003 49.188 46.4021L49.1877 46.4025C47.7558 48.3499 45.4434 49.6201 42.8362 49.6201C40.2297 49.6201 37.9171 48.3525 36.4848 46.4025C35.3815 44.902 33.605 43.929 31.6041 43.929C29.6027 43.929 27.8266 44.9003 26.7237 46.4021C26.4253 46.8084 25.854 46.8959 25.4477 46.5975C25.0414 46.2991 24.954 45.7278 25.2523 45.3215Z" fill="#0B537C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.2523 45.3215C26.6846 43.3713 28.9974 42.1035 31.6041 42.1035C34.2112 42.1035 36.5236 43.3737 37.9555 45.3211L37.9558 45.3215C39.0588 46.8233 40.8348 47.7946 42.8362 47.7946C44.837 47.7946 46.6133 46.8218 47.7167 45.3215C49.149 43.3713 51.4617 42.1035 54.0684 42.1035C56.6756 42.1035 58.9879 43.3737 60.4199 45.3211L60.4202 45.3215C61.5231 46.8233 63.2992 47.7946 65.3006 47.7946C67.3015 47.7946 69.078 46.8217 70.1813 45.3211C71.6136 43.3712 73.9262 42.1035 76.5327 42.1035C79.1399 42.1035 81.4523 43.3737 82.8842 45.3211L82.8845 45.3215C83.9875 46.8233 85.7635 47.7946 87.7649 47.7946C89.7658 47.7946 91.5423 46.8217 92.6457 45.3211C92.9443 44.915 93.5156 44.8278 93.9217 45.1265C94.3278 45.4251 94.415 45.9964 94.1164 46.4025C92.6844 48.3499 90.3721 49.6201 87.7649 49.6201C85.1584 49.6201 82.8458 48.3525 81.4135 46.4025C80.3101 44.902 78.5337 43.929 76.5327 43.929C74.5313 43.929 72.7553 44.9003 71.6523 46.4021L71.652 46.4025C70.2201 48.3499 67.9077 49.6201 65.3006 49.6201C62.6939 49.6201 60.3811 48.3523 58.9488 46.4021C57.8455 44.9018 56.0691 43.929 54.0684 43.929C52.067 43.929 50.291 44.9003 49.188 46.4021L49.1877 46.4025C47.7558 48.3499 45.4434 49.6201 42.8362 49.6201C40.2297 49.6201 37.9171 48.3525 36.4848 46.4025C35.3815 44.902 33.605 43.929 31.6041 43.929C29.6027 43.929 27.8266 44.9003 26.7237 46.4021C26.4253 46.8084 25.854 46.8959 25.4477 46.5975C25.0414 46.2991 24.954 45.7278 25.2523 45.3215Z" stroke="#0B537C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g filter="url(#filter4_d_809_3389)">
        <path d="M80.335 44.1479C76.1163 56.9539 64.0587 66.1985 49.841 66.1985C32.648 66.1985 18.6108 52.6805 17.7812 35.6943" stroke="#0B537C" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
      </g>
      <g filter="url(#filter5_d_809_3389)">
        <path d="M19.1484 24.6732C23.1744 11.5447 35.3943 2 49.8412 2C62.3166 2 73.131 9.11695 78.4428 19.5135" stroke="#0B537C" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_i_809_3389" x="3" y="0" width="93.6846" height="71.1982" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3389"/>
      </filter>
      <filter id="filter1_d_809_3389" x="12.0674" y="17.5786" width="86.7832" height="13.8486" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2.16601"/>
        <feGaussianBlur stdDeviation="1.08301"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_809_3389"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_809_3389" result="shape"/>
      </filter>
      <filter id="filter2_d_809_3389" x="0.833985" y="29.3423" width="86.7832" height="13.8486" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2.16601"/>
        <feGaussianBlur stdDeviation="1.08301"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_809_3389"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_809_3389" result="shape"/>
      </filter>
      <filter id="filter3_d_809_3389" x="21.9092" y="41.1035" width="75.5508" height="13.8486" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2.16601"/>
        <feGaussianBlur stdDeviation="1.08301"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_809_3389"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_809_3389" result="shape"/>
      </filter>
      <filter id="filter4_d_809_3389" x="13.942" y="33.6943" width="70.2333" height="38.1825" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.83928"/>
        <feGaussianBlur stdDeviation="0.919642"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_809_3389"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_809_3389" result="shape"/>
      </filter>
      <filter id="filter5_d_809_3389" x="15.3082" y="0" width="66.9745" height="30.3524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.83928"/>
        <feGaussianBlur stdDeviation="0.919642"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_809_3389"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_809_3389" result="shape"/>
      </filter>
    </defs>
  </svg>,
    unselected: <svg width="170" height="123" viewBox="0 0 170 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M42.0346 38.5577C44.6797 34.9567 48.9504 32.6157 53.7638 32.6157C58.5785 32.6157 62.8488 34.9614 65.4931 38.5577L65.4937 38.5585C67.5305 41.3318 70.8104 43.1256 74.5064 43.1256C78.2012 43.1256 81.4816 41.3291 83.5191 38.5585C86.1642 34.957 90.4352 32.6157 95.2489 32.6157C100.064 32.6157 104.334 34.9614 106.978 38.5577L106.979 38.5585C109.016 41.3318 112.296 43.1256 115.991 43.1256C119.687 43.1256 122.967 41.3288 125.005 38.5577C127.65 34.9567 131.921 32.6157 136.734 32.6157C141.549 32.6157 145.819 34.9614 148.463 38.5577L148.464 38.5585C150.501 41.3318 153.781 43.1256 157.477 43.1256C161.172 43.1256 164.452 41.3288 166.49 38.5577C167.041 37.8077 168.096 37.6468 168.846 38.1982C169.596 38.7497 169.757 39.8047 169.206 40.5547C166.562 44.151 162.291 46.4967 157.477 46.4967C152.663 46.4967 148.392 44.1558 145.747 40.5547C143.71 37.7837 140.429 35.9869 136.734 35.9869C133.038 35.9869 129.758 37.7806 127.721 40.554L127.721 40.5547C125.076 44.151 120.806 46.4967 115.991 46.4967C111.178 46.4967 106.907 44.1558 104.262 40.5547C102.225 37.7837 98.9441 35.9869 95.2489 35.9869C91.553 35.9869 88.2731 37.7806 86.2362 40.554L86.2357 40.5547C83.5914 44.151 79.321 46.4967 74.5064 46.4967C69.6926 46.4967 65.4216 44.1554 62.7766 40.554C60.739 37.7833 57.4586 35.9869 53.7638 35.9869C50.0679 35.9869 46.788 37.7806 44.7511 40.554L44.7506 40.5547C42.1063 44.151 37.8359 46.4967 33.0213 46.4967C28.2075 46.4967 23.9365 44.1554 21.2915 40.554C20.7405 39.8037 20.902 38.7487 21.6523 38.1977C22.4026 37.6466 23.4575 37.8082 24.0086 38.5585C26.0454 41.3318 29.3253 43.1256 33.0213 43.1256C36.7164 43.1256 39.9971 41.3288 42.0346 38.5577Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.544915 60.2821C3.18994 56.6806 7.46099 54.3394 12.2748 54.3394C17.0885 54.3394 21.3595 56.6806 24.0046 60.2821C26.0414 63.0555 29.3213 64.8492 33.0173 64.8492C36.7121 64.8492 39.9924 63.0527 42.03 60.2821C44.675 56.6806 48.946 54.3394 53.7598 54.3394C58.5736 54.3394 62.8446 56.6806 65.4896 60.2821C67.5265 63.0555 70.8064 64.8492 74.5024 64.8492C78.1971 64.8492 81.4775 63.0527 83.5151 60.2821C86.1601 56.6806 90.4311 54.3394 95.2449 54.3394C100.059 54.3394 104.33 56.6806 106.975 60.2821C109.012 63.0555 112.291 64.8492 115.987 64.8492C119.683 64.8492 122.963 63.0524 125.001 60.2813C127.646 56.6803 131.917 54.3394 136.73 54.3394C141.544 54.3394 145.815 56.6806 148.46 60.2821C149.011 61.0324 148.849 62.0874 148.099 62.6384C147.349 63.1894 146.294 63.0279 145.743 62.2776C143.706 59.5042 140.426 57.7105 136.73 57.7105C133.034 57.7105 129.754 59.5042 127.717 62.2776L127.717 62.2784C125.072 65.8747 120.802 68.2204 115.987 68.2204C111.174 68.2204 106.903 65.8791 104.258 62.2776C102.221 59.5042 98.9409 57.7105 95.2449 57.7105C91.5489 57.7105 88.269 59.5042 86.2322 62.2776L86.2316 62.2784C83.5873 65.8747 79.317 68.2204 74.5024 68.2204C69.6886 68.2204 65.4176 65.8791 62.7726 62.2776C60.7357 59.5042 57.4558 57.7105 53.7598 57.7105C50.0638 57.7105 46.7839 59.5042 44.7471 62.2776L44.7465 62.2784C42.1022 65.8746 37.832 68.2204 33.0173 68.2204C28.2035 68.2204 23.9325 65.8791 21.2875 62.2776C19.2506 59.5042 15.9707 57.7105 12.2748 57.7105C8.57876 57.7105 5.29885 59.5042 3.262 62.2776C2.71096 63.0279 1.65601 63.1894 0.905704 62.6384C0.155401 62.0874 -0.0061298 61.0324 0.544915 60.2821Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M39.4643 82.0023C42.1094 78.4009 46.3804 76.0596 51.1941 76.0596C56.0088 76.0596 60.2791 78.4053 62.9234 82.0016L62.924 82.0023C64.9608 84.7757 68.2407 86.5694 71.9367 86.5694C75.6315 86.5694 78.9119 84.773 80.9495 82.0023C83.5945 78.4009 87.8655 76.0596 92.6793 76.0596C97.4939 76.0596 101.764 78.4053 104.409 82.0016L104.409 82.0023C106.446 84.7757 109.726 86.5694 113.422 86.5694C117.117 86.5694 120.398 84.7726 122.435 82.0016C125.08 78.4005 129.351 76.0596 134.164 76.0596C138.979 76.0596 143.249 78.4053 145.894 82.0016L145.894 82.0023C147.931 84.7757 151.211 86.5694 154.907 86.5694C158.602 86.5694 161.883 84.7726 163.92 82.0016C164.472 81.2516 165.527 81.0906 166.277 81.6421C167.027 82.1936 167.188 83.2486 166.636 83.9986C163.992 87.5949 159.722 89.9406 154.907 89.9406C150.093 89.9406 145.823 87.5996 143.178 83.9986C141.14 81.2275 137.859 79.4307 134.164 79.4307C130.468 79.4307 127.188 81.2245 125.152 83.9978L125.151 83.9986C122.507 87.5949 118.236 89.9406 113.422 89.9406C108.608 89.9406 104.337 87.5993 101.692 83.9978C99.6544 81.2272 96.374 79.4307 92.6793 79.4307C88.9833 79.4307 85.7034 81.2245 83.6665 83.9978L83.666 83.9986C81.0217 87.5949 76.7513 89.9406 71.9367 89.9406C67.1233 89.9406 62.8525 87.5996 60.2075 83.9986C58.1699 81.2275 54.8893 79.4307 51.1941 79.4307C47.4982 79.4307 44.2183 81.2245 42.1814 83.9978C41.6304 84.7481 40.5754 84.9097 39.8251 84.3586C39.0748 83.8076 38.9133 82.7526 39.4643 82.0023Z" fill="black" />
      <path d="M141.187 79.8351C133.396 103.484 111.129 120.556 84.8735 120.556C53.123 120.556 27.2004 95.5924 25.6685 64.2239" stroke="black" strokeWidth="3.37115" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28.1929 43.8709C35.6277 19.6263 58.1943 2 84.8734 2C107.912 2 127.883 15.1429 137.692 34.3423" stroke="black" strokeWidth="3.37115" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  },
  fire: {
    selected: <svg width="127" height="126" viewBox="0 0 127 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_809_3306)">
        <g filter="url(#filter1_i_809_3306)">
          <path d="M74.67 121.474C75.8386 120.668 91.079 113.908 95.9182 101.349C96.0118 101.117 96.098 100.881 96.1841 100.645C96.2365 100.503 96.2927 100.364 96.3414 100.218C97.0006 98.3827 97.4988 96.4875 97.8359 94.5698C97.8434 94.5398 97.8508 94.5099 97.8546 94.4799C97.8583 94.4574 97.8621 94.435 97.8658 94.4125C97.8808 94.3226 97.8958 94.229 97.9108 94.1353C101.424 74.2617 87.577 55.8227 87.577 55.8227C87.7193 60.0701 85.3372 63.9391 81.4943 66.943C81.4868 28.5704 55.4519 20.63 55.4519 20.63C55.4519 20.63 59.6019 34.3535 41.4212 58.4445C38.2039 62.7181 35.009 67.0179 32.5219 71.7485C27.8363 80.6441 26.3793 91.4723 30.4731 100.862C34.552 110.218 43.1554 116.747 51.9536 121.373L52.0809 121.504C24.6939 116.267 4 92.1914 4 63.2799C4 30.5406 30.5406 4 63.2799 4C96.0193 4 122.56 30.5406 122.56 63.2799C122.56 92.124 101.96 116.155 74.67 121.466" stroke="#881E17" strokeWidth="7.61693" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <g filter="url(#filter2_i_809_3306)">
          <path d="M60.5236 41.7397C65.947 46.298 67.2767 54.3958 65.8085 61.295C64.7185 66.4151 62.2278 71.1231 59.2126 75.3256C54.6881 81.6293 48.0061 88.049 48.0323 96.5363C48.0735 110.676 67.3029 115.23 75.7527 105.923C79.7192 101.555 80.4908 93.8358 76.5917 89.5472C74.9025 87.6932 72.5354 86.6183 70.1083 86.4048C66.4152 86.0789 62.3888 87.9816 60.737 91.581C59.0853 95.1805 60.6097 100.244 64.1417 101.387" stroke="#881E17" strokeWidth="7.61693" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <filter id="filter0_i_809_3306" x="0.191406" y="0.19165" width="126.177" height="128.121" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3306" />
        </filter>
        <filter id="filter1_i_809_3306" x="0.191406" y="0.19165" width="126.177" height="127.668" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.39662" />
          <feGaussianBlur stdDeviation="1.27373" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3306" />
        </filter>
        <filter id="filter2_i_809_3306" x="44.2236" y="37.9312" width="38.7368" height="79.2064" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.39662" />
          <feGaussianBlur stdDeviation="1.27373" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3306" />
        </filter>
      </defs>
    </svg>,
    selectedMobile: <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
    <g filter="url(#filter0_i_809_3383)">
      <g filter="url(#filter1_i_809_3383)">
        <path d="M40.0873 65.312C40.7171 64.878 48.9309 61.2344 51.5389 54.4659C51.5894 54.3408 51.6358 54.2136 51.6823 54.0864C51.7105 54.0097 51.7408 53.935 51.767 53.8563C52.1223 52.8672 52.3908 51.8458 52.5725 50.8122C52.5765 50.7961 52.5805 50.7799 52.5826 50.7638C52.5846 50.7517 52.5866 50.7396 52.5886 50.7274C52.5967 50.679 52.6048 50.6285 52.6128 50.5781C54.5063 39.8673 47.0435 29.9296 47.0435 29.9296C47.1202 32.2187 45.8363 34.304 43.7652 35.9229C43.7612 15.2421 29.7298 10.9627 29.7298 10.9627C29.7298 10.9627 31.9664 18.3589 22.168 31.3426C20.434 33.6459 18.7122 35.9633 17.3718 38.5128C14.8465 43.307 14.0612 49.1428 16.2676 54.2035C18.4659 59.246 23.1027 62.7645 27.8444 65.2575L27.913 65.3281C13.1529 62.5061 2 49.5304 2 33.9487C2 16.3039 16.3039 2 33.9487 2C51.5934 2 65.8974 16.3039 65.8974 33.9487C65.8974 49.4941 54.795 62.4455 40.0873 65.3079" stroke="#881E17" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g filter="url(#filter2_i_809_3383)">
        <path d="M32.4636 22.3394C35.3866 24.796 36.1032 29.1603 35.3119 32.8786C34.7245 35.638 33.3821 38.1754 31.7571 40.4403C29.3186 43.8376 25.7174 47.2976 25.7315 51.8718C25.7537 59.492 36.1173 61.9467 40.6713 56.9304C42.809 54.5767 43.2248 50.4163 41.1235 48.105C40.2131 47.1058 38.9373 46.5264 37.6292 46.4114C35.6389 46.2358 33.4689 47.2612 32.5787 49.2011C31.6884 51.141 32.51 53.8702 34.4136 54.4859" stroke="#881E17" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_i_809_3383" x="0" y="0" width="67.8975" height="70.3281" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3383"/>
      </filter>
      <filter id="filter1_i_809_3383" x="0" y="0" width="67.8975" height="70.3281" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3383"/>
      </filter>
      <filter id="filter2_i_809_3383" x="23.7314" y="20.3394" width="20.7715" height="44.21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3383"/>
      </filter>
    </defs>
  </svg>,
    unselected: < svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <path d="M72.4024 119.474C73.571 118.668 88.8114 111.908 93.6506 99.3491C93.7443 99.1169 93.8304 98.881 93.9165 98.645C93.969 98.5027 94.0252 98.3641 94.0739 98.218C94.7331 96.3827 95.2312 94.4875 95.5683 92.5698C95.5758 92.5398 95.5833 92.5099 95.587 92.4799C95.5908 92.4574 95.5945 92.435 95.5983 92.4125C95.6132 92.3226 95.6282 92.229 95.6432 92.1353C99.1565 72.2617 85.3094 53.8227 85.3094 53.8227C85.4517 58.0701 83.0696 61.9391 79.2267 64.943C79.2192 26.5704 53.1843 18.63 53.1843 18.63C53.1843 18.63 57.3343 32.3535 39.1537 56.4445C35.9363 60.7181 32.7414 65.0179 30.2544 69.7485C25.5688 78.6441 24.1117 89.4723 28.2056 98.8622C32.2844 108.218 40.8878 114.747 49.686 119.373L49.8133 119.504C22.4263 114.267 1.73242 90.1914 1.73242 61.2799C1.73242 28.5406 28.273 2 61.0124 2C93.7517 2 120.292 28.5406 120.292 61.2799C120.292 90.124 99.6921 114.155 72.4024 119.466" stroke="black" strokeWidth="3.37095" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M58.256 39.7397C63.6795 44.298 65.0091 52.3958 63.5409 59.295C62.4509 64.4151 59.9602 69.1231 56.9451 73.3256C52.4205 79.6293 45.7385 86.049 45.7647 94.5363C45.8059 108.676 65.0353 113.23 73.4851 103.923C77.4516 99.5553 78.2232 91.8358 74.3241 87.5472C72.6349 85.6932 70.2678 84.6183 67.8407 84.4048C64.1476 84.0789 60.1212 85.9816 58.4695 89.581C56.8177 93.1805 58.3421 98.2444 61.8741 99.3867" stroke="black" strokeWidth="3.37095" strokeLinecap="round" strokeLinejoin="round" />
    </svg >
  },
  air: {
    selected: <svg width="156" height="127" viewBox="0 0 156 127" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_809_3309)">
          <g filter="url(#filter1_i_809_3309)">
            <path d="M69.4153 40.9346C69.007 39.6948 68.7898 38.3689 68.7898 36.9943C68.7898 30.0165 74.4455 24.3645 81.4196 24.3645C88.3937 24.3645 94.0494 30.0202 94.0494 36.9943C94.0494 43.9684 88.3937 49.6241 81.4196 49.6241H22.1509" stroke="#95673C" strokeWidth="7.61693" strokeMiterlimit="10" strokeLinecap="round" />
          </g>
          <g filter="url(#filter2_i_809_3309)">
            <path d="M101.042 56.4932C100.623 55.2347 100.394 53.8863 100.394 52.4855C100.394 45.5077 106.05 39.8557 113.024 39.8557C119.998 39.8557 125.654 45.5114 125.654 52.4855C125.654 59.4596 119.998 65.1153 113.024 65.1153H4" stroke="#95673C" strokeWidth="7.61693" strokeMiterlimit="10" strokeLinecap="round" />
          </g>
          <g filter="url(#filter3_i_809_3309)">
            <path d="M99.9145 88.1351C99.5886 89.1651 99.4126 90.2626 99.4126 91.4012C99.4126 97.3603 104.244 102.196 110.207 102.196C116.17 102.196 121.002 97.364 121.002 91.4012C121.002 85.4384 116.17 80.6067 110.207 80.6067H16.7383" stroke="#95673C" strokeWidth="7.61693" strokeMiterlimit="10" strokeLinecap="round" />
          </g>
          <g filter="url(#filter4_i_809_3309)">
            <path d="M34.8442 49.6276C41.0093 23.4691 64.5011 4 92.5436 4C125.283 4 151.824 30.5406 151.824 63.2799C151.824 96.0193 125.283 122.56 92.5436 122.56C65.8345 122.56 43.2491 104.896 35.8368 80.6103" stroke="#95673C" strokeWidth="7.61693" strokeMiterlimit="10" strokeLinecap="round" />
          </g>
        </g>
        <defs>
          <filter id="filter0_i_809_3309" x="0.191406" y="0.19165" width="155.44" height="129.177" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3309" />
          </filter>
          <filter id="filter1_i_809_3309" x="18.3423" y="20.5562" width="79.5156" height="35.4239" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="3.39662" />
            <feGaussianBlur stdDeviation="1.27373" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3309" />
          </filter>
          <filter id="filter2_i_809_3309" x="0.191406" y="36.0474" width="129.271" height="35.4239" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="3.39662" />
            <feGaussianBlur stdDeviation="1.27373" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3309" />
          </filter>
          <filter id="filter3_i_809_3309" x="12.9297" y="76.7983" width="111.88" height="31.7533" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="3.39662" />
            <feGaussianBlur stdDeviation="1.27373" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3309" />
          </filter>
          <filter id="filter4_i_809_3309" x="31.0352" y="0.19165" width="124.597" height="128.724" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="3.39662" />
            <feGaussianBlur stdDeviation="1.27373" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3309" />
          </filter>
        </defs>
      </svg>,
      selectedMobile: <svg xmlns="http://www.w3.org/2000/svg" width="85" height="69" viewBox="0 0 85 69" fill="none">
      <g filter="url(#filter0_i_809_3375)">
        <g filter="url(#filter1_i_809_3375)">
          <path d="M37.4229 22.0001C37.2019 21.3288 37.0842 20.6108 37.0842 19.8664C37.0842 16.0879 40.1468 13.0273 43.9233 13.0273C47.6998 13.0273 50.7624 16.0899 50.7624 19.8664C50.7624 23.6429 47.6998 26.7055 43.9233 26.7055H11.8291" stroke="#95673C" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </g>
        <g filter="url(#filter2_i_809_3375)">
          <path d="M54.5486 30.4253C54.3215 29.7438 54.1977 29.0136 54.1977 28.2551C54.1977 24.4766 57.2603 21.416 61.0368 21.416C64.8133 21.416 67.8759 24.4786 67.8759 28.2551C67.8759 32.0316 64.8133 35.0942 61.0368 35.0942H2" stroke="#95673C" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </g>
        <g filter="url(#filter3_i_809_3375)">
          <path d="M53.9377 47.5596C53.7612 48.1173 53.6659 48.7116 53.6659 49.3282C53.6659 52.555 56.2823 55.1735 59.5112 55.1735C62.7401 55.1735 65.3564 52.5571 65.3564 49.3282C65.3564 46.0993 62.7401 43.4829 59.5112 43.4829H8.89746" stroke="#95673C" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </g>
        <g filter="url(#filter4_i_809_3375)">
          <path d="M18.7021 26.7075C22.0406 12.5426 34.7614 2 49.9466 2C67.675 2 82.0469 16.3718 82.0469 34.1003C82.0469 51.8288 67.675 66.2006 49.9466 66.2006C35.4835 66.2006 23.2534 56.6356 19.2396 43.4848" stroke="#95673C" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_i_809_3375" x="0" y="0" width="84.0469" height="70.04" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.83928"/>
          <feGaussianBlur stdDeviation="6.89731"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3375"/>
        </filter>
        <filter id="filter1_i_809_3375" x="9.8291" y="11.0273" width="42.9336" height="20.6782" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3375"/>
        </filter>
        <filter id="filter2_i_809_3375" x="0" y="19.416" width="69.876" height="20.6782" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3375"/>
        </filter>
        <filter id="filter3_i_809_3375" x="6.89746" y="41.4829" width="60.459" height="18.6904" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3375"/>
        </filter>
        <filter id="filter4_i_809_3375" x="16.7021" y="0" width="67.3447" height="71.2007" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_809_3375"/>
        </filter>
      </defs>
    </svg>,
    unselected:<svg width="152" height="123" viewBox="0 0 152 123" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M67.3982 38.9346C66.9899 37.6948 66.7727 36.3689 66.7727 34.9943C66.7727 28.0165 72.4284 22.3645 79.4025 22.3645C86.3766 22.3645 92.0323 28.0202 92.0323 34.9943C92.0323 41.9684 86.3766 47.6241 79.4025 47.6241H20.1338" stroke="black" strokeWidth="3.37095" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M99.0249 54.4932C98.6054 53.2347 98.377 51.8863 98.377 50.4855C98.377 43.5077 104.033 37.8557 111.007 37.8557C117.981 37.8557 123.637 43.5114 123.637 50.4855C123.637 57.4596 117.981 63.1153 111.007 63.1153H1.98291" stroke="black" strokeWidth="3.37095" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M97.8974 86.1354C97.5715 87.1654 97.3955 88.2628 97.3955 89.4015C97.3955 95.3605 102.227 100.196 108.19 100.196C114.153 100.196 118.985 95.3643 118.985 89.4015C118.985 83.4386 114.153 78.6069 108.19 78.6069H14.7212" stroke="black" strokeWidth="3.37095" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M32.8271 47.6279C38.9922 21.4693 62.484 2.00024 90.5265 2.00024C123.266 2.00024 149.806 28.5408 149.806 61.2802C149.806 94.0196 123.266 120.56 90.5265 120.56C63.8174 120.56 41.232 102.896 33.8197 78.6106" stroke="black" strokeWidth="3.37095" strokeMiterlimit="10" strokeLinecap="round" />
      </svg>
  }
}
export default elementsSvg