import './Footer.scss'
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useDeviceDetection from '../../../useDeviceDetection';
import { helpZoomLink, mainZoomLink, personsRouts, zoomBaseUrl } from '../../../models.js';
import logo from '../../../assets/images/footer-logo.svg'
import { useNavigate } from "react-router";
import ConferenceScheduleModalBtn from '../../common/conference-schedule-modal/conference-schedule-modal.jsx';
import headphones from '../../../assets/images/icons/small-headphones.svg'
import CommonButton from '../../common/button/button.jsx';
import zoom from '../../../assets/images/icons/zoom.svg'
import TalkingRoomsModalBtn from '../../common/talking-rooms-modal-btn/talking-rooms-modal-btn.jsx';

const Footer = () => {

  const location = useLocation();
  const deviceType = useDeviceDetection();
  const navigate = useNavigate();

  const [selectedPerson, setSelectedPerson] = React.useState(
    personsRouts?.find(r => location.pathname.includes(r.path)) ?? null
  );

  React.useEffect(() => {
    setSelectedPerson(personsRouts?.find(r => location.pathname.includes(r.path)));
  }, [location]);

  const homeLoge = <img src={logo} className='footer-logo' alt='סיפור הרוח' onClick={() => { navigate('/Home'); resetScroling(); }} />;
  const resetScroling = () => document.body.scrollTop = document.documentElement.scrollTop = 0;

  return (
    <div className={`footer-main-component ${deviceType.isMobile ? 'footer-main-component-mobile' : ''}`}>

      <div className='footer-content-container'>
        <div className='footer-first-row'>
          {!deviceType.isMobile && homeLoge}
          <div className='footer-persons-names'>
            {personsRouts.map((p, i) =>
              <Link key={`l${i}`} to={p.path}  className={`${ p.name === selectedPerson?.name? 'selected-footer-person': ''}`}
              style={{ width: deviceType.isMobile ? 'auto' : p.width + 33 }}
               onClick={resetScroling}>{p.name + ' ז"ל'}</Link >
            )}
          </div>
          <div className='footer-buttons-container'>
          <TalkingRoomsModalBtn OpenButton={(onClick) => <CommonButton
                width='176.307px'
                minHeight='48.225px'
                fillColor="#966C6C"
                hoverFillColor={'#000'}
                contentColor="#fff"
                notOutLineHover={true}
                imageSrc={zoom}
                onClick={onClick}
                text="הקלטות ערב ההנצחה"
                fontSize={'15px'}
              />} />
            {/* <ConferenceScheduleModalBtn isBtnPinkInDefualt={!deviceType.isMobile}
              btnSize={deviceType.isMobile ? {
                width: '172.307px',
                minHeight: '48.225px'
              } : {
                width: '198.307px',
                minHeight: '53.225px'
              }
              } /> */}
            {/* {deviceType.isMobile && <> */}
              {/* {selectedPerson ? <CommonButton
                width='172.307px'
                minHeight='48.225px'
                fillColor="#966C6C"
                outlineColor="#000"
                contentColor="#fff"
                imageSrc={zoom}
                href={`${zoomBaseUrl}${selectedPerson.zoomLink}`}
                text="הקלטות ערב ההנצחה"
              /> : */}
                {/* <TalkingRoomsModalBtn OpenButton={(onClick) => <CommonButton
                  width='172.307px'
                  minHeight='48.225px'
                  fillColor="#966C6C"
                  outlineColor="#000"
                  contentColor="#fff"
                  imageSrc={zoom}
                  onClick={onClick}
                  text="ערב ההנצחה"
                />} /> */}
                {/* } */}
              {/* <CommonButton
                width='172.307px'
                minHeight='48.225px'
                fillColor="#000"
                contentColor="#fff"
                outlineColor="#fff"
                imageSrc={headphones}
                href={`${zoomBaseUrl}${helpZoomLink}`}
                text="תמיכה טכנית"
              /> */}
            {/* </>} */}
          </div>
        </div>
        {deviceType.isMobile && homeLoge}

        <div className='contact-us-icons-bar'>
          תמיכה טכנית: 09-9668486
        </div>
      </div>
      <div className='c'><p >כל הזכויות שמורות לאלנט @2024</p></div>
    </div>
  );
};

export default Footer;
