import { useState, useEffect } from 'react';

export const deviceScreenEnum = {mobile:'Mobile', tablet:'Tablet', desktop:'Desktop', XLscreen:'XLScreen'};

const useDeviceDetection = () => {
  const [device, setDevice] = useState({isMobile:false,isTablet:false, isDesptop:true, isXLScreen:false});

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = (/iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent)) || window.innerWidth < 768;
      const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);
      const isXLScreen = window.innerWidth > 1280;
      const isDesptop = !isMobile && !isTablet;

      // if (isMobile) {
      //   setDevice(deviceScreenEnum.mobile);
      // } else if (isTablet) {
      //   setDevice(deviceScreenEnum.tablet);
      // } else if (isXLScreen) {
      //   setDevice(deviceScreenEnum.XLscreen);
      // } else {
      //   setDevice(deviceScreenEnum.desktop);
      // }

      setDevice({isMobile,isTablet, isDesptop, isXLScreen});
    };

    handleDeviceDetection();
    window.addEventListener('resize', handleDeviceDetection);

    return () => {
      window.removeEventListener('resize', handleDeviceDetection);
    };
  }, []);

  return device;
};

export default useDeviceDetection;