
import * as React from 'react';
import useDeviceDetection from '../../../useDeviceDetection';
export const fillSquireEnum = {
  full: [''],
  topLeft: ['Top', 'Left'],
  topRight: ['Top', 'Right'],
  bottomRight: ['Bottom', 'Right'],
  bottomLeft: ['Bottom', 'Left']
};


const CommonSquireDecoration = ({
  fillSquire = fillSquireEnum.full, color = '#000',
  defaultSet = {
    top: 'unset', right: 'unset', bottom: 'unset', left: 'unset',
    width: 66, height: 64, borderWeight: 3, borderRadius: 6 },
  mobileSet = { top: 'unset', right: 'unset', bottom: 'unset', left: 'unset',
    width: 27.043, height: 26.225, borderWeight: 2.229, borderRadius: 3 }
}
) => {

  const deviceType = useDeviceDetection();

  const defaultStyle = {
    top: 'unset', right: 'unset', bottom: 'unset', left: 'unset',
    width: 66, height: 64, borderWeight: 3, borderRadius: 6 }

  const mobileStyle = { top: 'unset', right: 'unset', bottom: 'unset', left: 'unset',
    width: 27.043, height: 26.225, borderWeight: 2.229, borderRadius: 3 }

  let customStyle = deviceType.isMobile? {...mobileStyle, ...mobileSet} : {...defaultStyle,...defaultSet};

  const style = {
    position: 'absolute',
    zIndex: '20',    
    top: customStyle.top,
    right: customStyle.right,
    bottom: customStyle.bottom,
    left: customStyle.left,
    width: `${customStyle.width}px`,
    height: `${customStyle.height}px`,
  };

  fillSquire.map(prop => style[`border${prop}`] = `solid ${color} ${customStyle.borderWeight}px`);
  style[`border${fillSquire.join('')}Radius`] = `${customStyle.borderRadius}px`; 


  return (
    <div style={style}></div>
  );
};
export default CommonSquireDecoration;




