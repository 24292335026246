import EmptyRow from "../common/empty-row/empty-row"
export const MattyWeissTexst = {
    dates: '7.10.2023-24.02.1954',
    main: <>
        {`מתי ליוותה אנשי חינוך רבים, פיתחה תוכניות שונות, ותיזכר כאישה נוכחת, שמשפחתה קודמת לכול. אישה שהייתה שם תמיד – להקשיב, לעזור ולתמוך.`}<EmptyRow />
        {`מתי ועמיר וייס. היא אשת חינוך. הוא איש אדמה וטבע.`}<EmptyRow />
        {`בשבעה באוקטובר נרצחו מתי ועמיר בביתם בקיבוץ בארי.`}<EmptyRow />
        {`ארבעת ילדיהם, נכדיהם, בני משפחה ומכרים מתגעגעים עד אין קץ וזוכרים את טוב ליבם.
    `}</>,
    slides: {
        earth: {
            mainShortText: `מתי נולדה בחולון בשנת 1954, בת בכורה לזוג הורים שעלו מטורקיה, אחות גדולה לאבי.
            במהלך ילדותה עבדו הוריה קשה מאוד על מנת לפרנס את הבית, ומתי היתה בת למופת.
            כשגדלו הילדים עברה המשפחה לאילת ולאחר מספר שנים חזרו לחולון.
            לאחר הצבא אמה של מתי הפצירה בה לא לחזור הביתה. השיגי את החלומות שלך, אמרה לה. ומתי שמעה בקולה.`,
            mainText: <>{`היא עבדה בשדה התעופה באילת ופגשה שם את עמיר.
            השניים נישאו ונולדו להם ארבעה ילדים: רן ומיכל (התאומים), יובל ואורן.`}
                <EmptyRow />
                {`כשנתיים לאחר שרן ומיכל נולדו, עזבה המשפחה את אילת ועברה לקיבוץ רמת רחל. לאחר כשלוש שנים עברו לקיבוץ בארי והכו בו שורשים.`}
                <EmptyRow />
                {`            מתי ועמיר היו הפכים משלימים – היא לא יכלה להיות במקום אחד, הוא לא אהב שינויים, אבל אהבתם הייתה גדולה. אהבו גם את "ארץ ישראל היפה", טבע וטיולים, מוזיקה, וכמובן את המשפחה.`}
                <EmptyRow />
                {`מתי, עמיר וכל הילדים גרו בקיבוץ בארי.`}
                <EmptyRow />
                {`מתי אירחה תמיד ברוחב לב. כל דברי המאפה, מתוקים ומלוחים, היו מעשה ידיה והוגשו מתחת לפרגולה, לצד העצים והעציצים שטיפחה.`}
                <EmptyRow />
                {`משפחתה, חבריה ומכריה של מתי זוכרים אותה כאשת משפחה, אשת חינוך, ובעיקר כאישה שכולם יכולים להתחבר אליה.`}
            </>
            ,mobileMainText: `היא עבדה בשדה התעופה באילת ופגשה שם 
            את עמיר.
            השניים נישאו ונולדו להם ארבעה ילדים: רן ומיכל (התאומים), יובל ואורן.
            כשנתיים לאחר שרן ומיכל נולדו, עזבה המשפחה את אילת ועברה לקיבוץ רמת רחל. לאחר כשלוש שנים עברו לקיבוץ בארי והכו בו שורשים.
            מתי ועמיר היו הפכים משלימים – היא לא יכלה להיות במקום אחד, הוא לא אהב שינויים, אבל אהבתם הייתה גדולה. אהבו גם את 
            "ארץ ישראל היפה", טבע וטיולים, מוזיקה, וכמובן את המשפחה.
            מתי, עמיר וכל הילדים גרו בקיבוץ בארי.
            מתי היתה סבתא בכל רמ"ח איבריה. הילדים והנכדים היו מגיעים לביתם בכל יום, ובימים שלא הגיעו, הייתה מתי מגיעה אליהם, לרוב עם פשטידות, עוגיות, עוגות או דברי מאפה אחרים. בשבתות היה לה חשוב לקחת את הנכדים לטיולים ברחבי הקיבוץ ובפרדסים ולחפש אחר כלניות, חלמוניות ואירוסים.
            למתי הייתה נוכחות מרשימה, ולעמיר – קול ייחודי. הוא הוביל את ערבי השירה בקיבוץ,
            והשיר האחרון ששר היה בטקס יום כיפור היה "והחיטה צומחת שוב"...
            ומתי הייתה שם איתו תמיד, מקשיבה לו ומתמלאת בגאווה.
            מתי ועמיר נולדו בפברואר, היישר לעונת הכלניות. לרוב הם חגגו את ימי ההולדת שלהם בפיקניק משפחתי גדול באזור אבו מאליק. כמה שיותר טבע, יותר טוב!
            מתי אירחה תמיד ברוחב לב. כל דברי המאפה, מתוקים ומלוחים, היו מעשה ידיה והוגשו מתחת לפרגולה, לצד העצים והעציצים שטיפחה.
            משפחתה, חבריה ומכריה של מתי זוכרים אותה כאשת משפחה, אשת חינוך, ובעיקר כאישה שכולם יכולים להתחבר אליה.`
            ,
            imagesTexts: [`מתי ועמיר נולדו בפברואר, היישר לעונת הכלניות.
             לרוב הם חגגו את ימי ההולדת שלהם בפיקניק משפחתי גדול באזור אבו מאליק. כמה שיותר טבע, יותר טוב!`,
                `מתי היתה סבתא בכל רמ"ח איבריה. הילדים והנכדים היו מגיעים לביתם בכל יום, ובימים שלא הגיעו, הייתה מתי מגיעה אליהם, לרוב עם פשטידות, עוגיות, עוגות או דברי מאפה אחרים. בשבתות היה לה חשוב לקחת את הנכדים לטיולים ברחבי הקיבוץ ובפרדסים ולחפש אחר כלניות, חלמוניות ואירוסים.`,
                `למתי הייתה נוכחות מרשימה, ולעמיר – קול ייחודי. הוא הוביל את ערבי השירה בקיבוץ,
                        
                השיר האחרון ששר היה בטקס יום כיפור היה "והחיטה צומחת שוב"...
                        ומתי הייתה שם איתו, תמיד, מקשיבה לו ומתמלאת בגאווה.`
            ]
        },
        water: {
            mainShortText: `במטבח כמו בחינוך: קוסמת שמערבבת חומרים ויוצרת מוצר מוצלח שכל המשתמשים נהנים ממנו, מפיקים ממנו משהו ולומדים איך "לאפות" ואיך לשפר את "האפייה" לפעם הבאה.`,
            mainText: <>{`אמונתה של מתי בעשייה חינוכית הייתה מדבקת. קולגות ושותפים לא יכלו שלא להיסחף, לחלום ולהגשים, כי ברגע שהיא החליטה שמשהו צריך לקרות, לא עמד בדרכה דבר!`}
                <EmptyRow />
                {`מתי הייתה אשת חינוך בכל רמ"ח אבריה. ידעה להגדיר את החזון, להתוות את הדרך להגשמתו ולכתוב את התוכנית החינוכית הכי מורכבת וגדולה שיש: למכללת ספיר, לקהילות, למנהלים, לאגף, לפסג"ה, ולצד זה לגעת בנימים הכי קטנים של התפקיד, של האנשים שמרכיבים את התוכנית, לאבחן את הקשיים בדרך, לגשת ולתת יד בפעולות הביניים שמובילות להגשמת חזון הגדול. זאת הייתה מתי.`}
                <EmptyRow />
                {`מתי אהבה לחיות ולעבוד. שני הדברים הללו היו שזורים זה בזה. היא אהבה לחיות חיים בעלי משמעות, ואהבה להיות משמעותית. לא פלא שהקריירה שלה הייתה מגוונת, וזרמה ממקום למקום, מתפקיד לתפקיד, מפרויקט לפרויקט.`}
            </>
        },
        fire: {
            mainShortText: `למידה, חקר והתנסות – אלה התשוקות שהובילו את מתי, בין שמדובר בניסוי של מתכוני אפייה חדשים ובין שמדובר בניהול ייחודי של בית ספר.`,
            mainText: <>
                {`מתי החלה את דרכה כמורה בתיכון גולדווטר באילת, למדה והתפתחה בהתמדה.`}
                <EmptyRow />
                {`היא עשתה תואר שני "מרחוק" באוניברסיטה באנגליה, בזמנים שעוד קראו לזה 'תואר בהתכתבות' – תואר במנהל חינוך.`}
                <EmptyRow />
                {`בבארי היא ניהלה את בית הספר היסודי "נופי בארי". היא שאפה ללמידה חווייתית, ובית הספר שלה חרט על דגלו חקר והתנסות.`}
                <EmptyRow />
                {`תחת ניהולה הפכו אזורים בקיבוץ למרכזי למידה, למשל גן הירק ופינת החי. ילדי שדרות שולבו בבית הספר, ובזמן גל העלייה מאתיופיה שולבו בזכותה ילדים בני העדה בבית הספר.`}
                <EmptyRow />
                {`מתי פעלה רבות בהתפתחות האישית שלה. למדה פסיכולוגיה חיובית, עשתה קורסי קואצ'ינג – העיקר להתפתח. ולצד זאת השקיעה בפיתוח אחרים: היא לא הפסיקה ליזום וללוות ושימשה מנטורית של אנשי חינוך אחרים. גם כשיצאה לפנסיה לא נחה לרגע!`}
                <EmptyRow />
            </>
        },
        air: {
            mainText: <><big style={{ fontFamily: 'Fb Extrim' }}>"להגיע עד קצה העיפרון של הלומד"</big>
                {`, נהגה מתי לומר: "לעודד מורות ומורים לחשוף את היכולות שלהם, לסמן את החוסרים שלהם, להשתפר בהוראה – הכול כדי לשפר את למידת התלמידים," הנחתה מתי את הקהילות המקצועיות הלומדות במכללת ספיר.`}
                <EmptyRow height={10} />
                {`סיפרו על מתי שהמליצה לפעול על פי אמונתו של האר"י הקדוש – על פי תורת הצמצום.`}
                <EmptyRow height={10} />
                {`למרות היותה אשת חינוך חילונית מקיבוץ בארי, היא מצאה בדבריו הד להתנהלות שלה: כדי שאחרים יוכלו להאיר הדרך להולכים, להוביל, להצליח – על אנשי הליווי, המנטורים שלהם להצטמצם על מנת לפַנות להם מקום.`}
                <EmptyRow height={10} />
                {`כך הייתה גם מתי – מלווה, מלמדת ומאמנת אחרים, ומפנה להם מקום כדי שיוכלו להצליח.`}
                <EmptyRow height={10} />
            </>,
            mainShortText: `האני מאמין של מתי היה לגעת באנשים. בכל אדם באשר הוא. להקשיב תמיד לכל אדם ולהתחבר לכל נפש מהמקום שלה, ומשם לחבר אותה לעשייה – לרתום, לגייס, להעצים.`
        },
    },
    songHeader: 'הרדופים',
    songAuthor: 'שלמה ארצי',
    songLink: 'jl5uOClGcWQ?si=iI-fVO54_ql_KCWE',
    song: [],
    padletLink: '9z06k7a7fpep33rz'
}