import './mobile-nav.scss'
import * as React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import mobileLogo from "../../../assets/images/mobile-logo.svg";
import { helpZoomLink, personsRouts, zoomBaseUrl } from '../../../models.js';
import headphones from '../../../assets/images/icons/small-headphones.svg'
import zoom from '../../../assets/images/icons/zoom.svg'
import home from '../../../assets/images/icons/home.svg'
import CommonButton from '../../common/button/button.jsx';
import CloseIcon from '@mui/icons-material/Close';
import TalkingRoomsModalBtn from '../../common/talking-rooms-modal-btn/talking-rooms-modal-btn.jsx';


export default function MobileNav() {

  const location = useLocation();
  const navigate = useNavigate();

  //#region : mobile persons menue components
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [activeNavLink, setActiveNavLink] = React.useState(personsRouts.findIndex(r => location.pathname.includes(r.path)));

  React.useEffect(() => {
    setActiveNavLink(personsRouts.findIndex(r => location.pathname.includes(r.path)));
  }, [location]);

  const onMobileNavItemClicked = (activeIndex = null) => {
    setActiveNavLink(activeIndex);
    setTimeout(() => setIsMobileMenuOpen(false), 250);
  }

  const drawer = (
    <div className='left-drawer-menue'>
      <IconButton
        onClick={() => setIsMobileMenuOpen(false)}
        sx={{
          color: 'white',
          position: 'absolute',
          left: '20px',
          top: '18px',
          zIndex: 1,
        }}
      >
        <CloseIcon />
      </IconButton>
      <List>
        {personsRouts.map((route, index) => (
          <ListItem key={`menu-item-${index}`} disablePadding>
            <ListItemButton component={Link} to={route.path} onClick={() => onMobileNavItemClicked(index)}>
              <ListItemText primary={route.name  + ' ז"ל'} sx={{
                fontWeight: activeNavLink === index ? 700 : 400
              }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div className="nav-buttons-container" >
        <CommonButton
          width='145.307px'
          minHeight='41.225px'
          onClick={() => setIsMobileMenuOpen(false)}
          fillColor="#000"
          contentColor="#fff"
          imageSrc={home}
          routePath='/Home'
          text="לדף הבית"
        />
        <TalkingRoomsModalBtn
          anotherActionOnClose={()=>setIsMobileMenuOpen(false)}
          OpenButton={onClick => <CommonButton
          width='145.307px'
          minHeight='41.225px'
          fillColor="#966C6C"
          outlineColor="#000"
          contentColor="#fff"
          imageSrc={zoom}
          onClick={onClick}
          text="ערב ההנצחה"
          fontSize={'18px'}
        />}/>
        {/* <CommonButton
          width='145.307px'
          minHeight='41.225px'
          fillColor="#000"
          contentColor="#fff"
          outlineColor="#fff"
          imageSrc={headphones}
          onClick={() => setIsMobileMenuOpen(false)}
          href={`${zoomBaseUrl}${helpZoomLink}`}
          text="תמיכה טכנית"
        /> */}
      </div>
    </div>
  );
  //#endregion

  return (
    <div className='mobile-nav-component'>
      <div className='place-holder-div'></div>
      <img src={mobileLogo} alt='סיפור הרוח' onClick={() => navigate('/Home')} />
      <IconButton
        color="inherit"
        aria-label="תפריט"
        edge="end"
        onClick={() => setIsMobileMenuOpen(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={isMobileMenuOpen}
        anchor="left"
        onClose={() => setIsMobileMenuOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', minWidth: '65vw', width: 'max-content', background: '#232B2D' }
        }}
      >
        {drawer}
      </Drawer>
    </div>
  );

}

