import './Button.scss'
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceDetection from '../../../useDeviceDetection';

const CommonButton = ({ fillColor, contentColor, outlineColor, text, notOutLineHover, hoverFillColor, routePath, href, onClick, width, minHeight, imageSrc, borderRadius, fontSize }) => {

  const deviceType = useDeviceDetection();
  const navigate = useNavigate();
  const openExternalWebsite = (url) => {
    window.open(url, '_blank');
  };

  let buttonStyle = {
    "--fill-color": fillColor,
    "--content-color": contentColor,
    "--outline-color": (outlineColor ? outlineColor : fillColor),
    "--hover-fill-color": (hoverFillColor ? hoverFillColor : fillColor)
  }

  if (width)
    buttonStyle = { ...buttonStyle, 'width': width }
  if (minHeight)
    buttonStyle = { ...buttonStyle, 'minHeight': minHeight }
  if(borderRadius)
    buttonStyle = { ...buttonStyle, 'borderRadius': borderRadius }
  if(fontSize)
    buttonStyle = { ...buttonStyle, 'fontSize': fontSize }



  const handleClick = () => {
    if (onClick)
      onClick();
    if (routePath)
      navigate(routePath);
    else if (href)
      openExternalWebsite(href);
  }

  return (
    <button onClick={handleClick} style={buttonStyle}
      className={`common-button ${notOutLineHover || deviceType.isMobile ? '' : 'outline-hover'}
     ${deviceType.isMobile ? 'mobile-common-button' : ''}`}
    >
      {text}{imageSrc && <img src={imageSrc} alt={text} />}
    </button>
  );
};
export default CommonButton;




