import React from 'react';
import useDeviceDetection from '../../../../useDeviceDetection';
import CustomModal from '../../custom-modal/custom-modal';

const ZoomRecordModal = ({
    openButton, anotherActionOnClose, zoomRecordLink = 'ScMzIvxBSi4?si=NvD6QjD9R6pdr3DH', name = null
}) => {

    const deviceType = useDeviceDetection();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    
    const handleOpenRecordingModalBtnClick = () => {
        setIsModalOpen(true);
      };
    
      const handleModalClose = () => {
        setIsModalOpen(false);
        if (anotherActionOnClose)
          anotherActionOnClose();
      };

    return (<>
        <CustomModal width={65} widthType={'vw'} isFullWidth={deviceType.isMobile}
            open={isModalOpen} onClose={handleModalClose}
            content={
                <>
                {deviceType.isMobile && <h2 style={{color: '#fff', fontFamily: 'Fb Extrim', textAlign:'center'}}>הקלטה מחדר השיח של {name}</h2>}
                <iframe style={deviceType.isMobile? {width: '68vw', height: '22vh', margin:'7vh 0px 19vh' }
                    :{ width: '54vw', height: '70vh', marginLeft:'2.9vw' }}
                    src={`https://www.youtube.com/embed/${zoomRecordLink}`}
                    title={name !== 'המליאה' ? `הקלטה מחדר השיח של ${name}` : 'הקלטה מחדר המליאה'} frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </>
            }
        />
        {openButton(handleOpenRecordingModalBtnClick)}

    </>)
}
export default ZoomRecordModal;








