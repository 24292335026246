import React from 'react';
import './tab-header-and-expandable-text.scss'
import CommonButton from '../../button/button';
import useDeviceDetection from '../../../../useDeviceDetection';
import CustomModal from '../../custom-modal/custom-modal';

const TabHeaderAndExpandableText = ({
    header, text, textWidth = '392px', textHeight = '225px', mainShortText = null, textMobileWidth='318px',
    textFullHeight, isExpanded, setIsExpanded, customMoadlTextStyle = {}, modalWidth = 984
}) => {

    const deviceType = useDeviceDetection();
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleOpenModalBtnClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const textStyle = {
        maxWidth: deviceType.isMobile ? textMobileWidth : textWidth,
        width: deviceType.isMobile ? '100%' : textWidth,
        height: isExpanded ? textFullHeight : textHeight
    }

    return (<>
        {!deviceType.isMobile && <CustomModal
            open={isModalOpen} onClose={handleModalClose} width={modalWidth}
            content={<div className="full-text-modal" style={customMoadlTextStyle}>{text}</div>}
        />}
        <div style={{ height: 'auto', width: deviceType.isMobile ? '351px' : 'unset' }}
            className={`tab-header-and-expandable-text-component ${deviceType.isMobile ? 'tab-header-and-expandable-text-component-mobile' : ''}`}>
            <h3>{header}</h3>
            <div className='expandable-text' style={textStyle}>{mainShortText ?? text}</div>
            {(text || deviceType.isMobile) && <CommonButton
                width={deviceType.isMobile ? '100%' : null}
                fillColor="#000"
                contentColor="#fff"
                onClick={() => { deviceType.isMobile ? setIsExpanded(!isExpanded) : handleOpenModalBtnClick() }}
                text={isExpanded ? 'סגירה' : "קרא עוד..."} />}
        </div>
    </>)
}
export default TabHeaderAndExpandableText;








