import React, { useEffect, useState } from 'react';
import './sideNavigation.scss'
import { Outlet } from 'react-router-dom';
import useDeviceDetection from '../../../useDeviceDetection';
import i1 from '../../../assets/images/icons/side-navigation/description.svg'
import i2 from '../../../assets/images/icons/side-navigation/elements.svg'
import i3 from '../../../assets/images/icons/side-navigation/song.svg'
import i4 from '../../../assets/images/icons/side-navigation/share.svg'
import i5 from '../../../assets/images/icons/side-navigation/galery.png'

import selctedI1 from '../../../assets/images/icons/side-navigation/selected-description.svg';
import selctedI2 from '../../../assets/images/icons/side-navigation/selected-elements.svg';
import selctedI3 from '../../../assets/images/icons/side-navigation/selected-song.png';
import selctedI4 from '../../../assets/images/icons/side-navigation/selected-share.png';
import selctedI5 from '../../../assets/images/icons/side-navigation/selected-galery.png';
import useIsNotFixed from '../../../useIsNotFixed';

const SideNavigation = () => {

  const deviceType = useDeviceDetection();
  const isNotFixed = useIsNotFixed();

  const [selected, setSelected] = useState();
  const navigationItems = [
    { id: 'start', value: '', icon: i1, selectedIcon: selctedI1 },
    { id: '02', value: 'סיפור אישי', icon: i2, selectedIcon: selctedI2 },
    { id: '03', value: 'שיר', icon: i3, selectedIcon: selctedI3 },
    { id: '04', value: '', icon: i4, selectedIcon: selctedI4 },
    { id: '05', value: 'גלריה', icon: i5, selectedIcon: selctedI5 }
  ];
  const selectSection = (sectionId) => {    
    setSelected(sectionId);
    const element = document.getElementById(sectionId);
    element.scrollIntoView({block: "start", inline: "start"});
  }

  const handleScroll = () => {
    const visibleElementId = getFirstVisibleElementId();
    if (visibleElementId) {
      if (visibleElementId !== selected) {
        setSelected(visibleElementId);
        // window.history.replaceState(null, null, '#' + visibleElementId);//TODO CHECK IF WANTED, AND WHICH VAL THEY WANT
      }
    }
  };

  function getFirstVisibleElementId() {
    const elements = document.querySelectorAll("[class^=anchor-section-]");
    return Array.from(elements)
      .find(element => {
        let bounding = element.getBoundingClientRect();
        return bounding?.top >= 0;//  bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      })?.id;
  }

  useEffect(() => {

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);

  }, []);

  return (
    <div>
      {!deviceType.isMobile && <div className='side-navigation-component' style={isNotFixed ?
             { bottom: '529px', top: 'unset', position: 'absolute' }
            : {}}>
        <ul>
          {navigationItems.map(item => (
            <li key={item.id} className={(selected === item.id ? 'selected' : '')}>
              <span onClick={() => selectSection(item.id)}>
                <img src={selected === item.id ? item.selectedIcon : item.icon} alt={item.value} />
              </span>
            </li>
          ))}
        </ul>
      </div>}
      <Outlet context={[selected, setSelected]}></Outlet>
    </div>
  )
}
export default SideNavigation;