import { IconButton } from "@mui/material";
import React, { useRef, useState } from "react";

const CommonVideo = ({ src, style, description, isMobile = false }) => {

    const videoRef = useRef();
    const [isPlayIconShow, setIsPlayIconShow] = useState(false); 
    const [isPlayIconHoverd, setIsPlayIconHoverd] = useState(false); 
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        if (isPlaying) {
            try {
                videoRef.current.pause();
            } catch (error) { };
            setIsPlaying(false);
        } else {
            try {
                videoRef.current.play();
            } catch (error) { };
            setIsPlaying(true);
        }
    }

    return <div style={{ position: 'relative' }}>
        <video
            className="commom-video-player-component"
            ref={videoRef}
            src={src}
            style={style}
            autoPlay={false}
            title={description}
            onMouseEnter={() => setIsPlayIconShow(isPlaying)}
            onMouseOut={() => setIsPlayIconHoverd(false)}

        />
        {(!isPlaying || isPlayIconShow) &&
            <IconButton
                onMouseEnter={() => setIsPlayIconHoverd(!isMobile)}
                onClick={handlePlay} style={{ top: '40%', left: '40%', size: '54px', position: 'absolute', zIndex: 4 }}
            >
                {isPlayIconHoverd ?
                    <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="19" cy="19.28" r="19" fill="white" />
                        <path d="M27.5 17.914C28.1667 18.2989 28.1667 19.2612 27.5 19.6461L14.75 27.0073C14.0833 27.3922 13.25 26.911 13.25 26.1412L13.25 11.4188C13.25 10.649 14.0833 10.1679 14.75 10.5528L27.5 17.914Z" fill="black" />
                    </svg>
                    : <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="19" cy="19" r="19" fill="black" />
                        <path d="M27.5 17.634C28.1667 18.0189 28.1667 18.9811 27.5 19.366L14.75 26.7272C14.0833 27.1121 13.25 26.631 13.25 25.8612L13.25 11.1388C13.25 10.369 14.0833 9.88786 14.75 10.2728L27.5 17.634Z" fill="white" />
                    </svg>
                }
            </IconButton>
        }
    </div>;
}
export default CommonVideo;